import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  Space,
  Select,
  message,
  Checkbox,
  Skeleton,
  Typography,
  Row,
  Col,
  Card,
  Avatar,
  Breadcrumb,
  Tabs,
} from "antd";
import {
  PlusOutlined,
  UserAddOutlined,
  SearchOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  CarOutlined,
  RocketOutlined,
  CheckOutlined,
  SecurityScanOutlined,
  BarcodeOutlined
} from "@ant-design/icons";
import _ from "lodash";
import moment from 'moment';
import locale from "antd/es/date-picker/locale/es_ES";
import { useHistory, useParams } from "react-router-dom";
import HeaderSection, { InputDate, ItemForm } from "../../components/MyComponents";
import {ruleEmail} from "../../../src/lib/utils/rules";
import {stateList, subtypes} from "../../../src/lib/utils/utils";
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from "react-intl";
import { changeLangAction } from '../../redux/settingsDuck';
import { useSelector } from 'react-redux';
import axiosApi, { API, tenantSelected } from '../../lib/utils/axiosApi';
import getUserDataLogged from '../../lib/utils/user';
import SelectAsesor from "../Selects/SelectAsesor";
import useSettingsKikert from "../../hooks/useSettingsKikert";
import SelectSubtypes from "../Selects/SelectSubtypes";
import SelectJacLevels from "../Selects/SelectJacLevels";
import SelectJacOrderTypes from "../Selects/SelectJacOrderTypes";
import SelectJacVersions from "../Selects/SelectJacVersions";
import KModal from '../common/elements/KModal/KModal';
import QRReaderComponent from '../qrCode/qrCode';
import { KButton } from '../KComponents';

const FormContainer = ({children})=>{
  return (
    <div style={{margin: '10px'}}>
        {children}
    </div>
  )
}

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const TENANT_JAC='jacmexico';



const rulesRequired = [
  {
    required: true,
  },
]

const ServiceReceptionForm = ({
  insuranceType, changeLangAction, intl, settings, ...props
}) => {

  /* const [receptionType, setReceptionType] = useState(insuranceType) */
  const withtInsurance = useSelector(state => state.settings.insuranceIsActive);
  const orderNumberDisabled = useSelector(state => state.settings.orderNumberDisabled);
  const {settingsKikert, getMetaValue} = useSettingsKikert()

  const HIDE_PRIVATE_INDIVIDUAL_CHECK=getMetaValue('HIDE_PRIVATE_INDIVIDUAL_CHECK', '0');

  const options = [
    { label: `${<CheckOutlined />} Por aseguradora`, value: 'I' },
    { label: 'Particular', value: 'P' },
  ];

  const config = {
    rules: [
      {
        type: 'object',
        required: true,
        message: 'Seleccione una fecha',
      },
    ],
  };

  const onChange4 = e => {
    console.log('radio4 checked', e.target.value);
    /* setReceptionType(e.target.value) */

  };


  return (
    <Row gutter={10}>
      <Col lg={5} xs={24} sm={24} md={24}>
        <ItemForm
          name="recepteionDate"
          label={<FormattedMessage id="services.createNew.dateReceipt" />}
          {...config}
        >
          <InputDate
            locale={locale}
            // defaultValue={moment()}
            style={{ width: "100%" }}
          />
        </ItemForm>
      </Col>
      <Col lg={5} xs={24} sm={24} md={24}>
        <ItemForm
          name="orderNumber"
          label={<FormattedMessage id="services.createNew.orderNumber" />}
        >
          <Input size="large" disabled={orderNumberDisabled} placeholder="Número de orden" />
        </ItemForm>
      </Col>




      {
        (getMetaValue('HIDE_ORDER_SUBTYPE') === '0' &&
          tenantSelected !== 'jacmexico') &&  <Col lg={5} xs={24} sm={24} md={24}>
            <SelectSubtypes/>
          </Col>
      }



      

        {
          getMetaValue("HIDE_PYRAMID_FIELD") !== "1" && (
            <Col lg={5} xs={24} sm={24} md={24}>
            <ItemForm
              name="pyramid"
              label={<FormattedMessage id="services.createNew.pyramid" />}
              //rules={rulesRequired}
              //tooltip="Campo requerido"
            >
              <Input size="large" placeholder="Pirámide" />
            </ItemForm>
            </Col>
          )
        }
      

      {
          tenantSelected === 'jacmexico' &&  <Col lg={5} xs={24} sm={24} md={24} style={{marginBottom:20}}>
            <SelectJacLevels name={'metaLevel'}/>
          </Col>
      }

      {
          tenantSelected === 'jacmexico' &&  <Col lg={5} xs={24} sm={24} md={24} style={{marginBottom:20}}>
            <SelectJacOrderTypes name={'order_type'}/>
          </Col>
      }


      {
          tenantSelected === 'jacmexico' &&  <Col lg={5} xs={24} sm={24} md={24} style={{marginBottom:20}}>
            <SelectJacVersions />
          </Col>
      }


      
        <Col>
        <ItemForm rules={rulesRequired} name="insuranceType" className="radioItemForm">
          <Radio.Group
            className="radioCheck"
            style={{ width: "100%" }}
            size="medium"
            /* options={options} */
            onChange={onChange4}
            defaultValue={'P'}
            optionType="button"
            buttonStyle="solid"
          >
            {
              withtInsurance && (
                <Radio.Button value="I">  <CheckOutlined /> <FormattedMessage id="services.createNew.byIsurer" /></Radio.Button>
              )
            }
            {
              HIDE_PRIVATE_INDIVIDUAL_CHECK === '0' && <Radio.Button value="P"> <CheckOutlined /> <FormattedMessage id="services.createNew.privateIndividual" /></Radio.Button>
            }

          </Radio.Group>
        </ItemForm>



      </Col>
      
      
      <Col lg={5} xs={24} sm={24} md={24} style={{marginBottom:20}}>
        <SelectAsesor name={'assigned_to'}/>
      </Col>

    </Row>
  );
}

const VehicleCardReadOnly = ({ vehicleData }) => {

  //console.log('vehicleData', vehicleData)

  const brand = _.get(vehicleData, 'model.brand.name')
  const model = _.get(vehicleData, 'model.name')
  const color = _.get(vehicleData, 'color.name')
  const year = _.get(vehicleData, 'year')
  const vin = _.get(vehicleData, 'vin')

  const full_name = `${brand} ${model} ${color} ${year}`

  return (
    <Card
      style={{ width: 300 }}
      cover={
        <img
          alt="example"
          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
        />
      }
      actions={[]}
    >
      <Meta
        avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
        title={full_name}
        description={vin}
      />
    </Card>
  )
}

/*  */
const UnitFormItems = ({
  changeLangAction,
  carTypeSelected,
  carModeUI,
  intl,
  settings,
  fieldsRequired,
  unitData,
  readOnly,
  setVehicleSelected,
  handleChangeGasType,
  carTypes,
  handleCarBrands,
  carBrands,
  handleCarModel,
  viewBrand,
  handleCarColor,
  handleCarTypes,
  carModels,
  carColors,
  loading,
  setIsValidVin,
  isUpd,
  vinLength,
  form,
  loadingCarTypes,
  loadingCarModels,
  loadingCarColors,
  loadingCarBrands,
  ...props
}) => {

  const [statusVinField, setStatusVinField] = useState("")
  const [statusVinFieldMsg, setStatusVinFieldMsg] = useState("")
  const [readyToReadBarCode, setReadyToReadBarCode] = useState(false)
  const {settingsKikert, getMetaValue} = useSettingsKikert()
  const HIDE_ECONOMIC_NUMBER_INPUT=getMetaValue('HIDE_ECONOMIC_NUMBER_INPUT', '0');
  const HIDE_LICENSE_PLATE_INPUT=getMetaValue('HIDE_LICENSE_PLATE_INPUT');
  const ECONOMIC_NUMBER_LABEL=getMetaValue('ECONOMIC_NUMBER_LABEL');
  useEffect(() => {
    if (unitData && carModels.length > 0 && unitData?.model?.brand?.id) {
      console.log('carTypeSelected', carTypeSelected)
      handleCarBrands(unitData?.model?.brand?.id);
    }
  }, [unitData, carModels]);

  const asyncValidateVin = (e) => {
    const vin = e.target.value.toUpperCase();
    form.setFieldsValue({"serialNumber":vin ? vin.toUpperCase() : ""})
    if(vinLength){
      if(vin && vin.length === vinLength){
        setStatusVinField("validating")
        setStatusVinFieldMsg("Validando...")
        debounceValidateVin(vin, setErrorVin)
      }
      else{
        setStatusVinField("error")
        setStatusVinFieldMsg("El VIN debe contener por lo menos "+ vinLength +" caracteres")
      }
    }

  }

  const setErrorVin = (type = "error", msg = "") => {
      setStatusVinField(type)
      setStatusVinFieldMsg(msg)
  } 

  const verifyVin = async (search = '', callback = () => { }) => {
    try {
      //console.log("VIN", search)
      //setLoading(true)
      const response = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CAR}?vin=${search}`)
      //console.log("Response vehicle vin", response.data)
      //setCustomers(response.data.results)
      if(response.data.results.length > 0){
        setIsValidVin(false)
        callback("error", "El VIN ya existe")
      }else{
        setIsValidVin(true)
        callback("success", "VIN validado")
      }
    } catch (error) {
      callback("")
      console.error('Error al validat vin', error)
    } finally {
      //setLoading(false)
    }
  }

  const debounceValidateVin = _.debounce(
    (query, callback) => {
      verifyVin(query, callback)
    },
    500
  )

  const getResultBarCode = (vin)=>{
    form.setFieldsValue({"serialNumber":vin ? vin.toUpperCase() : ""})
    if(vinLength){
      if(vin && vin.length === vinLength){
        setStatusVinField("validating")
        setStatusVinFieldMsg("Validando...")
        debounceValidateVin(vin, setErrorVin)
      }
      else{
        setStatusVinField("error")
        setStatusVinFieldMsg("El VIN debe contener por lo menos "+ vinLength +" caracteres")
      }
    }else{
      debounceValidateVin(vin, setErrorVin)
    }
  }

  return (
    <div>
      <Row gutter={24}>
        <Col lg={8} xs={24} sm={24} md={24}>
          <ItemForm
              label={<FormattedMessage id="services.createNew.typeVehicle" />}
              rules={fieldsRequired && rulesRequired}
              name="carType"
              tooltip="Campo requerido"
          >
            {
              !loadingCarTypes ?
              <Select
                loading={loadingCarTypes}
                /* defaultValue={carTypeSelected} */
                style={{ width: "100%" }}
                onChange={handleCarTypes}
                disabled={loadingCarTypes}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                readOnly={readOnly}
            >
              {carTypes.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              : <p>Cargando...</p>
            }
          </ItemForm>
        </Col>
        <Col lg={8} xs={24} sm={24} md={24}>
          <Row style={{width: '100%', alignItems: 'center'}}>
          <ItemForm
            label={<FormattedMessage id="services.createNew.serialNumber" />}
            name="serialNumber"
            // rules={fieldsRequired && [rulesRequired, vinRule]}
            tooltip="Campo requerido"
            rules={[{required: true, message: 'El campo es requerido'}]}
            validateStatus={statusVinField}
            hasFeedback
            help={statusVinFieldMsg}
            style={{ flex: '1'}}
          >
            
              <Input
                size="large"
                maxLength={vinLength ? vinLength : 100}
                //status={vinIsValid}
                placeholder="Número de serie"
                value={unitData ? unitData.vin : ""}
                readOnly={isUpd}
                disabled={isUpd}
                onChange={asyncValidateVin}
              />
              
          </ItemForm>
         {!isUpd && <KButton
                icon={<BarcodeOutlined />}
                type={'outline'}
                onClick={() => setReadyToReadBarCode(true)}
                size="large"
                style={{ flex: 'none', marginBottom: '3px' }}
              />}
          </Row>
        </Col>
        {
          HIDE_LICENSE_PLATE_INPUT === '0' && <Col span={8}>
          <ItemForm
            label={<FormattedMessage id="services.createNew.plates" />}
            name="plate">
            <Input size="large" 
              onChange={(e) => {
                const value = e.target.value.toUpperCase().replace(/[^A-Z0-9\s-]/g, '');
                form.setFieldsValue({ plate: value });
              }}
            placeholder="Placas" readOnly={readOnly} />
          </ItemForm>
        </Col>
        }
        

      </Row>
      <Row gutter={24}>
        
        <Col lg={8} xs={24} sm={24} md={24}>
          <ItemForm
            name="carBrand"
            label={<FormattedMessage id="layout.brand" />}
            /* value={unitData ? unitData.model.brand.id : ""} */
            rules={fieldsRequired && rulesRequired}
            tooltip="Campo requerido"
          >
            {
              !loadingCarBrands ?
              <Select
              loading={loadingCarBrands}
              disabled={loadingCarBrands}
              /* defaultValue={carBrandSelected} */
              style={{ width: "100%" }}
              onChange={handleCarBrands}
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              readOnly={readOnly}
            >
              {/*LISTAR MARCAS*/}
              {carBrands.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
                ))}
              </Select>: <p>Cargando...</p>
            }
          </ItemForm>
        </Col>
        <Col lg={8} xs={24} sm={24} md={24}>
          <ItemForm
            label={<FormattedMessage id="layout.model" />}
            rules={fieldsRequired && rulesRequired}
            name="carModel"
            tooltip="Campo requerido"
          >
            {
              !loadingCarModels ?
              <Select
              loading={loadingCarModels}
              style={{ width: "100%" }}
              disabled={loadingCarModels}
              /* value={carModelSelected} */
              onChange={handleCarModel}
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              readOnly={readOnly}
            >
              {/*LISTAR MODELOS*/}
              {viewBrand
                ? viewBrand.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
              {/*{carModels.map((item) => (
                <Option key={item.id}>{item.name}</Option>
              ))}*/}
            </Select>: <p>Cargando...</p>
            }
          </ItemForm>
        </Col>
        <Col lg={8} xs={24} sm={24} md={24}>
          <ItemForm
              label={'Año'}
              name="year"
              // rules={fieldsRequired && [rulesRequired, vinRule]}
              tooltip="Campo requerido"
          >
            <Input
                size="large"
                maxLength={4}
                defaultValue={'2024'}
                //status={vinIsValid}
                placeholder="Año"
                //value={unitData ? JSON.stringify(unitData) : "aa"}
                //onChange={asyncValidateVin}
            />
          </ItemForm>
        </Col>
        <Col lg={8} xs={24} sm={24} md={24}>
          <ItemForm
            /* initialValue={unitData ? unitData.color.id : ""} */
            rules={fieldsRequired && rulesRequired}
            label={<FormattedMessage id="services.createNew.colour" />}
            name="carColor"
          >
            {
              !loadingCarColors ?
              <Select
              loading={loadingCarColors}
              style={{ width: "100%" }}
              disabled={loadingCarColors}
              /* value={carColorSelected} */
              onChange={handleCarColor}
              readOnly={readOnly}
            >
              {carColors.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
                ))}
              </Select>: <p>Cargando...</p>
            }
          </ItemForm>
        </Col>
        {
          HIDE_ECONOMIC_NUMBER_INPUT === '0' &&
          <Col lg={8} xs={24} sm={24} md={24}>
            <ItemForm
                label={`${ECONOMIC_NUMBER_LABEL?ECONOMIC_NUMBER_LABEL:'Núm. económico'}`}
                name="economic_number"
            >
              <Input size="large"/>
            </ItemForm>
          </Col>
        }
        <Col lg={8} xs={24} sm={24} md={24}>
          <ItemForm
              label={`Tipo de transmisión`}
              name="transmission"
          >
            <Select
                style={{ width: "100%" }}
                defaultValue={'STANDARD'}
            >
              <Option value={'STANDARD'} key={'standar'}>
                Estándar
              </Option>
              <Option value={'AUTOMATIC'} key={'automatic'}>
                Automática
              </Option>
            </Select>
          </ItemForm>
        </Col>
       {readyToReadBarCode && <KModal
          title={"Lector de código de barras"}
          onCancel={()=> setReadyToReadBarCode(false)}
          loading={false}
          visible={readyToReadBarCode}>
          <Col lg={24} xs={24} sm={24} md={24}>
            <QRReaderComponent
                readyToReadQr={readyToReadBarCode}
                setReadyToReadQr={setReadyToReadBarCode}
                getResultQr = {getResultBarCode}
            />
          </Col>
      </KModal>}
      </Row>
    </div>
  );
};
/*  */

const UnitForm = ({ changeLangAction, intl, settings, setCar, setGasTypeSelected, setCarModeUI, carModeUI, isUpd, carSelectedForUpd, setIsValidVin, setDefaultUnitValue, form, ...props }) => {

  const [carBrands, setCarBrands] = useState([])
  const [carModels, setCarModels] = useState([])
  const [carColors, setCarColors] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [vehicleSelected, setVehicleSelected] = useState(null)
  const [vehicleDataSelected, setVehicleDataSelected] = useState(null)
  const [carColorSelected, setCarColorSelected] = useState(null)
  /* const [carBrandSelected, setCarBrandSelected] = useState(null) */
  const [carModelSelected, setCarModelSelected] = useState(null)
  //const [gasTypeSelected, setGasTypeSelected] = useState("GAS")
  const [loading, setLoading] = useState(false)
  const [loadingVehicleUpd, setLoadingVehicleUpd] = useState(false)
  const [loadingCarTypes, setLoadingCarTypes] = useState(true)
  const [loadingCarModels, setLoadingCarModels] = useState(true)
  const [loadingCarColors, setLoadingCarColors] = useState(true)
  const [loadingCarBrands, setLoadingCarBrands] = useState(true)
  const [carTypes, setcarTypes] = useState([])
  const [carTypeSelected, setCarTypeSelected] = useState(null)
  const [isNew, setIsNew] = useState(false)
  const [vinLength, setVinLength] = useState(null)

  const handleChangeGasType = (val) => {
    setGasTypeSelected(val)
  }

  const getCarBrands = async () => {
    try{
      setLoadingCarBrands(true)
      const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_CARBRAND+'?page_size=100000')
      setCarBrands(response.data.results)
    }catch(error){
      console.error('Error al cargar datos de marca', error)
    }finally{
      setLoadingCarBrands(false)
    }
    /* if (response.data.results.length === 1) {
      setDefaultUnitValue("carBrand", response.data.results[0]["id"]);
    } */
  }

  const getCarModels = async () => {
    //console.log('getCarModels===')
    setLoadingCarModels(true)
    try{
      
      const responseModels = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CARMODEL}?page_size=100000`)
      //console.log(responseModels.data.results);
      setCarModels(responseModels.data.results)
    }catch(error){
      console.error('Error al cargar datos de modelo', error)
    }finally{
      setLoadingCarModels(false)
    }
  }

  const getCarColors = async () => {
    try{
      setLoadingCarColors(true)
      const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_CARCOLOR)
      let dataColors = response?.data?.results || [];
    setCarColors(dataColors)
    if (dataColors === 1) {
        setDefaultUnitValue("carColor", dataColors[0]["id"]);
      }else{
        const blancoColor = dataColors.find(color => color.name === 'Blanco');
        if(blancoColor && !isUpd) setDefaultUnitValue("carColor", blancoColor.id);
      }
    }catch(error){
      console.error('Error al cargar datos de color', error)
    }finally{
      setLoadingCarColors(false)
    }
  }

  const loadData = async () => {
    try {
      setLoading(true)
      await getCarModels()
      await getCarColors()
      await getCarBrands()
      await getCarTypes()
    } catch (error) {
      console.error('Error al cargar datos de marca', error)
    } finally {
      setLoading(false)
    }
  }

  const [viewBrand, setViewBrand] = useState()

  const handleCarBrands = (val) => {
    // 
    const nameBrand = val;
    let filtros = carModels.filter(element => element.brand.id === nameBrand)
    if(carTypeSelected && tenantSelected === TENANT_JAC){
      filtros = filtros.filter(element => element.vehicle_type === carTypeSelected || element.vehicle_type === null);
    }
    //console.log('Value de las marcas', nameBrand)
    //carModels.map((item) => (console.log('MAURICIO: ', item.name)))
    setViewBrand(filtros)
  }

  useEffect(()=>{
    if(carTypeSelected && tenantSelected === TENANT_JAC){
      handleCarBrands(form.getFieldValue('carBrand'))
    }
  },[carTypeSelected])

  const handleCarTypes = (val, e) => {
    let cartype = carTypes.find((item)=> item.id === val)
    if(cartype){
      setVinLength(cartype.vin_validation? cartype.vin_length : null)
    }
    setCarTypeSelected(val)
  }

  const handleCarModel = (val) => {
    
    setCarModelSelected(val)
  }

  const handleCarColor = (val) => {
    
    setCarColorSelected(val)
  }

  const getVehicles = async (search = '', callback = () => { }) => {
    try {
      setLoading(true)
      const response = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CAR}?search=${search}`)
      
      //setCustomers(response.data.results)
      callback(response.data.results)
    } catch (error) {
      callback([])
      console.error('Error al buscar vehiculos', error)
    } finally {
      setLoading(false)
    }
  }

  const getCarTypes = async () => {
    try {
      setLoadingCarTypes(true)
      const response = await axiosApi.get(`${API.WORKSHOP.WORKSHOP_CAR_TYPES}`)
      //console.log('Se ejecuto vehiculos busqyeda', response.data)
      setcarTypes(response.data.results)
      if (response.data.results.length === 1 ) {
        setDefaultUnitValue("carType", response.data.results[0]['id']);
        let _carType = response.data.results[0] ;
        setVinLength(_carType.vin_validation? _carType.vin_length : null)
      }
    } catch (error) {
      console.error('Error al buscar vehiculos', error)
    } finally {
      setLoadingCarTypes(false)
    }
  }

  const debouncedVehicleSearch = _.debounce(
    (query, callback) => {
      getVehicles(query, callback)
    },
    500
  )

  const vehicleHandleSearch = (val) => {
    
    if (val)
      debouncedVehicleSearch(val, results => setVehicles(results))
  }

  const vehicleHandleChange = (val, val2) => {
    
    setVehicleSelected(val)
  }

  const toogleNewVehicle = () => {
    const newValue = !isNew
    if (newValue) {
      setVehicleSelected(null)
    }
    setIsNew(newValue)
  }

  const setVehicleData = () => {
    const vehicleId = vehicleSelected ? parseInt(vehicleSelected) : 0
    const query = { 'id': vehicleId }
    const vehicleFound = _.find(vehicles, query)

    
    if (vehicleFound) {
      setVehicleDataSelected(vehicleFound)
      
      setCar(vehicleFound)
      setIsValidVin(true)
    }
    else{
      setIsValidVin(false)
      setVehicleDataSelected(null)
    }
  }

  useEffect(
    () => {
      if (vehicleSelected) {
        setVehicleData()
      }
    }, [vehicleSelected]
  )

  useEffect(() => {
    if (vehicleDataSelected){
      
      handleCarBrands(vehicleDataSelected.id);
    } 
  }, [vehicleDataSelected]);

  useEffect(() => {
    if(carBrands.length === 1){
      setDefaultUnitValue("carBrand", carBrands[0]["id"]);
      handleCarBrands(carBrands[0]["id"]);
    }
  }, [carBrands]);
  
  

  const handleVehicleSelectedUPD = async (vehicleId) => {
    try {
      setLoadingVehicleUpd(true)
      await getVehicles(vehicleId, results => setVehicles(results))
      setVehicleSelected(vehicleId)
    } catch (error) {

    } finally {
      setLoadingVehicleUpd(false)
    }
  }

  useEffect(
    () => {
      if (carSelectedForUpd) {
        handleVehicleSelectedUPD(carSelectedForUpd)
      }
    },
    [carSelectedForUpd]
  )

  useEffect(
    () => {
      setCarModeUI(isNew ? 'new' : 'search')
    },
    [isNew]
  )

  useEffect(
    () => {
      loadData()
    }, []
  )

  const options = vehicles.map(d => <Option key={d.id}>{`${d.model?.brand?.name ? d.model?.brand?.name : ''} ${d?.model?.name ? d?.model?.name : ''} ${d?.color?.name} Placas: <${d.license_plate?d.license_plate:'N/A'}>  VIN: <${d.vin?d?.vin.toUpperCase():'--'}>`}</Option>);

  const rulesRequiredVehicleSearch = [
    {
      required: !isNew && !vehicleSelected,
    },
  ]

  return (
    <>
      <Row gutter={24}>
        {!vehicleSelected && !isNew && (
          <Col lg={12} xs={24} sm={24} md={24}>
            <ItemForm
              label={<FormattedMessage id="services.createNew.unit" />}
              name="unitSearch"
              rules={rulesRequiredVehicleSearch}
            >
              <Select
                style={{ margin: 10, color: "blue" }}
                size="large"
                loading={loading}
                showSearch
                disabled={isNew}
                value={vehicleSelected}
                placeholder={
                  <FormattedMessage id="services.createNew.labelUnit" />
                }
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                onSearch={vehicleHandleSearch}
                onChange={vehicleHandleChange}
                notFoundContent={null}
                allowClear
              >
                {options}
              </Select>
            </ItemForm>
          </Col>
        )}
        {vehicleSelected && (
          <Col lg={24} xs={24} sm={24} md={24}>
            <Breadcrumb>
              <Breadcrumb.Item
                className="customer-option"
                onClick={() => setVehicleSelected(null)}
              >
                <ArrowLeftOutlined />
                <span>
                  <FormattedMessage id="search.again" />
                </span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        )}
      </Row>
      {vehicleSelected || isNew ? (
        <UnitFormItems
          fieldsRequired={!vehicleSelected}
          readOnly={false}
          unitData={vehicleDataSelected}
          handleChangeGasType={handleChangeGasType}
          handleCarTypes={handleCarTypes}
          loadingCarTypes={loadingCarTypes}
          loadingCarModels={loadingCarModels}
          loadingCarColors={loadingCarColors}
          loadingCarBrands={loadingCarBrands}
          vinLength={vinLength}
          handleCarBrands={handleCarBrands}
          handleCarModel={handleCarModel}
          handleCarColor={handleCarColor}
          carColors={carColors}
          carTypeSelected={carTypeSelected}
          viewBrand={viewBrand}
          carBrands={carBrands}
          carModels={carModels}
          carTypes={carTypes}
          loading={loading}
          setIsValidVin={setIsValidVin}
          carModeUI={carModeUI}
          isUpd={isUpd}
          form={form}
        />
      ) : (
        ""
      )}
      <Row style={{ marginBottom: 20 }}>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item
              className="customer-option"
              onClick={toogleNewVehicle}
            >
              {isNew ? (
                <>
                  <SearchOutlined />
                  <span>
                    <FormattedMessage id="services.createNew.searchUnit" />
                  </span>
                </>
              ) : !isUpd && (
                <>
                  <PlusOutlined />
                  <span>
                    <FormattedMessage id="services.createNew.addNew" />
                  </span>
                </>
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </>
  );
}

const { Meta } = Card;

const CutomerCardReadOnly = ({ customerData }) => {

  const first_name = _.get(customerData, 'first_name')
  const last_name = _.get(customerData, 'last_name')
  const mlast_name = _.get(customerData, 'mlast_name')
  const address = _.get(customerData, 'address')
  const email = _.get(customerData, 'email')

  const full_name = `${first_name} ${last_name}`

  return (
    <Card
      style={{ width: 300 }}
      cover={
        <img
          alt="example"
          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
        />
      }
      actions={[]}
    >
      <Meta
        avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
        title={full_name}
        description={email}
      />
    </Card>
  )
}

export const CustomerForm = ({ setCustomerdataHeader, setCustomerModeUI, customerSelectedForUpd, customerSelectedForUpdData, isUpd, customerTypeData='PF',onSetCustomerType }) => {

  const [customerSelected, setCustomerSelected] = useState(null)
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingCustomerUpd, setLoadingCustomerUpd] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [_customerType, setCustomerType] = useState(customerTypeData)
  const [customerSelectedData, setCustomerSelectedData] = useState(null)
  const {settingsKikert, getMetaValue} = useSettingsKikert()
  const { TabPane } = Tabs;
  const ADDRESS_TYPE=getMetaValue('ADDRESS_TYPE');


  useEffect(() => {
    
    if (customerSelectedForUpd) {
      setCustomerSelectedData(customerSelectedForUpdData);
      setCustomerdataHeader(customerSelectedForUpdData);
    }
  }, [customerSelectedForUpd]);
  
  
  const getCustomers = async (search = '', callback = () => { }) => {
    try {
      setLoading(true)
      const response = await axiosApi.get(
        `${API.BUSINESS.BUSINESS_CUSTOMER}?search=${search}`
      );
      
      //setCustomers(response.data.results)
      callback(response.data.results)
    } catch (error) {
      callback([])
      console.error('Error al buscar clientes', error)
    } finally {
      setLoading(false)
    }
  }

  const debouncedCustomerSearch = _.debounce(
    (query, callback) => {
      getCustomers(query, callback)
    },
    500
  )

  const customerHandleSearch = (val) => {
    
    if (val)
      debouncedCustomerSearch(val, results => setCustomers(results))
  }

  const customerHandleChange = (val, val2) => {
    
    setCustomerSelected(val)
  }

  const setCutomerData = () => {
    
    const customerId = customerSelected ? parseInt(customerSelected) : 0
    const query = { 'id': customerId }
    const customerFound = _.find(customers, query)
    
    if (customerFound) {
      setCustomerSelectedData(customerFound)
      setCustomerdataHeader(customerFound)
      console.log("customerFound", customerFound);
    }
    else if(!customerSelectedForUpd && !customerSelectedForUpdData){
      setCustomerSelectedData(null);
    }
      
  }

  const handleUploadedCustomer = async (customerId) => {
    try {
      setLoadingCustomerUpd(true)
      await getCustomers(customerId, results => setCustomers(results))
      setCustomerSelected(customerId)
    } catch (error) {

    } finally {
      setLoadingCustomerUpd(false)
    }
  }

  useEffect(
    () => {
      if (customerSelectedForUpd) {
        handleUploadedCustomer(customerSelectedForUpd)
      }
    },
    [customerSelectedForUpd]
  )

  useEffect(
    () => {
      if (customerSelected) {
        setCutomerData()
      }
    }, [customerSelected]
  )

  useEffect(() => {
    
  }, [customerSelectedData])

  const toogleNewCustomer = () => {
    buscarDenuevo();
    const newValue = !isNew
    if (newValue) {
      setCustomerSelected(null)
    }
    setIsNew(newValue)
    //console.log('Customer UI', newValue, newValue ? 'new': 'search')
    setCustomerModeUI(newValue ? 'new' : 'search')
  }

  const options = customers.map(d => <Option key={d.id}>{`${d.first_name} ${d.last_name} ${d.email?d.email:''}`}</Option>);

  const rulesRequiredCustomerSearch = [
    {
      required: !isNew && !customerSelected,
    },
  ]

  const buscarDenuevo = () => {
    
    setCustomerSelectedData(null);
    setCustomerSelected(null);
  }

  return (
    <>
      {!isNew && (
        <>
          {!customerSelectedData ? (
            <Row>
              <Col span={12}>
                <ItemForm
                  label={<FormattedMessage id="services.createNew.client" />}
                  name="customer_search"
                  rules={rulesRequiredCustomerSearch}
                >
                  <Select
                    style={{ margin: 1, color: "blue" }}
                    size="small"
                    loading={loading}
                    showSearch
                    disabled={isNew}
                    value={customerSelected}
                    placeholder={<FormattedMessage id="services.createNew.labelClient" />}
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={false}
                    onSearch={customerHandleSearch}
                    onChange={customerHandleChange}
                    notFoundContent={null}
                    allowClear
                  >
                    {options}
                  </Select>
                </ItemForm>
              </Col>
            </Row>
          ) : (
            //Formulario solo de lectura al seleccionar cliente
              <Col span={24}>
                <Breadcrumb>
                  <Breadcrumb.Item
                      className="customer-option"
                      onClick={buscarDenuevo}
                  >
                    <ArrowLeftOutlined />
                    <span>
                  <FormattedMessage id="search.again" />
                </span>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>

          )}
        </>
      )}

      {isNew || customerSelectedData  ? (
        <Row gutter={[18]}>
          <Col span={8}>
            <ItemForm
                required
                label={"Tipo de persona"}
                name="person_type"
                rules={rulesRequired}
                initialValue={customerTypeData}
            >
              <Select
                  loading={loading}
                  style={{ width: "100%" }}
                  value={customerTypeData}
                  onChange={(value)=>onSetCustomerType(value)}
              >
                    <Option key={'F'} value={'PF'}>Persona Física</Option>
                    <Option key={'M'} value={'PM'}>Persona Moral</Option>
              </Select>
            </ItemForm>

          </Col>
          {
              customerTypeData==='PM' &&
              <Col span={8}>
                <ItemForm
                    label={"Razón social"}
                    rules={rulesRequired}
                    tooltip="Campo requerido"
                    name="company_name"
                >
                  <Input
                      placeholder="Razón social"
                      size="large"
                      className="item-form-user"
                  />
                </ItemForm>
              </Col>
          }



          <Col span={8}>
            <ItemForm
                label={customerTypeData==='PF'?<FormattedMessage id="layout.name" />:'Nombre de contacto'}
                name="customerName"
                rules={rulesRequired}
                tooltip="Campo requerido"
            >
              <Input
                  size="large"
                  className="item-form-user"
              />
            </ItemForm>
          </Col>
          <Col span={8}>
            <ItemForm
                label={customerTypeData==='PF'?'Apellidos':'Apellido de contacto'}
              rules={rulesRequired}
              name="customerMiddleName"
              tooltip="Campo requerido"
            >
              <Input
                size="large"
                className="item-form-user"
              />
            </ItemForm>
          </Col>
          {/*<Col span={8}>*/}
          {/*  <ItemForm*/}
          {/*    label={<FormattedMessage id="services.createNew.lastNameMother" />}*/}
          {/*    name="customerLastName"*/}
          {/*  >*/}
          {/*    <Input*/}
          {/*      placeholder="Apellido materno"*/}
          {/*      size="large"*/}
          {/*      className="item-form-user"*/}
          {/*    />*/}
          {/*  </ItemForm>*/}
          {/*</Col>*/}
          <Col span={8}>
            <ItemForm
              label={<FormattedMessage id="security.login.email" />}
              name="email"
              rules={[ruleEmail]}
            >
              <Input
                placeholder="Correo electrónico"
                size="large"
                className="item-form-user"
              />
            </ItemForm>
          </Col>
          <Col span={8}>
            <ItemForm
              label={<FormattedMessage id="services.createNew.celNumber" />}
              name="telephone"
            >
              <Input
                placeholder="Teléfono"
                size="large"
                className="item-form-user"
              />
            </ItemForm>
          </Col>
          <Col span={8}>
            <ItemForm
                label={"RFC"}
                name="rfc"
                rules={customerTypeData==='PM'?rulesRequired:null}
            >
              <Input
                  placeholder="RFC"
                  size="large"
                  className="item-form-user"
              />
            </ItemForm>
          </Col>
          <Col span={8}>
            <ItemForm
              label={<FormattedMessage id="services.createNew.address" />}
              name="address"
            >
              <Input
                placeholder="Dirección"
                size="large"
                className="item-form-user"
              />
            </ItemForm>
          </Col>
          {
              ADDRESS_TYPE !=='SIMPLE' &&
              <>

                <Col span={8}>
                  <ItemForm
                      label={'Núm. Ext.'}
                      name="ext_num"
                  >
                    <Input
                        placeholder="Núm Int."
                        size="large"
                        className="item-form-user"
                    />
                  </ItemForm>
                </Col>
                <Col span={8}>
                  <ItemForm
                      label={'Núm. Int.'}
                      name="int_num"
                  >
                    <Input
                        placeholder="Núm Int."
                        size="large"
                        className="item-form-user"
                    />
                  </ItemForm>
                </Col>
                <Col span={8}>
                  <ItemForm
                      label={'Colonia'}
                      name="colony"
                  >
                    <Input
                        placeholder="Colonia"
                        size="large"
                        className="item-form-user"
                    />
                  </ItemForm>
                </Col>
                <Col span={8}>
                  <ItemForm
                      label={'C.P'}
                      name="zip_code"
                  >
                    <Input
                        placeholder="C.P"
                        size="large"
                        className="item-form-user"
                    />
                  </ItemForm>
                </Col>
                <Col span={8}>
                  <ItemForm
                      label={'Delegación y municipio'}
                      name="municipality"
                  >
                    <Input
                        placeholder="Delegación y municipio"
                        size="large"
                        className="item-form-user"
                    />
                  </ItemForm>
                </Col>
                <Col span={8}>
                  <ItemForm
                      label={'Estado'}
                      name="state"
                  >

                    <Select
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        /* value={carInsuranceSelected} */
                    >
                      {/*ELEGIR ASEGURADORA*/}
                      {stateList.map((item, idx) => (
                          <Option key={idx} value={item}>{item}</Option>
                      ))}
                    </Select>


                  </ItemForm>
                </Col>
              </>
          }

          <Col span={8}>
            <ItemForm
                name="is_owner"
                valuePropName="checked"
            >
              
              <Checkbox style={{marginTop: 30}}><b>¿Es el dueño?</b></Checkbox>
            </ItemForm>
          </Col>
          



        </Row>
      ) : <> </>}

      <Row style={{ marginBottom: 20 }}>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item
              className="customer-option"
              onClick={toogleNewCustomer}
            >
              {isNew ? (
                <>
                  <SearchOutlined />
                  <span><FormattedMessage id="services.createNew.searchClient" /></span>
                </>
              ) : !isUpd && (
                <>
                  <UserAddOutlined />
                  <span><FormattedMessage id="services.createNew.addNew" /></span>
                </>
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </>
  );
}

const InsuranceForm = ({ changeLangAction, intl, settings, userAgentSelected, insurance, ...props }) => {
  

  const [carInsurances, setCarInsurances] = useState([]);
  const [carInsuranceSelected, setCarInsuranceSelected] = useState(null);
  const [carInsurancesAgent, setCarInsurancesAgents] = useState([]);
  const [carInsuranceAgentSelected, setCarInsuranceAgentSelected] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const withtInsurance = useSelector(state => state.settings.insuranceIsActive);

  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Seleccione una fecha",
      },
    ],
  };

  const [viewAgentes, setViewAgentes] = useState()

  //FUNCIÓN PARA OBTENER VALUE DE LA ASEGURADORA
  const handleInsuranceSelected = (val) => {
    const nameAgenteAseguradora = val;
    const filtrosAseguradora = carInsurancesAgent.filter(element => element.insurance === nameAgenteAseguradora)
    setViewAgentes(filtrosAseguradora)
    //carInsurancesAgent.map((item) => (console.log(item)))
    setCarInsuranceSelected(val);
  };

  const handleInsuranceAgentSelected = (val) => {
    setCarInsuranceAgentSelected(val);
  };

  const getInsurancesAgents = async () => {
    const url = `/workshop/insurance-agent/`;
    const response = await axiosApi.get(url);
    setCarInsurancesAgents(response.data.results);
  };

  const getInsurances = async () => {
    const url = `/workshop/insurance/`;
    const response = await axiosApi.get(url);
    setCarInsurances(response.data.results);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      await getInsurances();
      await getInsurancesAgents();
      setCarInsuranceAgentSelected(userAgentSelected);
      setCarInsuranceSelected(insurance);
      
    } catch (error) {
      // console.log("Error al cargar datos iniciales", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
         <>
      <Row gutter={24}>
        <Col span={8}>
          <ItemForm
            label={<FormattedMessage id="services.createNew.siniestroNumber" />}
            name="sinisterNumber"
            rules={rulesRequired}
            tooltip="Campo requerido"
          >
            <Input placeholder="Número de siniestro" />
          </ItemForm>
        </Col>
        <Col span={8}>  
          <ItemForm
            required
            label={<FormattedMessage id="services.insure" />}
            name="insurance"
            rules={rulesRequired}
          /* initialValue={carInsuranceSelected} */
          >
            <Select
              loading={loading}
              style={{ width: "100%" }}
              /* value={carInsuranceSelected} */
              onChange={handleInsuranceSelected}
            >
              {/*ELEGIR ASEGURADORA*/}
              {carInsurances.map((item) => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </ItemForm>
        </Col>
        <Col span={8}>
          <ItemForm
            required
            label={<FormattedMessage id="services.createNew.adjuster" />}
            name="insuranceAgent"
            rules={rulesRequired}
          >
            <Select
              loading={loading}
              style={{ width: "100%" }}
              /* value={carInsuranceAgentSelected} */
              onChange={handleInsuranceAgentSelected}
            >
              {/*ELEGIR AGENTES*/}
              {viewAgentes ?
                viewAgentes.map((item) => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                )) : ''
              }
              {/*{carInsurancesAgent.map((item) => (
                <Option key={item.id} value={item.id} >{item.name}</Option>
              ))}*/}
            </Select>
          </ItemForm>

        </Col>

      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <ItemForm
            label={<FormattedMessage id="services.createNew.numberGuaranty" />}
            name="insuranceNumber"
          >
            <Input placeholder="Número de póliza" />
          </ItemForm>
        </Col>
        <Col span={12}>
          <ItemForm
            name="dueDateInsurance"
            label={<FormattedMessage id="services.createNew.dateValidityGuaranty" />}
          >
            <DatePicker style={{ width: "100%" }} />
          </ItemForm>
        </Col>
        <Col lg={5} xs={24} sm={24} md={24} >
          <Form.Item name="with_deductible" valuePropName="checked">
            <Checkbox><b>¿Aplica deducible?</b></Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const ServiceForm = ({ intl,userStore, ...props }) => {
  const [loading, setLoading] = useState(true)
  const [isValidVin, setIsValidVin] = useState(true)
  const [form] = Form.useForm();
  const [customerData, setCustomerData] = useState(null)
  const [carSelectedForUpd, setCarSelectedForUpd] = useState(null)
  const [customerSelectedForUpd, setCustomerSelectedForUpd] = useState(null)
  const [customerSelectedForUpdData, setCustomerSelectedForUpdData] = useState(null);
  const [customerType, setCustomerType] = useState('PF');
  const [car, setCar] = useState(null)
  const [carModeUI, setCarModeUI] = useState('search')
  const [customerModeUI, setCustomerModeUI] = useState('search')
  const [requiredMark, setRequiredMarkType] = useState('Opcional');
  const [insuranceType, setInsuranceType] = useState('P')
  const [gasTypeSelected, setGasTypeSelected] = useState("GAS")
  const {getMetaValue} = useSettingsKikert()
  const [initialData, setInitialData] = useState({
    requiredMarkValue: requiredMark,
    insuranceType: getMetaValue('DEFAULT_WORKSHOPORDER_TYPE')==='INSURANCE'?'I':'P',
    recepteionDate: moment(),
    gasType: 'GAS'
  })
  const withCustomerDefaultId = useSelector(state => state.settings.defaultCustomerId);
  const withCustomerDefaultUrl = useSelector(state => state.settings.defaultCustomerUrl);
  //const userData = getUserDataLogged()
  const history = useHistory()
  const { id } = useParams();
  const isUpd = id ? true : false


  useEffect(()=>{
    //console.log('useeffect', isUpd, userData)
    const userData = getUserDataLogged()
    if(!isUpd){
      form.setFieldsValue({
        assigned_to: userData?.user_id,
        order_type: 'Estándar',
        metaLevel: '1_250',
        order_version: 'CBU',
      })
    }
  },[])


  const keySave = 'keySave'

  const validateMessages = {
    required: '${label} ' + intl.formatMessage({ id: "services.saleOrder.obligatory" }),
    types: {
      email: '${label} no es un email válido',
      number: '${label} no es un número válido',
    },
    number: {
      range: '${label} debe ser entre ${min} y ${max}',
    },
  };

  const getCustomerDefaultId = async (customerId, callback = () => { }) => {
    try {
      setLoading(true)
      const response = await axiosApi.get(
        `${API.BUSINESS.BUSINESS_CUSTOMER}${customerId}`
      );
      //setCustomers(response.data.results)
      callback(response.data)
    } catch (error) {
      callback([])
      console.error('Error al buscar clientes', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    withCustomerDefaultId && withCustomerDefaultId.id && getCustomerDefaultId(withCustomerDefaultId.value, setCustomerData)
  }, [withCustomerDefaultId])

  const setInitialDataMap = (initialDataIn) => {
    const initial = {
      recepteionDate: moment(initialDataIn.created_at),
      orderNumber: initialDataIn.order_number,
      pyramid: initialDataIn?.WORKSHOP?.pyramid,
      insuranceType:initialDataIn.WORKSHOP.insurance_type,
      sinisterNumber: initialDataIn.WORKSHOP.sinister_number,
      insuranceNumber: initialDataIn.WORKSHOP.policy_number,
      assigned_to: initialDataIn.assigned_to?.id,
      order_subtype: initialDataIn?.order_subtype || 'STANDARD',
      metaLevel: initialDataIn?.WORKSHOP?.metadata?.metaLevel,
      order_type: initialDataIn?.WORKSHOP?.metadata?.order_type,
      order_version: initialDataIn?.WORKSHOP?.metadata?.order_version,
      with_deductible: initialDataIn?.WORKSHOP?.with_deductible,
      dueDateInsurance: initialDataIn.WORKSHOP.policy_expiration_date
        ? moment(initialDataIn.WORKSHOP.policy_expiration_date)
        : null,
      comments: initialDataIn.comment,
      insuranceAgent: initialDataIn.WORKSHOP.insurance_agent.id,
      insurance: initialDataIn.WORKSHOP.insurance.id,
      is_owner: initialDataIn?.WORKSHOP?.is_owner
    };
    const customerId = initialDataIn.customer.id
    setCarSelectedForUpd(initialDataIn.WORKSHOP.vehicle.id)
    setInsuranceType(initialDataIn.WORKSHOP.insurance_type)
    setCustomerSelectedForUpd(customerId)
    //console.log("Customer", initialDataIn.customer)
    setCustomerSelectedForUpdData(initialDataIn.customer);
    setInitialData(initial)
    form.setFieldsValue(initial);
  }

  const setDefaultUnitValue = (item, value) => {
    form.setFieldsValue({
      [item] : value
    });
  }

  const loaddata = async (expedientId) => {
    try {
      setLoading(true)
      
      const url = `${API.WORKSHOP.WORKSHOP_ORDER_DETAIL}${expedientId}`
      const response = await axiosApi.get(url)
      //console.log("response===>", response)
      
      setInitialDataMap(response.data)
    } catch (error) {
      //console.error('No se pudo obtener información', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      loaddata(id)
    } else {
      console.log('es nuevo')
      form.setFieldsValue({
        assigned_to: userStore.id
      })
      setLoading(false)
    }
  }, [id])





  //const onRequiredTypeChange = ({ requiredMarkValue }) => {
  const onRequiredTypeChange = (changedValues, allValues) => {
    const { requiredMarkValue } = changedValues
    const { insuranceType } = allValues
    
    setRequiredMarkType(requiredMarkValue);
    setInsuranceType(insuranceType)
  };

  const save = async request => {
    try {
      setLoading(true)
      if(request.serialNumber){
        request.serialNumber = request.serialNumber.toUpperCase()
      }
      const loadingMsg = message.loading({ content: intl.formatMessage({ id: "services.createNew.saving" }), keySave })
      const url = API.WORKSHOP.WORKSHOP_ORDER
      const response = await axiosApi.post(url, request)
      
      message.success({ content: intl.formatMessage({ id: "services.createNew.saved" }), keySave });
      /* history.push(`/services/list`) */
      history.push(`/service-detail/${response.data.order_id}`);
    } catch (error) {
      //console.log('Error al guardar el servicio', error)
      message.error({
        content: intl.formatMessage({ id: "services.createNew.err" }), keySave
      })
    } finally {
      setLoading(false)
    }
  }

  const getRequest = (values) => {
    const user = getUserDataLogged()
    let vehicle = null
    let customer = null
    let metadata=null
    // if(carModeUI === 'search'){
    //   vehicle = {
    //     id: car.id
    //   }
    // }

    //if (carModeUI === 'new') {
      vehicle = {
        model: values?.carModel,
        color: values.carColor,
        order_subtype: values?.order_subtype ? values?.order_subtype : 'STANDARD',
        id: car && car.id ? car.id : null,
        license_plate: values.plate,
        economic_number: values.economic_number,
        transmission: values.transmission,
        type: values.carType,
        //owner: user.user_id,
        //type: 1,
        //vin: values.serialNumber,
        year: values.year
      }
    if(!isUpd){
      vehicle.vin = values?.serialNumber.toUpperCase()
    }

    if(tenantSelected==='jacmexico'){
       metadata = {
         metaLevel: values.metaLevel,
         order_type: values.order_type ? values.order_type : 'Estándar',
         order_version: values.order_version ? values.order_version : ''
       }
    }

    //}
    //if (customerModeUI === 'new') {
      customer = {
        id: customerData && customerData.id ? customerData.id : null,
        first_name: withCustomerDefaultId ? customerData.first_name : values.customerName,
        last_name: withCustomerDefaultId ? customerData.last_name : values.customerMiddleName,
        //mlast_name: withCustomerDefaultId ? customerData.mlast_name : values.customerLastName,
        email: withCustomerDefaultId ? customerData.email : values.email,
        phone_number: withCustomerDefaultId ? customerData.phone_number : values.telephone,
        address: withCustomerDefaultId ? customerData.address : values.address,
        ext_num: withCustomerDefaultId ? customerData.ext_num : values.ext_num,
        int_num: withCustomerDefaultId ? customerData.int_num : values.int_num,
        colony: withCustomerDefaultId ? customerData.colony : values.colony,
        municipality: withCustomerDefaultId ? customerData.municipality : values.municipality,
        state: withCustomerDefaultId ? customerData.state : values.state,
        zip_code: withCustomerDefaultId ? customerData.zip_code : values.zip_code,
        company_name: withCustomerDefaultId ? customerData.company_name : values.company_name,
        person_type: withCustomerDefaultId ? customerData.person_type : values.person_type,
        rfc: withCustomerDefaultId ? customerData.rfc : values.rfc
      }
    //}

    //onbtenemos el company seleccionado
    let localstore = localStorage.getItem('settings_kk');
    if(localstore){
      localstore = JSON.parse(localstore)
    }

    let request = {
      created_at: moment(values.recepteionDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
      branch: localstore?.branchSelected?.id,
      order_type_code: 'WORKSHOP',
      pyramid: values.pyramid,
      with_deductible: values.with_deductible,
      order_number: values.orderNumber,
      created_by: user.user_id,
      assigned_to: values.assigned_to,
      vehicle_id: null,
      customer_id: null,
      customer: customer,
      vehicle: vehicle,
      order_subtype: values?.order_subtype ? values?.order_subtype : 'STANDARD',
      insurance_type: values.insuranceType,
      comment: values.comments,
      insurance_agent_id: null,
      policy_number: null,
      metadata:metadata,
      policy_expiration_date: null,
      sinister_number: null,
      insurance_id: null,
      status: 'OPEN',
      is_owner: values.is_owner
    }

    if (isUpd) {
      request.id = parseInt(id);
    }


    if (values.insuranceType === 'I') {
      request.insurance_id = values.insurance
      request.insurance_agent_id = values.insuranceAgent
      request.policy_number = values.insuranceNumber
      request.policy_expiration_date = values.dueDateInsurance ? values.dueDateInsurance.format('YYYY-MM-DD') : null
      request.sinister_number = values.sinisterNumber
    }
    if (carModeUI === 'search') {
      request.vehicle_id = car.id
    }

    if (carModeUI === 'new') {
      request.vehicle = vehicle
    }

    if (customerModeUI === 'search') {
      request.customer_id = customerData.id
    }

    if (customerModeUI === 'new') {
      request.customer = customer
    }
    return request
  }

  const onFinish = (values) => {
    if(isValidVin){
      const request = getRequest(values)
      save(request)
    }else{
      message.error({ content: "Introduce un VIN válido", keySave });
    }
  };

  const onFinishFailed = (errorInfo) => {
    
    const d = form.getFieldsValue();

    message.warning(intl.formatMessage({ id: "services.saleOrder.validateData" }))
  };

  
  useEffect(() => {
    //console.log("car", car);
    if (car || customerData) {
      //console.log("ONLY ONE", customerData)
      form.setFieldsValue({
        // Customer data
        customerName: customerData ? customerData.first_name : null,
        customerLastName: customerData ? customerData.last_name : null,
        customerMiddleName: customerData ? customerData.last_name : null,
        email: customerData ? customerData.email : null,
        telephone: customerData ? customerData.phone_number : null,
        address: customerData ? customerData.address : null,
        ext_num: customerData ? customerData.ext_num : null,
        int_num: customerData ? customerData.int_num : null,
        colony: customerData ? customerData.colony : null,
        state: customerData ? customerData.state : null,
        zip_code: customerData ? customerData.zip_code : null,
        municipality: customerData ? customerData.municipality : null,
        company_name: customerData ? customerData.company_name : null,
        person_type:customerData?.person_type,
        rfc:customerData?.rfc
      });

      if(customerData?.person_type){
        setCustomerType(customerData?.person_type)
      }


    }
  }, [customerData]);


  useEffect(() => {
    //console.log("car", car);
    if (car || customerData) {
      //console.log("ONLY ONE", customerData)
      if(car){
        form.setFieldsValue({
          serialNumber: car ? car?.vin?.toUpperCase() : null,
          year: car ? car.year:null,
          plate: car ? car.license_plate : null,
          economic_number: car ? car.economic_number : null,
          transmission: car ? car.transmission : null,
          carType: car ? car.type : null,
          carBrand:
              car && car.model && car.model.brand
                  ? parseInt(car.model.brand.id)
                  : null,
          carModel: car && car.model ? parseInt(car.model.id) : null,
          carColor: car && car.color ? parseInt(car.color.id) : null,
        });
      }

    }
  }, [car]);

  return (
    <FormContainer>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          layout="vertical"
          initialValues={initialData}
          onValuesChange={onRequiredTypeChange}
          requiredMark={requiredMark}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <HeaderSection>
            <Title level={4}>
            <RocketOutlined />{" "}
              <FormattedMessage id="services.createNew.reception" />
            </Title>
          </HeaderSection>

          <ServiceReceptionForm isUpd={isUpd} insuranceType={insuranceType} />

          <HeaderSection>
            <Title level={4}>
            <RocketOutlined /> <FormattedMessage id="services.createNew.unit" />
            </Title>
          </HeaderSection>

          <UnitForm
            carSelectedForUpd={carSelectedForUpd}
            setCar={setCar}
            setCarModeUI={setCarModeUI}
            isUpd={isUpd}
            setIsValidVin={setIsValidVin}
            setGasTypeSelected={setGasTypeSelected}
            carModeUI={carModeUI}
            setDefaultUnitValue={setDefaultUnitValue}
            form={form}
          />

          <HeaderSection>
            <Title level={4}>
              <UserOutlined />{" "}
              <FormattedMessage id="services.createNew.client" />
            </Title>
          </HeaderSection>

          {
            !withCustomerDefaultId ? <CustomerForm
            customerSelectedForUpd={customerSelectedForUpd}
            customerSelectedForUpdData={customerSelectedForUpdData}
            setCustomerModeUI={setCustomerModeUI}
            customerTypeData={customerType}
            onSetCustomerType={(val)=>setCustomerType(val)}
            isUpd={isUpd}
            setCustomerdataHeader={setCustomerData}
          /> :
              <Card
                hoverable={false}
                style={{ width: 140, marginBottom: 30 }}
                cover={<img alt="example" 
                onError={(e) => { e.target.onerror = null; e.target.src = 'https://jac.mx/assets/images/new_logo_JAC_black.png'; }}
                src={withCustomerDefaultUrl} />}
              >
                <Meta title={`${customerData?.first_name}  ${customerData?.last_name}`} />
              </Card>
          }

          {insuranceType === "I" && (
            <>
              <HeaderSection>
                <Title level={4}>
                  <SecurityScanOutlined />{" "}
                  <FormattedMessage id="services.insure" />
                </Title>
              </HeaderSection>
              <InsuranceForm
                userAgentSelected={_.get(initialData, "insuranceAgent")}
                insurance={_.get(initialData, "insurance")}
                isUpd={isUpd}
              />
            </>
          )}
          <HeaderSection>
            <Title level={4}>
              <FormattedMessage id="services.createNew.comments" />
            </Title>
          </HeaderSection>

          <ItemForm
            label={intl.formatMessage({
              id: "services.createNew.addInformation",
            })}
            name="comments"
          >
            <TextArea
              rows={4}
              placeholder={intl.formatMessage({
                id: "services.createNew.labelComments",
              })}
            />
          </ItemForm>

          <Row justify="center" gutter={16}>
            <Col>
                <Button
                  type="default"
                  htmlType="button"
                  onClick={() => history.push("/services/list/")}
                  className="btn-blue btn-report-action"
                  size="large"
                >
                  <FormattedMessage id="layout.cancel" />
                </Button>
            </Col>
            <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-string-blue btn-report-action"
                  size="large"
                >
                  <FormattedMessage id="services.filter.save" />
                </Button></Col>
          </Row>
        </Form>
      )}
    </FormContainer>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  userStore: state?.user,
});

export default injectIntl(
  connect(mapStateToProps, { changeLangAction })(ServiceForm)
); 