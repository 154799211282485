import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {injectIntl } from "react-intl";
import {
    Layout,
    Card,
    Row,
    Col,
    Spin,
    Form,
    DatePicker,
    Divider,
    message, 
    Typography, Checkbox,
    Input,
    Tag
} from 'antd'
import ServiceLayout from "../../layouts/ServiceLayout";
import MenuServiceDetail from "../../components/services/MenuServiceDetail";
import SectionTitle from "../../components/common/elements/SectionTitle/SectionTitle";
import KButton from "../../components/common/elements/KButton/KButton";
import {useHistory, useParams} from "react-router-dom";
import {getServiceDetail} from '../../redux/serviceOrderDuck'
import _ from "lodash";
import locale from "../../locale/es_mx.json";
import {ruleRequired, twoDecimal} from "../../lib/utils/rules";
import axiosApi, {API} from "../../lib/utils/axiosApi";
import {CustomTable} from "../../components/KComponents";
import KModal from "../../components/common/elements/KModal/KModal";
import KSelect from "../../components/common/elements/KSelect/KSelect";
import moment from "moment";
import SelectAsesor from "../../components/Selects/SelectAsesor";
import SelectBudgetReceivers from "../../components/Selects/SelectBudgetReceivers";
import {DownloadOutlined, CheckCircleFilled, UpOutlined,
    DownOutlined, PauseOutlined} from "@ant-design/icons";
import {CSVLink} from "react-csv";
import NumberFormat from "../../components/common/NumberFormat";
import SelectSuppliers from "../../components/Selects/SelectSuppliers";
import MainLayoutv2 from '../../layouts/MainLayoutv2';
import useFetch from "../../hooks/useFetch";

const WorkshopPartsPage=({intl,getServiceDetail,serviceOrder, ...props})=>{

    let { id } = useParams();
    let history = useHistory();
    const [loading, setLoading] = useState(false)
    const [editObj, setEditObj] = useState(false)
    const [form] = Form.useForm();
    const [parts, setParts] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [showLogModal, setShowLogModal] = useState(false)
    const [totalParts, setTotalParts] = useState(0)
    const [pendingParts, setPendingParts] = useState(null)
    const [warehouseSelected, setWarehouseSelected] = useState(null)
    const [budgetItemLog, setBudgetItemLog] = useState([])
    const [selectedBudgetItem, setSelectedBudgetItem] = useState(null)


    const warehouseResponse = useFetch(API.WORKSHOP.WORKSHOP_GET_WAREHOUSES)
    const warehouseSectionResponse = useFetch(API.WORKSHOP.WORKSHOP_GET_WAREHOUSE_SECTIONS)


    const { TextArea } = Input;

    useEffect(() => {
        selectedBudgetItem?.id && loadBugetItemLog(selectedBudgetItem?.id)
    }, [selectedBudgetItem])

    useEffect(()=>{
        if (id) {
            getServiceDetail(id); // Why? because when we refresh the page, it can't load service detail data
            getValuationParts(id);
        }
    },[])

    useEffect(()=>{
        /*form.setFieldsValue({
            warehouse_section: null
        })*/
    },[warehouseSelected])

    useEffect(()=>{
        if(serviceOrder?.serviceDetail?.order_dynamic_data?.valuation && !loading){
            getValuationParts()
        }
    },[serviceOrder])

    const loadBugetItemLog = async (bugetItemId) => {
        setLoading(true)
        try{
            let url = API.WORKSHOP.WORKSHOP_BUDGET_LOG
            url = url.replace('{budget_item_id}', bugetItemId);
            const resp = await axiosApi.get(url);
            setBudgetItemLog(resp?.data?.logs)
            //console.log("Resp log", resp)
            //message.success('Actualizado correctamente')
        }catch (e){
            message.error('Ha ocurrido un error, porfavor intenta nuevamente')
        }finally {
            setLoading(false)
        }
    }

    const editPart=async (idPart, data)=>{
        console.log("data", data)
        setLoading(true)
        try{
            const resp = await axiosApi.patch(
                `${API.WORKSHOP.WORKSHOP_BUDGET }${idPart}/`,
                data
            );
            message.success('Actualizado correctamente')
            getValuationParts();
        }catch (e){
            message.error('Ha ocurrido un error, porfavor intenta nuevamente')
        }finally {
            setShowModal(false)
            setLoading(false)
        }

    }

    const getValuationParts = async () => {
        setLoading(true)
        try {
            let _totalParts = 0;
            let _pendingParts = 0;
            const resp = await axiosApi.get(
                `${API.WORKSHOP.WORKSHOP_BUDGET }?job_type__code=PARTS&order=${id}&page_size=1000000`
            );
            console.log(resp)
            if(resp?.data?.results){
                setParts(resp.data.results);
                resp.data.results.forEach((item)=>{
                   _totalParts=_totalParts+parseInt(item.qty);
                   _pendingParts = _pendingParts + (item.delivered_to?0:1)
                });
                setTotalParts(_totalParts)
                setPendingParts(_pendingParts)
            }


        } catch (error) {
            setParts([])
            console.log("error", error );
        }finally {
            setLoading(false)
        }

    }

    const openLogModal = (item) => {
        setShowLogModal(true)
        setSelectedBudgetItem(item)
    }

    const editData=(item)=>{
        setShowModal(true)
        setEditObj(item)
        form.resetFields()


        if(item.cost){
            form.setFieldsValue({
                cost:parseFloat(item.cost)
            })
        }

        if(item.priority){
            form.setFieldsValue({
                priority:item.priority
            })
        }


        if(item.cost){
            form.setFieldsValue({
                price:parseFloat(item.price)
            })
        }

        if(item.delivery_real_date){
            form.setFieldsValue({
                delivery_real_date:moment(item.delivery_real_date)
            })
        }

        if(item.supplier){
            form.setFieldsValue({
                supplier:item.supplier.id
            })
        }

        if(item.reception_date){
            form.setFieldsValue({
                reception_date: moment(item.reception_date)
            })
        }

        if(item.delivery_warehouse_date){
            form.setFieldsValue({
                delivery_warehouse_date: moment(item.delivery_warehouse_date)
            })
        }

        if(item.warehouse){
            form.setFieldsValue({
                warehouse: item?.warehouse?.id
            })
            setWarehouseSelected(item?.warehouse?.id)
        }


        if(item.warehouse_section){
            form.setFieldsValue({
                warehouse_section: item?.warehouse_section?.id
            })
        }

        if(item.delivery_promise_date){
            form.setFieldsValue({
                delivery_promise_date: moment(item.delivery_promise_date)
            })
        }

        if(item.delivered_to){
            form.setFieldsValue({
                delivered_to: item?.delivered_to?.id
            })
        }

        const { delivery_canceled, delivery_canceled_reason } = item
        form.setFieldsValue({
            delivery_canceled,
            delivery_canceled_reason
        })

    }


    const priorityList = [
        { value:1, label:  ` Alta`},
        { value:2, label:' Media'},
        { value:3, label:' Baja'}
    ]



    const columns =  [
        {
            title: "Prioridad ",
            dataIndex: "priority",
            key: "priority",
            render: (item) => {
                let priority = priorityList.find((element) => element.value === item);
                return <span>
                     {
                         item === 1 ? <UpOutlined style={{color: 'red'}}/> :
                             item === 3 ? <DownOutlined style={{color: 'blue'}}/> :
                                 <PauseOutlined rotate={90} style={{color: 'orange'}}/>
                     }
                    {
                        priority?.label
                    }
                </span>
            }
        },
        {
            title: 'Estatus valuación',
            key: 'status',
            render:(item)=>{
                if(item.status==="PENDING"){
                    return <Tag color="gold">Pendiente</Tag>
                }else if(item.status==="AUTH"){
                    return <Tag color="green">Autorizado</Tag>
                }else{
                    return <Tag color="red">Rechazado</Tag>
                }

            }
        },
        {
            title: 'Estatus entrega',
            key: 'status',
            render:(item)=>{
                return <>{item.delivered_to ? <Tag color="green">Entregado</Tag> : <Tag color="gold">Pendiente</Tag>}</>
            }
        },
            {
                title: 'Refacción',
                dataIndex: 'concept',
                key: 'concept',
            },
            {
                title: 'Cantidad',
                dataIndex: 'qty',
                key: 'qty'
            },
            {
                title: 'Costo unitario',
                dataIndex: 'cost',
                key: 'cost',
                render:(data)=>{
                    return <NumberFormat number={data} prefix={'$'}/>
                }
            },
            {
                title: 'Precio',
                dataIndex: 'price',
                key: 'price',
                render: (data) => {
                    return <NumberFormat number={data} prefix={'$'}/>
                }
            },
            {
                title: 'Tipo de proveedor',
                key: 'supplier_category_name',
                render:(record)=>{
                    return <span>{record?.supplier_category_name}</span>
                }
            },
            {
                title: 'Proveedor',
                key: 'supplier',
                render:(record)=>{
                    return <span>{record?.supplier?.name}</span>
                }
            },
            {
                title: 'Fecha promesa',
                dataIndex: 'delivery_promise_date',
                key: 'delivery_promise_date',
                render:(data)=>{
                    return <span>{data && moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                }
            },
            {
                title: 'Fecha recepción',
                dataIndex: 'reception_date',
                key: 'reception_date',
                render:(data)=>{
                    return <span>{data && moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                }
            },
            {
                title: 'Fecha almacén',
                dataIndex: 'delivery_warehouse_date',
                key: 'delivery_warehouse_date',
                render:(data)=>{
                    return <span>{data && moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                }
            },
            {
                title: 'Almacén',
                dataIndex: 'warehouse',
                key: 'warehouse',
                render:(data)=>{
                    return <span>{data?.name}</span>
                }
            },
            {
                title: 'Sección de almacén',
                dataIndex: 'warehouse_section',
                key: 'warehouse_section',
                render:(data)=>{
                    return <span>{data?.name}</span>
                }
            },
            {
                title: 'Fecha real de entrega',
                dataIndex: 'delivery_real_date',
                key: 'delivery_real_date',
                render:(data)=>{
                    return <span>{data && moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                }
            },
            {
                title: 'Entregado a',
                key: 'delivered_to',
                render:(record)=>{
                    return <span>{record?.delivered_to?.first_name} {record?.delivered_to?.last_name}</span>
                }
            },
            {
                title: 'Acciones',
                dataIndex: 'operation',
                render: (item,record) => {
                    return <>
                        <Typography.Link disabled={serviceOrder?.serviceDetail?.status === 'CLOSED'} onClick={()=>editData(record)}>
                            Editar
                        </Typography.Link>
                        <Divider type="vertical" />
                        <Typography.Link disabled={false} onClick={()=>openLogModal(record)}>
                            Historial de cambios
                        </Typography.Link>
                    </>
                },
            }
        ]

    const onFinish = (values) => {
        console.log("Vas", values)
        let data = {...values}
        if(data?.delivery_promise_date){
            data.delivery_promise_date = moment(data.delivery_promise_date).format('YYYY-MM-DD')
        }

        if(data.reception_date){
            data.reception_date = moment(data.reception_date).format('YYYY-MM-DD')
        }

        if(data.delivery_warehouse_date){
            data.delivery_warehouse_date = moment(data.delivery_warehouse_date).format('YYYY-MM-DD')
        }

        if(data?.delivery_real_date){
            data.delivery_real_date = moment(data.delivery_real_date).format('YYYY-MM-DD')
        }


        if(!data?.delivered_to){
            data.delivered_to = null
        }

        if(!data?.warehouse){
            data.warehouse = null
            data.warehouse_section = null
        }

        if(!data?.warehouse_section){
            data.warehouse_section = null
        }

        if(data?.delivery_canceled){
            data.delivered_to = null
            data.delivery_real_date = null
        }

        if(!data?.delivery_canceled && data?.delivered_to){
            data.delivery_canceled = false
            data.delivery_canceled_reason = null
        }

        if(!data?.supplier){
            data.supplier = null
        }

        editPart(editObj.id, data)
        console.log('Success:', data);
    };


    const headersCSV = [
        { label: "Estatus", key: "status" },
        { label: "Prioridad", key: "priority" },
        { label: "Refacción", key: "concept" },
        { label: "Cantidad", key: "qty" },
        { label: "Costo unitario", key: "cost" },
        { label: "Proveedor", key: "supplier" },
        {label:"Fecha promesa", key:'delivery_promise_date'},
        { label: "Fecha recepción", key: "reception_date" },
        { label: "Fecha real de entrega", key: "delivery_real_date" },
        { label: "Entregado a", key: "delivered_to" },
    ]

    const generateData = () => {
        const myArray = [];
        parts && parts.map((ele, i) => {
            //const FechaPrueba = new Date(_.get(chatItem, 'date', new Date()))
            //moment(FechaPrueba).format(format1)
            myArray.push({...ele,
                status: `${ele.delivered_to? 'Entregado':'Pendiente'}`,
                supplier: `${ele.supplier ? ele?.supplier?.name: '' }`,
                delivered_to:`${ ele?.delivered_to?.first_name ? ele?.delivered_to?.first_name : '' } ${ ele?.delivered_to?.last_name ? ele?.delivered_to?.last_name : ''}`});
        })
        return myArray;
    }

    const onValuesChangeFormPart = ({ delivery_canceled, warehouse }) => {
        console.log('warehouseSelected', warehouse)
        if(warehouse){
            //setWarehouseSelected(warehouse)
            form.setFieldsValue({
                warehouse_section: null
            })
        }else{
            //setWarehouseSelected(null)
        }
        if(delivery_canceled === undefined) return
        let partToEdit = editObj
        partToEdit.delivery_canceled = delivery_canceled
        setEditObj({...partToEdit})
        console.log("partToEdit", partToEdit)
    }

    return <>
        <MainLayoutv2 isServiceDetail={true} title={intl.formatMessage({ id: "servicesDetails.spareParts" })} index="serviceparts">
            <Spin spinning={serviceOrder.loading || loading}>
                <Layout style={{ marginTop: 10 }}>
                    <Card className="card_details_service">
                        <MenuServiceDetail menuSelected={3} />
                        <SectionTitle title={intl.formatMessage({ id: "servicesDetails.spareParts" })} />
                        <br/><br/><br/>

                        <Row>
                            <Col span={24}>
                                <p style={{margin:0, padding:0 }}><b>Registros encontrados:</b> {parts ? parts.length : 0} </p>
                                {
                                    (pendingParts && pendingParts>0) ? (<p style={{margin:0, padding:0 }}><b>Por entregar:</b> {pendingParts} </p>) : null
                                }

                                {
                                    (parts && parts.length>0 &&  pendingParts===0)?<p style={{margin:0, padding:0 }}> <b>Refacciones entregadas</b> {' '} <CheckCircleFilled style={{color:'green'}} /> </p>:null
                                }

                                <p><b>Total de partes:</b> {totalParts}</p>
                                <CSVLink
                                    headers={headersCSV}
                                    data={generateData()}
                                    filename={`parts_${serviceOrder?.serviceDetail?.folio}.csv`}
                                >
                                    <DownloadOutlined />{" Descargar lista"}
                                </CSVLink>
                            </Col>
                            <Col span={24}>
                                <Form form={form} component={false}>
                                    <div style={{ overflowX: 'auto' }}>
                                    <CustomTable
                                    pagination={{ pageSize: 50}}
                                    columns={columns} dataSource={parts}/>
                                    </div>
                                </Form>
                                
                            </Col>
                        </Row>
                    </Card>
                </Layout>
            </Spin>

            <KModal title={`${editObj.concept}`} onCancel={()=>setShowModal(false)} visible={showModal}>
                <Row>
                    <Col span={24}>
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                            onValuesChange={onValuesChangeFormPart}
                            layout={'vertical'}
                        >
                            <Form.Item  label={'Prioridad'} name="priority">
                                <KSelect
                                    style={{ width: 100 }}
                                    options={priorityList}
                                />
                            </Form.Item>

                            <Form.Item name={'cost'} label="Costo" rules={[twoDecimal]}>
                                <Input  className={'kinput'} prefix={'$'}/>
                            </Form.Item>

                            <Form.Item name={'price'} label="Precio" rules={[twoDecimal]}>
                                <Input  className={'kinput'} prefix={'$'}/>
                            </Form.Item>

                            <SelectSuppliers style={{marginBottom:20}} allowClear={true} name={'supplier'} label="Proveedor" />



                            <Form.Item name={'delivery_promise_date'} label="Fecha promesa de entrega">
                                <DatePicker
                                    locale={locale}
                                    className={'kinput'}
                                    style={{width:'100%'}}
                                    format={'DD/MM/YYYY'}
                                />
                            </Form.Item>
                            <Form.Item name={'reception_date'} label="Fecha de recepción en taller">
                                <DatePicker
                                    locale={locale}
                                    className={'kinput'}
                                    style={{width:'100%'}}
                                    format={'DD/MM/YYYY'}
                                />
                            </Form.Item>
                            <Form.Item name={'delivery_warehouse_date'} label="Fecha almacén">
                                <DatePicker
                                    locale={locale}
                                    className={'kinput'}
                                    style={{width:'100%'}}
                                    format={'DD/MM/YYYY'}
                                />
                            </Form.Item>
                            <Form.Item name="warehouse" label="Almacén">
                                <KSelect 
                                    options={warehouseResponse?.data?.results?.map(i => {
                                        return {value: i.id, label: i.name}
                                    })}
                                    onChange={(value)=>{
                                        setWarehouseSelected(value)
                                    }}
                                    allowClear
                                />
                            </Form.Item>
                            {
                                warehouseSelected && (
                                    <Form.Item name="warehouse_section" label="Sección de almacén">
                                        <KSelect 
                                            options={warehouseSectionResponse?.data?.results?.filter(section => section.warehouse === warehouseSelected)?.map(section => {
                                                return {value: section.id, label: section.name}
                                            })}
                                            allowClear
                                        />
                                    </Form.Item>
                                )
                            }
                            <Divider>Entrega de parte</Divider>
                            {
                                editObj?.delivered_to && (
                                    <Form.Item name="delivery_canceled" valuePropName="checked">
                                        <Checkbox>Cancelar entrega</Checkbox>
                                    </Form.Item>
                                )
                            }
                            {
                                !editObj?.delivery_canceled || !editObj?.delivered_to ? (
                                    <>
                                    <Form.Item name={'delivery_real_date'} label="Fecha real de entrega">
                                            <DatePicker
                                                locale={locale}
                                                className={'kinput'}
                                                style={{width:'100%'}}
                                                format={'DD/MM/YYYY'}
                                            />
                                        </Form.Item>
                                        <SelectBudgetReceivers extra={'Para marcar la pieza como entregada tendrá que elegir un usuario de esta lista.'} allowClear={true} name={'delivered_to'} label="Entregado a" />
                                    </>
                                ) : (
                                    <Form.Item name="delivery_canceled_reason" label="Razón de cancelación de entrega">
                                        <TextArea rows={4} />
                                    </Form.Item>
                                )
                            }

                            {
                                editObj?.delivery_canceled && !editObj?.delivered_to && (
                                    <Form.Item name="delivery_canceled_reason" label="Se ha cancelado anteriormente por:">
                                        <TextArea rows={4} disabled />
                                    </Form.Item>
                                )
                            }


                            <Form.Item style={{marginTop:20}}>
                                <KButton loading={loading} text={'Cancelar'} onClick={()=>setShowModal(false)} type={'secondary'} />
                                <KButton loading={loading} text={'Guardar'} htmlType={'submit'} type={'Primary'} />
                            </Form.Item>

                        </Form>

                    </Col>
                </Row>

            </KModal>
            <KModal title={`${selectedBudgetItem?.concept}`} onCancel={()=>setShowLogModal(false)} visible={showLogModal}>
                <Row>
                    <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <h3>Historial de Cambios</h3>
                            <Spin spinning={loading}>
                            {!loading && budgetItemLog.length == 0 && <>Ninguno</>}
                            {!loading && budgetItemLog?.map && budgetItemLog.map((log, index) => (
                                <Row key={index}>
                                    <Col span={24}>
                                        <p><strong>Fecha:</strong> {moment(log.timestamp).format('DD/MM/YYYY HH:mm:ss')}</p>
                                        <p><strong>Descripción:</strong> Cambio de valor de <b>{log.field_name}</b>: <i>{log.new_value}</i></p>
                                        <Divider />
                                    </Col>
                                </Row>
                            ))}
                            </Spin>
                        </Col>
                    </Row>
                    </Col>
                </Row>

            </KModal>
        </MainLayoutv2>
    </>
}


const mapStateToProps = state => ({
    settings: state.settings,
    serviceOrder: state.serviceOrder
})


export default injectIntl(connect(mapStateToProps,{getServiceDetail})(WorkshopPartsPage));
