import axios from 'axios';
import cookie from "js-cookie";

/* export const domainApi = "kk.hiumanlab.com"; */
export const domainApi = process.env
  ? process.env.REACT_APP_DOMAIN_BASE
  : "kk.hiumanlab.com";
  /* ? process.env.DOMAIN_BASE
  : "kk.hiumanlab.com"; */

let defaultTenant = "kikert";
if (typeof window !== "undefined") {
  let splitDomain = window.location.hostname.split(".");
  if (splitDomain.length > 0) {
    defaultTenant = splitDomain[0];
  }
}


const tenant = defaultTenant || process.env.REACT_APP_DEFAULT_TENANT

export const typeHttp = process.env.REACT_APP_HTTPS === "1"
  ? "https"
  : "http";

const domainApiWithTenant = `${tenant}.${domainApi}`;
/* const domainApiWithTenant = `demo.${domainApi}`; */

export const  urlfull = `${typeHttp}://${domainApiWithTenant}`
//console.log('urlfull',urlfull)
export const config = {
    baseURL: `${urlfull}`,
    headers: {'Content-Type': 'application/json'},
};

export const axiosApi = axios.create(config);

axiosApi.interceptors.request.use (
    async function (config) {
        let token = cookie.get('token') ? cookie.get('token') : '';
        let settings = localStorage.getItem('settings_kk')

        if(settings){
            try{
                settings = JSON.parse(settings)
                if(settings.companySelected){
                    config.headers['company'] = settings.companySelected.id
                }
                if(settings.branchSelected){
                    config.headers['branch'] = settings.branchSelected.id
                }
            }catch (e){
                console.log('Error en interceptor axios', e)
            }
        }

        if(token) config.headers.Authorization = `JWT ${token}`;

        return config;
    },
    function (error) {
        return Promise.reject (error);
    }
);

// Add a response interceptor
axiosApi.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  //console.log('Aqui interceptando el error', error, error?.response?.data)
      if(/*error.response?.status === 403 || */error?.response?.data?.message === '401 Unauthorized'){
        window.location.href = "/security/login";
      }
  return Promise.reject(error);
});

export const resetConfig = async () => {
};

export const setConfigAxios = async (token) => {
};

export const tenantSelected = defaultTenant;

export const API = {
  SECURITY: {
    LOGIN: "/security/api/token/",
    PING: "/security/api/ping/",
    USER_PROFILE: "/security/user/",
    GENERAL_CONFIG: "/setup/general-config/",
    GENERAL_CONFIG_META: "/setup/general-config-meta/",
    TEAMS: "/security/team/",
  },
  BUSINESS: {
    BUSINESS_SELECT_COMPANY: "/business/select-company/",
    BUSINESS_SELECT_BRANCH: "/business/select-branch/",
    BUSINESS_CUSTOMER: "/business/customer/",
    BRANCH: "/business/branch/",
    COMPANY: "/business/company/",
    LICENSE_STATUS: "/business/company/{companyId}/license-status/",
    CUSTOMER: "/business/customer/",
    UNITS: "/inventory/vehicle/",
    MEMBERSHIP: "/collection/payment-order/",
    SUPPLIERS: "/business/supplier/",
    CUSTOM_FILTER:"/business/custom-filter/",
    SUPPLIER_CATEGORY:  "/business/supplier-category/",
    TENANT_STATUS:"/tenant/schema/cloud-storage-status/{tenant_name}/",
    PROMOS_LIST:"/promos/promotion/"
  },
  WORKSHOP: {
    WORKSHOP_CARBRAND: "/workshop/vehiclebrand/",
    WORKSHOP_CARMODEL: "/workshop/vehiclemodel/",
    WORKSHOP_CARCOLOR: "/workshop/vehiclecolor/",
    WORKSHOP_INSURANCE: "/workshop/insurance/",
    WORKSHOP_CAR: "/workshop/vehicle/",
    WORKSHOP_INSURANCE_AGENTS: "/workshop/insurance-agent/",
    WORKSHOP_ORDER: "/workshop/workshop-order/",
    WORKSHOP_GIKSA_ORDER: "/workshop/workshop-order-giksa/",
    WORKSHOP_ORDER_DETAIL: "/service/order/",
    WORKSHOP_ORDER_DATA_INVOICE: "/service/order/{orderId}/set_invoice_data/",
    WORKSHOP_CAR_TYPES: "/inventory/vehicle-type/",
    WORKSHOP_VALUATION: "/workshop/valuation/",
    WORKSHOP_VALUATION_BUDGET_MASSIVE: "/workshop/valuation/budget-massive-status-update/",
    WORKSHOP_VALUATION_DELETE_MASSIVE: "/workshop/valuation/budget-massive-deletion/",
    WORKSHOP_JOB_TYPE: "/workshop/job-type/",
    WORKSHOP_BUDGET: "/workshop/budget/",
    WORKSHOP_BULK_BUDGET: "/workshop/bulk-budget/",
    WORKSHOP_BUDGET_LOG: "/workshop/budget/{budget_item_id}/change_log/",
    WORKSHOP_ORDER_LIST: "/service/order/?order_type__code=WORKSHOP",
    WORKSHOP_ORDER_REASONS: "/service/order-closing-reason/",
    WORKSHOP_CLOSE_ORDER: "/service/order/{orderId}/close/",
    WORKSHOP_STEPS:"/workflow/workflow-step/?workflow__order_type__code=WORKSHOP",
    WORKSHOP_REOPEN_ORDER: "/service/order/{orderId}/reopen/",
    WORKSHOP_SET_DELIVERY_DATE:
      "/service/order/{orderId}/set_delivery_promise_date/",
    WORKSHOP_GET_ENTRY_CONFIG: "workshop/entry/configuration/order/{orderId}/",
    WORKSHOP_GET_PARTS: "spareparts/sparepart/",
    WORKSHOP_GET_PARTS_BY_MODEL: "spareparts/sparepart/jac-spareparts/?model_id={id}",
    WORKSHOP_GET_WAREHOUSES: "spareparts/warehouse/",
    WORKSHOP_GET_WAREHOUSE_SECTIONS: "spareparts/warehouse-section/",
    WORKSHOP_SET_ENTRY: "/workshop/workshop-entry/",
    WORKSHOP_FINISH_REPAIR: "/service/order/{orderId}/close_repair/",
    WORKSHOP_ASSIGNED: "/service/order/{orderId}/change_assigned_to/",
    WORKSHOP_GLOBAL_PARTS: "/workshop/budget/global_list/?job_type__code=PARTS",
    WORKSHOP_PDF_AUDA: "/workshop/pdf-auda/",
    WORKSHOP_IMPORT_CSV_VALUATION: "/workshop/upload-valuation-csv/",
    WORKSHOP_JAC_DASHBOARD: "/workshop/jac-dashboard/",
    WORKSHOP_METAS: "/workshop/goal/",
    WORKSHOP_ORDER_JAC: "/workshop/workshop-order-jac/",
    WORKSHOP_WAREHOUSE: "/spareparts/warehouse/",
    WORKSHOP_WAREHOUSE_SECTION: "/spareparts/warehouse-section/",
  },
  SALE: {
    SALE_ORDER: "/sales/sales-order/",
    FINANCIAL_PLAN: "/sales/financial-plan/",
    CAR_AVAILABILITY: "/sales/car-availability/",
  },
  SURVEYS: {
    GET_SURVEYS: "/surveys/survey/",
    SURVEY_DETAIL: "/surveys/survey/{id}/structure/",
    SURVEY_SUBMIT: "/surveys/survey-response/"
  },
  SERVICE: {
    SERVICE_ORDER_FILE: "/service/order-file",
    SERVICE_ORDER_MESSAGES: "/service/order-comment/",
    SERVICE_ORDER_REASONS: '/service/order-open-reason/?order_type__code=WORKSHOP'
  },
  WORKFLOW: {
    WORKFLOW: "/workflow/workflow/",
    LOG: "/workflow/workflow-step-log/",
    CHANGE_VISIBLE: "/workflow/workflow-step-log/",
    USERS_WORKFLOW: "/security/user/workshop_users/",
    STEP_LOG: "/workflow/workflow-step-log/",
  },
  CHAT: {
    MESSAGE: "https://us-central1-app-kikert.cloudfunctions.net/app/message",
  },
  USER: {
    USER_PROFILE: "/security/user-profile",
    USERS_LIST: "/security/user/",
    USERS_LIST_BRANCH: "/security/user/?userprofile__branches={branchID}",
    USERS_ADVISORS: "/security/user/advisor_list/",
    USERS_BUDGET_RECEVERS: "/security/user/budget_receivers_list/"
  },
  GROUPS: {
    PROFILES: "/security/group/",
  },
  DASHBOARD:{
    GET_WIDGET:"/service/order-home-dashboard/?order_type=WORKSHOP"
  },
  ANALYTIC:{
    GET_WIDGET:"/service/order-analytics-dashboard/?order_type=WORKSHOP"
  },
  INVENTORY:{
    ITEMS:"/inventory/item"
  },
  DRIVE:{
    LIST_FILE:"/drive/element/",
    CREATE_FILE:"/drive/element/"
  }
};

export default axiosApi;
