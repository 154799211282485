import React, { useState, useEffect } from 'react'
import {Typography, Card, Row, Col, Form, DatePicker, Select, Spin, Image, Progress} from 'antd';
import {
    CheckSquareOutlined,
    FilterOutlined,
    PercentageOutlined,
    DollarCircleOutlined,
    DashboardOutlined,
    SyncOutlined
} from '@ant-design/icons';
import moment from 'moment'
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from 'react-redux'
import { changeLangAction } from '../../redux/settingsDuck';
import dash from "../../image/dashboard_pro.png"
import locale from '../../locale/es_mx.json'
import CardStatistics from "../../components/CardStatistics/CardStatistics";
import Chart from "react-apexcharts";
import {
    getWidgetAnalytic
} from "../../redux/dashboardDuck";
import KButton from "../../components/common/elements/KButton/KButton";
import _ from 'lodash'
import axiosApi, { tenantSelected } from "../../lib/utils/axiosApi";
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import JACTypeCharts from "./components/JACTypeCharts";

const { RangePicker } = DatePicker;
const { Title } = Typography;
moment().locale('es');
const DashboardKPIPage = ({changeLangAction, intl, settingsStore, getDeliveryToday,
                          getWidgetAnalytic, companySelected, ...props}) => {


    const [maxInflux, setMaxInflux] = useState(null) // Día con mayor afluencia
    const [sucursales, setSucursales] = useState([]) // Talleres
    const [numOrder, setNumOrder] = useState(0) //
    const [fulfillmentPromise, setFulfillmentPromise] = useState(0) //
    const [fulfillmentPromiseMonth, setFulfillmentPromiseMonth] = useState([]) //
    const [avgDays, setAvgDays] = useState(0) //
    const [avgTicket, setAvgTicket] = useState(0) //
    const [ordersByMonth, setOrdersByMonth] = useState(null) //
    const [ordersGlobalMonth, setOrdersGlobalMonth] = useState([]) //
    const [avgStayMonth, setAVGStayMonth] = useState([]) //
    const [avgTicketMonth, setAVGTicketMonth] = useState([]) //
    const [ordersByStatus, setOrdersByStatus] = useState([]) //
    const [ordersByWeek, setOrdersByWeek] = useState([]) //
    const [ordersByProcess, setOrdersByProcess] = useState([]) //
    const [ordersByBranch, setOrderByBranch] = useState([]) //
    const [closeOrdersByMonth, setCloseOrdersByMonth] = useState([]) //
    const [loading, setLoading] = useState(false)
    const [loadingFirstSection, setLoadingFirstSection] = useState(false)
    const [loadingOrdersByProcess, setLoadingOrdersByProcess] = useState(false)
    const [loadingordersGlobalMonth, setLoadingordersGlobalMonth] = useState(false)
    const [loadingKPIPainted, setLoadingKPIPainted] = useState(false)
    const [ordersPainted, setOrdersPainted] = useState([])
    const [totalUSPainted, setTotalUSPainted] = useState(0)
    const [filtersCharts, setFiltersCharts] = useState('')
    
    const colors=['#4021d9','#a945ec','#8556f5','#9c68ff']
    const colorsPositive=['#127f0a','#359f2a','#59bf4a','#7ddf69','#a1ff89']
    const colors1 = [
        '#341EFF', // Azul brillante
        '#090731', // Azul muy oscuro
        '#C667DC', // Púrpura
        '#FF4500', // Rojo-naranja
        '#00FFFF', // Cian
        '#FFD700', // Oro
        '#32CD32', // Lima
        '#FF1493', // Rosa profundo
        '#8A2BE2', // Azul violeta
        '#FF8C00', // Naranja oscuro
        '#1E90FF', // Azul dodger
        '#FF69B4', // Rosa fuerte
        '#00FF7F', // Verde primavera
        '#FF00FF', // Magenta
        '#7CFC00', // Verde césped
        '#DC143C', // Carmesí
        '#00BFFF', // Azul cielo profundo
        '#FF7F50', // Coral
        '#6A5ACD', // Azul pizarra
        '#FFA500', // Naranja
        '#20B2AA', // Verde mar claro
        '#9370DB', // Púrpura medio
        '#F08080', // Coral claro
        '#4169E1', // Azul real
        '#FA8072', // Salmón
        '#DDA0DD', // Ciruela
        '#FF6347', // Tomate
        '#48D1CC', // Turquesa medio
        '#C71585', // Violeta medio
        '#00FA9A', // Verde primavera medio
        '#D2691E', // Chocolate
        '#87CEEB', // Azul cielo
        '#CD5C5C', // Rojo indio
        '#4682B4', // Azul acero
        '#DAA520', // Vara de oro
        '#9932CC', // Orquídea oscuro
        '#FFA07A', // Salmón claro
        '#3CB371', // Verde mar medio
        '#BA55D3', // Orquídea medio
        '#F4A460'  // Marrón arena
    ];
    const { Meta } = Card;
    const { Title, Text } = Typography;
    const [form] = Form.useForm()

    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: 'Selecciona un rango de fecha',
            },
        ],
    };


    const getSucursales=async(idCompany)=>{
        try{
            const res = await axiosApi.get(`/business/select-branch/?company__id=${idCompany}`)
            console.log('idCompany',res?.data?.results)
            let items = []
            if(res?.data?.results){
                res?.data?.results.map((s)=>{
                    items.push({
                        label: s.name,
                        value: s.id,
                    });
                })
                setSucursales(items)
            }else{
                setSucursales([])
            }



        }catch (e){

        }finally {

        }
    }

    useEffect(()=>{
        if(companySelected){
            getSucursales(companySelected.id)
        }
    },[companySelected])




    const getInitialWidgets=async(filters)=>{

        const promesas = [
            getOrderGlobalDashboard(filters, 'NUM_ORDERS'),
            getOrderGlobalDashboard(filters,'FULFILLMENT_PROMISE_DATE'),
            getOrderGlobalDashboard(filters,'AVG_STAY_DAYS'),
            getOrderGlobalDashboard(filters,'AVG_TICKET')
        ];

        setLoadingFirstSection(true)
        Promise.all(promesas).then(
            (values) => {
                setLoadingFirstSection(false)
                // arreglo de 4  respuestas
              setNumOrder(values[0]?.data?.count ? values[0]?.data?.count : 0)
              setFulfillmentPromise(values[1]?.data?.count ? values[1]?.data?.count : 0)
              setAvgDays(values[2]?.data?.count ? values[2]?.data?.count : 0)
              setAvgTicket(values[3]?.data?.count ? values[3]?.data?.count : 0)
            },
            (reason) => {
                setLoadingFirstSection(false)
            }
        );

    }

    const [loadingOrdersByMonth, setLoadingOrdersByMonth] = useState(false)
    const [loadingCloseOrdersByMonth, setLoadingCloseOrdersByMonth] = useState(false)
    const [loadingAVGStayMonth, setLoadingAVGStayMonth] = useState(false)
    const [loadingAVGTicketMonth, setLoadingAVGTicketMonth] = useState(false)
    const [loadingFulfillmentPromiseMonth, setLoadingFulfillmentPromiseMonth] = useState(false)
    const [loadingOrderByBranch, setLoadingOrderByBranch] = useState(false)
    const [loadingOrdersByStatus, setLoadingOrdersByStatus] = useState(false)
    const [loadingOrdersByWeek, setLoadingOrdersByWeek] = useState(false)
    

    const onFinish=async (values)=>{
        console.log(values)
        let params = {
            from_date:null,
            to_date:null
        }
        if(values.dates){
            params.from_date = values.dates[0].format('YYYY-MM-DD').toString()
            params.to_date = values.dates[1].format('YYYY-MM-DD').toString()
        }

        if(values.branch){
            params.branches_id = values.branch
        }

        let filters =Object.keys(params).map(key => key + '=' + params[key]).join('&');
         
        

        getInitialWidgets(filters)
        setFiltersCharts(filters)


        setLoadingordersGlobalMonth(true)
        getOrderGlobalDashboard(filters,'GLOBAL_ORDERS_BY_MONTH_BY_STATUS').then((re)=>{
            setOrdersGlobalMonth(re.data)
        }).catch(()=>{}).finally(()=>{setLoadingordersGlobalMonth(false)})

        if(tenantSelected === 'jacmexico'){
            setLoadingKPIPainted(true)
            getOrderGlobalDashboard(filters,'JAC_PAINTED_PARTS').then((res)=>{
                setOrdersPainted(res.data?.data)
            }).catch(()=>{}).finally(()=>{setLoadingKPIPainted(false)})
        }
       

        setLoadingOrdersByMonth(true)
        getOrderGlobalDashboard(filters,'ORDERS_BY_MONTH').then((re)=>{
            setOrdersByMonth(re.data)
        }).catch(()=>{}).finally(()=>{setLoadingOrdersByMonth(false)})


        setLoadingCloseOrdersByMonth(true)
        getOrderGlobalDashboard(filters,'CLOSED_ORDERS_BY_MONTH').then((re)=>{
            setCloseOrdersByMonth(re.data)
        }).catch(()=>{}).finally(()=>{setLoadingCloseOrdersByMonth(false)})


        setLoadingAVGStayMonth(true)
        getOrderGlobalDashboard(filters,'AVG_STAY_DAYS_BY_MONTH').then((re)=>{
            setAVGStayMonth(re.data)
        }).catch(()=>{}).finally(()=>{setLoadingAVGStayMonth(false)})


        setLoadingAVGTicketMonth(true)
        getOrderGlobalDashboard(filters,'AVG_TICKET_BY_MONTH').then((re)=>{
            setAVGTicketMonth(re.data)
        }).catch(()=>{}).finally(()=>{setLoadingAVGTicketMonth(false)})

        setLoadingFulfillmentPromiseMonth(true)
        getOrderGlobalDashboard(filters,'FULFILLMENT_PROMISE_DATE_BY_MONTH').then((re)=>{
            setFulfillmentPromiseMonth(re?.data)
        }).catch(()=>{}).finally(()=>{setLoadingFulfillmentPromiseMonth(false)})

        setLoadingOrderByBranch(true)
        getOrderGlobalDashboard(filters,'NUM_ORDERS_BY_BRANCH').then((re)=>{
            setOrderByBranch(re?.data)
        }).catch(()=>{}).finally(()=>{setLoadingOrderByBranch(false)})

    
        setLoadingOrdersByStatus(true)
        getOrderGlobalDashboard(filters,'NUM_ORDERS_BY_STATUS').then((re)=>{
            setOrdersByStatus(re.data)
        }).catch(()=>{}).finally(()=>{setLoadingOrdersByStatus(false)})


        setLoadingOrdersByWeek(true)
        getOrderGlobalDashboard(filters,'ORDERS_BY_WEEKDAY').then((re)=>{
            setOrdersByWeek(re.data)
        }).catch(()=>{}).finally(()=>{setLoadingOrdersByWeek(false)})


        setLoadingOrdersByProcess(true)
        getOrderGlobalDashboard(filters,'ORDERS_BY_REPAIR_PROCESS').then((re)=>{
            setOrdersByProcess(re.data)
        }).catch(()=>{}).finally(()=>{setLoadingOrdersByProcess(false)})

    }

    const getOrderGlobalDashboard=async (filters,codeWidget='NUM_ORDERS')=>{
        const url = 'service/order-global-dashboard/?order_type=WORKSHOP';
        try{
           const res = await axiosApi.get(url+`&widget_code=${codeWidget}&${filters}`);
           return res;
        }catch (e){
           return e;
        }

    }



    /** Obtenemos el primer di ay ultimo del mes**/
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), 0, 1);
    const lastDay = new Date(now.getFullYear(), 11, 31);



    const datwrangeInitial={
        dates:[
            moment(firstDay.toISOString()),
            moment(lastDay.toISOString())
        ]
    }


    useEffect(()=>{
        form.submit()

    },[])

    return (
        <MainLayoutv2 title={<>{'Analítica'} {' '}
            <a href="/dashboard-kpi"><DashboardOutlined /></a>
        </>} index="analitica">

            <Row style={{marginBottom:50}}>
                <Col>
                    <Form
                        initialValues={datwrangeInitial}
                        layout="inline" form={form} onFinish={onFinish}>
                        <Form.Item name="dates" {...rangeConfig}>
                            <RangePicker locale={locale}
                                         ranges={{
                                             'Este mes': [moment().startOf('month'), moment().endOf('month')],
                                             'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                             'Este año': [moment().startOf('year'), moment().endOf('year')],
                                             'Año pasado': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                                         }}
                                         //picker="month"
                                         //format="YYYY-MM-DD"
                            />
                        </Form.Item>
                        <Form.Item name={'branch'}>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '300px' }}
                                placeholder="Elige una sucursal"
                                onChange={(e)=>console.log(e)}
                                options={sucursales}
                            />
                        </Form.Item>
                        <Form.Item>
                            <KButton icon={loading?<SyncOutlined spin />:<FilterOutlined />} disabled={loading} type={'secondary'} htmlType={'submit'} size={'default'} />

                        </Form.Item>

                    </Form>
                </Col>
            </Row>

            <Row gutter={16}>

                    <Col xs={24} lg={6} md={12}>
                        <Spin spinning={loadingFirstSection}>
                             <CardStatistics
                            fontSize={80}
                            icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:25,marginTop:30}}  />}
                            title={'Número de órdenes'}
                            value={numOrder}/>
                        </Spin>
                       
                    </Col>
                <Col xs={24} lg={6} md={12}>
                    <Spin spinning={loadingFirstSection}>
                    <CardStatistics
                        fontSize={80}
                        iconRight={<PercentageOutlined className={'text-primary'} style={{fontSize:25,marginTop:30}}  />}
                        title={'Cumplimiento fecha promesa'}
                        value={fulfillmentPromise}/>
                    </Spin>
                </Col>
                <Col xs={24} lg={6} md={12}>
                    <Spin spinning={loadingFirstSection}>
                        <CardStatistics
                            fontSize={80}
                            icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:25,marginTop:30}}  />}
                            title={'Días promedio estadía de unidades'}
                            value={avgDays}/>
                        </Spin>
                </Col>
                <Col xs={24} lg={6} md={12}>
                <Spin spinning={loadingFirstSection}>
                    <CardStatistics
                        icon={<DollarCircleOutlined  className={'text-primary'} style={{fontSize:25,marginTop:35}}  />}
                        title={'Ticket promedio por unidad'}
                        fontSize={80}
                        value={avgTicket}/>
                        </Spin>
                        
                </Col>

            </Row>
            <Row gutter={16}>
               <Col span={24} style={{marginBottom:20}}>
                        <br/>
                        <Spin spinning={loadingordersGlobalMonth}>
                        <CardStatistics
                            type={'chart'}
                            icon={<PercentageOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                            title={'Órdenes por mes'}
                            body={
                                <Chart
                                    options={{
                                        chart: {
                                            id: "ordenes-mes",
                                            type: 'line',
                                            dropShadow: {
                                                enabled: true,
                                                color: '#000',
                                                top: 18,
                                                left: 7,
                                                blur: 10,
                                                opacity: 0.2
                                            },
                                            toolbar: {
                                                show: true
                                            }
                                        },
                                        colors:colors1,
                                        xaxis: {
                                            categories: ordersGlobalMonth?.categories
                                        },
                                        dataLabels: {
                                            enabled: true,
                                        },
                                        stroke: {
                                            curve: 'smooth'
                                        }
                                    }}
                                    series={ordersGlobalMonth?.data ? ordersGlobalMonth?.data : [] }
                                    type="line"
                                    width="100%"
                                    height={'300'}
                                />
                            }/>
                         </Spin>
                    </Col>


            </Row>
            {
                tenantSelected === 'jacmexico' && (
                    <Row>
                    <Col span={24} style={{marginBottom:20}}>
                        <br/>
                        <Spin spinning={loadingKPIPainted}>
                        <CardStatistics
                            type={'chart'}
                            icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                            title={'Piezas pintadas'}
                            body={
                                <div style={{marginTop:20}}>
    
                                
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
    
                                        { ordersPainted?.total_painted_services && <Chart
                                            options={
                                                {
                                                series: [ordersPainted?.total_painted_services?.count],
                                                chart: {
                                                    id:'totalpintado',
                                                height: 350,
                                                type: 'radialBar',
                                                },
                                                title: {
                                                    text: `U's pintura`,
                                                    align: 'center'
                                                  },
                                                fill: {
                                                    type: 'gradient',
                                                    gradient: {
                                                      shade: 'dark',
                                                      type: 'horizontal',
                                                      shadeIntensity: 0.5,
                                                      gradientToColors: ['#ABE5A1'],
                                                      inverseColors: true,
                                                      opacityFrom: 1,
                                                      opacityTo: 1,
                                                      stops: [0, 100]
                                                    }
                                                  },
                                              plotOptions: {
                                                radialBar: {
                                                  hollow: {
                                                    size: '60%',
                                                  }
                                                },
                                              },
                                              labels:[`${ordersPainted?.total_painted_services?.count} U's` ],
                                              }}
                                            series={[ordersPainted?.total_painted_services?.percent*100]}
                                            type="radialBar"
                                            width="100%"
                                            height={'200'}
                                        />}
    
                                        { ordersPainted?.one_parts && <Chart
                                            options={
                                                {
                                                series: [ordersPainted?.one_parts?.count],
                                                chart: {
                                                    id:'totalpintado',
                                                height: 350,
                                                type: 'radialBar',
                                                },
                                                title: {
                                                    text: `1 pieza`,
                                                    align: 'center'
                                                  },
                                                fill: {
                                                    type: 'gradient',
                                                    gradient: {
                                                      shade: 'dark',
                                                      type: 'horizontal',
                                                      shadeIntensity: 0.5,
                                                      gradientToColors: ['#1d33ac'],
                                                      inverseColors: true,
                                                      opacityFrom: 1,
                                                      opacityTo: 1,
                                                      stops: [0, 100]
                                                    }
                                                  },
                                              plotOptions: {
                                                radialBar: {
                                                  hollow: {
                                                    size: '60%',
                                                  }
                                                },
                                              },
                                              labels:[`${ordersPainted?.one_parts?.count} U's`],
                                              }}
                                            series={[ordersPainted?.one_parts?.percent*100]}
                                            type="radialBar"
                                            width="100%"
                                            height={'200'}
                                            />}
    
    
                                            { ordersPainted?.two_parts && <Chart
                                            options={
                                                {
                                                series: [ordersPainted?.two_parts?.count],
                                                chart: {
                                                    id:'totalpintado',
                                                height: 350,
                                                type: 'radialBar',
                                                },
                                                title: {
                                                    text: `2 piezas`,
                                                    align: 'center'
                                                  },
                                                fill: {
                                                    type: 'gradient',
                                                    gradient: {
                                                      shade: 'dark',
                                                      type: 'horizontal',
                                                      shadeIntensity: 0.5,
                                                      gradientToColors: ['#1d33ac'],
                                                      inverseColors: true,
                                                      opacityFrom: 1,
                                                      opacityTo: 1,
                                                      stops: [0, 100]
                                                    }
                                                  },
                                              plotOptions: {
                                                radialBar: {
                                                  hollow: {
                                                    size: '60%',
                                                  }
                                                },
                                              },
                                              labels:[`${ordersPainted?.two_parts?.count} U's`],
                                              }}
                                            series={[ordersPainted?.two_parts?.percent*100]}
                                            type="radialBar"
                                            width="100%"
                                            height={'200'}
                                            />}
    
    
    
                                        { ordersPainted?.three_parts && <Chart
                                            options={
                                                {
                                                series: [ordersPainted?.three_parts?.count],
                                                chart: {
                                                    id:'totalpintado',
                                                height: 350,
                                                type: 'radialBar',
                                                },
                                                title: {
                                                    text: `3 piezas`,
                                                    align: 'center'
                                                  },
                                                fill: {
                                                    type: 'gradient',
                                                    gradient: {
                                                      shade: 'dark',
                                                      type: 'horizontal',
                                                      shadeIntensity: 0.5,
                                                      gradientToColors: ['#1d33ac'],
                                                      inverseColors: true,
                                                      opacityFrom: 1,
                                                      opacityTo: 1,
                                                      stops: [0, 100]
                                                    }
                                                  },
                                              plotOptions: {
                                                radialBar: {
                                                  hollow: {
                                                    size: '60%',
                                                  }
                                                },
                                              },
                                              labels:[`${ordersPainted?.three_parts?.count} U's`],
                                              }}
                                            series={[ordersPainted?.three_parts?.percent*100]}
                                            type="radialBar"
                                            width="100%"
                                            height={'200'}
                                            />}
    
                                
    
                                   
                                     
                                </div>
    
                                {
                                    ordersPainted?.more_three_parts && (
                                        <Row align='center' style={{marginTop:20}}>
                                        <Col span={10}>
                                        <span style={{fontSize:16}}>
                                           +3 piezas  ({ordersPainted?.more_three_parts?.count} U's) 
                                        </span>
                                        <Progress strokeLinecap="butt" percent={ordersPainted?.more_three_parts?.percent*100} />
                                        </Col>
                                        </Row>
                                    )
                                }
    
                                </div>
                                
                            }/>
                            </Spin>
                    </Col>
                </Row>
                )
            }

            {
                tenantSelected === 'jacmexico' && (
                    <JACTypeCharts  colors1={colors1} filters={filtersCharts} />
                )
            }

            <Row gutter={16}>
                <Col md={12} xs={24} style={{marginBottom:20}}>
                    <br/>
                    <Spin spinning={loadingOrdersByMonth}>
                    <CardStatistics
                        type={'chart'}
                        icon={<PercentageOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Entradas por mes'}
                        body={
                            <Chart
                                options={{
                                    chart: {
                                        id: "entradas-mes",
                                        type: 'area',
                                        dropShadow: {
                                            enabled: true,
                                            color: '#000',
                                            top: 18,
                                            left: 7,
                                            blur: 10,
                                            opacity: 0.2
                                        },
                                        toolbar: {
                                            show: true
                                        }
                                    },
                                    colors,
                                    xaxis: {
                                        categories: ordersByMonth?.categories
                                    },
                                    dataLabels: {
                                        enabled: true,
                                    },
                                    stroke: {
                                        curve: 'smooth'
                                    }
                                }}
                                series={ordersByMonth?.data ? ordersByMonth?.data : [] }
                                type="line"
                                width="100%"
                                height={'300'}
                            />
                        }/>
                        </Spin>
                </Col>
                <Col md={12} xs={24} style={{marginBottom:20}}>
                    <br/>
                    <Spin spinning={loadingCloseOrdersByMonth}>
                    <CardStatistics
                        type={'chart'}
                        icon={<PercentageOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Salidas por mes'}
                        body={
                            <Chart
                                options={{
                                    chart: {
                                        id: "salidas-mes",
                                        type: 'line',
                                        dropShadow: {
                                            enabled: true,
                                            color: '#000',
                                            top: 18,
                                            left: 7,
                                            blur: 10,
                                            opacity: 0.2
                                        },
                                        toolbar: {
                                            show: true
                                        }
                                    },
                                    colors: colorsPositive,
                                    xaxis: {
                                        categories: closeOrdersByMonth?.categories
                                    },
                                    dataLabels: {
                                        enabled: true,
                                    },
                                    stroke: {
                                        curve: 'smooth'
                                    }
                                }}
                                series={closeOrdersByMonth?.data ? closeOrdersByMonth?.data : [] }
                                type="line"
                                width="100%"
                                height={'300'}
                            />
                        }/>
                        </Spin>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} style={{marginBottom:20}}>
                    <br/>
                    <Spin spinning={loadingAVGStayMonth}>
                    <CardStatistics
                        type={'chart'}
                        icon={<PercentageOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Tiempo promedio de estadía de unidades en días'}
                        body={
                            <Chart
                                options={{
                                    chart: {
                                        id: "tiempo-promedio",
                                        type: 'line',
                                        dropShadow: {
                                            enabled: true,
                                            color: '#000',
                                            top: 18,
                                            left: 7,
                                            blur: 10,
                                            opacity: 0.2
                                        },
                                        toolbar: {
                                            show: true
                                        }
                                    },
                                    colors:colors1,
                                    xaxis: {
                                        categories: avgStayMonth?.categories
                                    },
                                    dataLabels: {
                                        enabled: true,
                                    },
                                    stroke: {
                                        curve: 'smooth'
                                    }
                                }}
                                series={avgStayMonth?.data ? avgStayMonth?.data : [] }
                                type="line"
                                width="100%"
                                height={'300'}
                            />
                        }/>
                        </Spin>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={24} style={{marginBottom:20}}>
                    <br/>
                    <Spin spinning={loadingAVGTicketMonth}>
                    <CardStatistics
                        type={'chart'}
                        icon={<PercentageOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Ticket promedio por mes'}
                        body={
                            <Chart
                                options={{
                                    chart: {
                                        id: "ticket-promedio-mes",
                                        type: 'area',
                                        toolbar: {
                                            show: true
                                        }
                                    },
                                    colors:colors1,
                                    xaxis: {
                                        categories: avgTicketMonth?.categories
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        formatter: function (val, opt) {
                                            return "$" +new  Intl.NumberFormat('es-MX', { maximumSignificantDigits: 3 }).format(val)
                                        },
                                    },
                                    stroke: {
                                        curve: 'smooth'
                                    }
                                }}
                                series={avgTicketMonth?.data ? avgTicketMonth?.data : [] }
                                type="area"
                                width="100%"
                                height={'300'}
                            />
                        }/>
                        </Spin>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={24} style={{marginBottom:20}}>
                    <br/>
                    <Spin spinning={loadingFulfillmentPromiseMonth}>
                    <CardStatistics
                        type={'chart'}
                        icon={<PercentageOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Porcentaje de cumplimiento fecha promesa por mes'}
                        body={
                            <Chart
                                options={{
                                    chart: {
                                        id: "porcentaje-cumplimiento-mes",
                                        type: 'bar',
                                        toolbar: {
                                            show: true
                                        }
                                    },
                                    colors,
                                    xaxis: {
                                        categories: fulfillmentPromiseMonth?.categories
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        formatter: function (val, opt) {
                                            return val+"%"
                                        },
                                    },
                                    stroke: {
                                        curve: 'smooth'
                                    }
                                }}
                                series={fulfillmentPromiseMonth?.data ? fulfillmentPromiseMonth?.data : [] }
                                type="bar"
                                width="100%"
                                height={'400'}
                            />
                        }/>
                        </Spin>
                </Col>
            </Row>
            <Row>
                <Col md={8} xs={24} style={{marginBottom:20, paddingLeft:30}}>
                    <br/>
                    <h3>Total órdenes por taller</h3>
                    <br/>
                    <Spin spinning={loadingOrderByBranch}>
                    <Chart
                        options={{
                            chart: {
                                width: 380,
                                type: 'donut',
                                id: "orders-taller",
                                toolbar: {
                                    show: true
                                }
                            },
                            colors:colors1,
                            labels:ordersByBranch?.labels,
                            responsive: [{
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: 200
                                    },
                                    legend: {
                                        position: 'bottom'
                                    }
                                }
                            }]
                        }}
                        series={ordersByBranch?.series ? ordersByBranch?.series : []}
                        type="donut"
                        width="100%"
                        height={'600'}
                    />
                     </Spin>

                </Col>

                <Col md={16} xs={24} style={{marginBottom:20}}>
                    <br/>
                    <Spin spinning={loadingOrdersByStatus}>
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Órdenes por status'}
                        body={
                            <Chart
                                options={{
                                    chart: {
                                        type:'bar',
                                        id: "ordenes-status",
                                        toolbar: {
                                            show: true
                                        }
                                    },
                                    colors,
                                    xaxis: {
                                        categories: ordersByStatus?.categories
                                    },
                                    dataLabels: {
                                        enabled: true,
                                    }
                                }}
                                series={ordersByStatus?.data ? ordersByStatus?.data : [] }
                                type="bar"
                                width="100%"
                                height={'450'}
                            />
                        }/>
                        </Spin>
                </Col>


            </Row>
            <Row>
                <Col span={24} style={{marginBottom:20}}>
                    <br/>
                    <Spin spinning={loadingOrdersByProcess}>

                    
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Unidades por proceso'}
                        body={
                            <Chart
                                options={{
                                    chart: {
                                        type:'bar',
                                        id: "by-process",
                                        toolbar: {
                                            show: true
                                        }
                                    },
                                    colors,
                                    xaxis: {
                                        categories: ordersByProcess?.categories
                                    },
                                    dataLabels: {
                                        enabled: true,
                                    }
                                }}
                                series={ordersByProcess?.data ? ordersByProcess?.data : [] }
                                type="bar"
                                width="100%"
                                height={'500'}
                            />
                        }/>
                        </Spin>
                </Col>
            </Row>

            

            <Row>
                <Col span={24} style={{marginBottom:20}}>
                    <br/>
                    <Spin spinning={loadingOrdersByWeek}>

                    
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{fontSize:40,marginTop:30}}  />}
                        title={'Total de órdenes por día de la semana'}
                        body={
                            <Chart
                                options={{
                                    chart: {
                                        type:'bar',
                                        id: "basic-bar-week",
                                        toolbar: {
                                            show: true
                                        }
                                    },
                                    colors,
                                    xaxis: {
                                        categories: ordersByWeek?.categories
                                    },
                                    dataLabels: {
                                        enabled: true,
                                    }
                                }}
                                series={ordersByWeek?.data ? ordersByWeek?.data : [] }
                                type="bar"
                                width="100%"
                                height={'500'}
                            />
                        }/>
                        </Spin>
                </Col>
            </Row>
        </MainLayoutv2>
    )
}

const mapState = (state) => ({
    settingsStore: state.settings,
    companySelected: state?.settings?.settingsData?.companySelected,
});

export default injectIntl(connect(mapState, {
    changeLangAction,
    getWidgetAnalytic
})(DashboardKPIPage))
