export const esmx = {
    'ok': 'Aceptar',
    'name': "Nombre",
    'last_name': "Apellido",
    'email': "Email",
    'phone': "Telefono",
    'form.is_required' : "Este campo es requerido",
    'form.type_email' : "No es un correo valido",
    'form.confirmPassword' : "¡Las dos contraseñas que ingresó no coinciden!",
    'form.date': "Selecciona una fecha",
    'profile_assigned': "Perfil asignado",
    "actions": "Acciones",
    "update": "Actualizar",
    "save": "Guardar",
    "cover": "Orden de servicio",
    "invoicing":'Facturación',
    'changelang': 'Change to English', //Botón para cambiar de idioma
    'no_results_found': 'No se encontraron resultados',
    //Pantalla de login, Recuperar contraseña y Reset Password
    'security.login.title': 'Ingresa tus credenciales para iniciar sesión',
    'security.login.logoutSuccess': 'Logout exitoso',
    'security.login.logoutErr': 'No se pudo cerrar sesión, intenta nuevamente',
    'security.login.incorrectAcces': 'Accesos incorrectos',
    'security.login.email': 'Correo electrónico',
    'security.login.password': 'Contraseña',
    'security.login.signin': 'Ingresar',
    'security.login.forgot': '¿Olvidaste tu contraseña?',
    'security.login.newaccount': '¿No tienes una cuenta?',
    'security.login.signup': 'Regístrate aquí',
    'security.login.buy': 'CONTRATAR',
    'security.login.accepTerms': 'Estoy de acuerdo con los Términos y condiciones.',
    'security.forgot.instruction': 'Ingrese su dirección de correo electronico y le enviaremos las instrucciones para restablecer una nueva.',
    'security.forgot.title': 'Recuperar contraseña',
    'security.forgot.login': 'Log In',
    'security.forgot.send': 'Enviar',
    'security.forgot.back': 'Regresar',
    'security.login.messageUser': 'Por favor, introduzca su correo electronico.',
    'security.login.messagePass': 'Por favor, introduzca su contraseña.',
    'security.login.click': 'Haz click',
    'security.login.here': 'Aquí.',
    'security.forgot.messageDone': 'Se ha enviado a su correo para la recuperación.',
    'security.forgot.messageDonePassword': 'Su contraseña ha sido cambiada.',
    'security.confirm.title': 'Confirma tu nueva contraseña.',
    'security.profile.title': 'Perfil',
    //Escoger la empresa y la sucursal
    'bussiness.branch.chooseBussines': 'Elige tu empresa.',
    'bussiness.company.chooseTaller': 'Elige tu Sucursal/ taller.',
    //Menu del dashboard que se situa a la izquierda
    'services.dash': 'Tablero',
    'services.dash.goal.title': 'Dashboard de metas',
    'services.analytics': 'Analítica',
    'services.sale': 'Ventas',
    'services.service': 'Servicios',
    'services.service.saleOrders': 'Ventas',
    'services.team': 'Equipo',
    'services.mess': ' Mensajes',
    'services.setts': 'Configuraciones',
    'services.insur': 'Aseguradoras',
    'services.brand': 'Marcas',
    'services.models': 'Modelos',
    'services.metas': 'Metas',
    'services.metas.type': 'Tipo',
    'services.metas.value': 'Valor',
    'services.metas.date': 'Fecha de aplicación',
    'services.metas.edit': 'Editar meta',
    'services.metas.new': 'Nuevo meta',
    'services.metas.upd': 'Actualizar Meta',
    'services.metas.form.type': 'Tipo de meta',
    'services.metas.form.value': 'Valor',
    'services.metas.form.aplication_date': 'Fecha de aplicación',
    'services.members_center': 'Members center',
    'services.members.delete.description': 'Se eliminará el grupo {group}. Esta acción no puede revertirse',
    //Botón para cambiar de empresa o cerrar sesión
    'layout.changeEmpresa': 'Cambiar de empresa',
    'layout.logOut': 'Cerrar Sesión',
    'layout.about': 'Acerca de',
    //Página de la aseguradora, CREAR, EDITAR, ELIMINAR
    'layout.name': 'Nombre',
    'layout.lastname': 'Apellido',
    'layout.phone': 'Telefono',
    'layout.assigned_profile': 'Perfil asignado',
    'layout.actions': 'Acciones',
    'layout.createNew': ' Crear Nuevo',
    'layout.agents': 'Agentes',
    'layout.edit': 'Editar',
    'layout.delete': 'Eliminar',
    'layout.addInsure': 'Agregar Aseguradora',
    'services.insure': 'Aseguradora',
    'layout.new': ' Nuevo',
    'layout.nameAgente': 'Nombre del agente',
    'layout.cancel': 'Cancelar',
    'layout.close': 'Cerrar',
    'layout.print': ' Imprimir',
    'layout.add': 'Añadir',
    'layout.goBack': 'Regresar',
    'layout.name_is_required': 'Por favor, ingresa un nombre',
    'layout.functions': 'Funciones',
    'layout.catalogs': 'Catálogos',
    //Titulo del Dash y el buscador
    'dashboard.myDashboard': 'Mi Tablero de control',
    'dashboard.myAnalytics': 'Mi Analítica',
    'dashboard.search': 'Buscar',
    'dashboard.title': 'Unidades',
    'services.searchtext':'Buscar por folio/nombre cliente/número de orden/ VIN / Placa',
    'servicespart.searchtext':'Buscar por folio/refacción/proveedor',
    //Errores y Succes
    'item.deleteSuccess': 'Se ha eliminado correctamente.',
    'item.saveSuccess': 'Se ha guardado correctamente.',
    'item.editSuccess': 'Se ha editado correctamente',
    'deleted.ask': '¿Seguro de eliminar el registro?',
    'deleted.description':'Se eliminará el registro "{obj}". Esta acción no puede revertirse.',
    'item.issure.agenteErr': 'Hubo un error al agregar el agente, porfavor intenta nuevamente.',
    'item.brand.registerErr': 'Ocurrió un error al eliminar el registro.',
    'item.brand.element': 'Hubo un error al guardar el elemento',
    //Página de la Marca, CREAR, EDITAR Y ELIMINAR
    'item.brand.nameBrand': 'Nombre de la marca',
    'item.brand.editBrand': 'Editar marca',
    'item.brand.addBrand': 'Añadir marca',
    //Página del Modelo,  CREAR, EDITAR Y ELIMINAR
    'item.model.nameModel': 'Nombre del modelo',
    'item.model.editModel': 'Editar modelo',
    'item.model.addModel': 'Añadir modelo',
    'layout.model': 'Modelo',
    'layout.brand': 'Marca',
    //Pagina tabla de services y donde aparece el electroplan
    'services.page.electroplan': 'Electroplan',
    'services.page.reports': ' Reportes',
    'services.page.report': ' Reporte',
    'services.page.jacreport': ' Bitácora de unidades',
    'services.page.analitycs': ' Analítica',
    'services.page.configurations': ' Configuraciones',
    'services.page.apps': ' Aplicaciones',
    'services.page.file': ' Expediente',
    'services.page.advisor': 'Asesor',
    'services.page.technical': 'Técnico',
    'services.page.costumer': 'Cliente',
    'services.page.status': 'Estado',
    'services.page.filter': ' Filtrar',
    'services.page.hide_status': 'Visible para el usuario',
    'services.switch.hide': 'Oculto',
    'services.switch.visible': 'Visible',
    'services.page.err': 'Error al cargar ',
    //Archvios y carpetas de servicios
    'services.files.title': ' Archivos del expediente',
    'services.files.newFolder': ' Nueva carpeta',
    'services.files.EditName': 'Editar nombre',
    'services.files.autogenerated': 'Autogenerado',
    'services.files.files': 'Archivos',
    'services.files.folders': 'Carpetas',
    'services.files.newFile': ' Nuevo archivo',
    'services.files.empty': 'Vacío',
    //Ventada de filtros de servicios
    'services.filter.filter': 'Filtrar resultados',
    'services.filter.date': 'Fecha de alta',
    'services.filter.stage': 'Etapa',
    'services.filter.vehicleLocation': 'Locación del vehículo',
    'services.filter.dateCita': 'Con cita',
    'services.filter.dateInMechanic': 'Fecha de entrada al taller',
    'services.filter.datePromise': 'Fecha promesa',
    'services.filter.invoiced': 'Facturado',
    'services.filter.privateParticular': 'Es particular',
    'services.filter.includeCloseFiles': 'Incluir expedientes cerrados',
    'services.filter.clearFilter': 'Limpiar filtro',
    'services.filter.save': ' Guardar',
    'services.filter.everything': 'Todos',
    //facturacion
    'service.dataInvoice':'Registro de datos de facturación',
    //Ventana crear nuevos servicios
    'services.createNew': 'Servicios - Crear nuevo',
    'services.update': 'Servicios - Actualizar',
    'services.createNew.reception': 'Recepción',
    'services.createNew.serviceOpening': 'Apertura de servicio',
    'services.createNew.dateReceipt': 'Fecha',
    'services.createNew.orderNumber': 'Número de orden',
    'services.createNew.pyramid': 'Pirámide',
    'services.createNew.byIsurer': 'Por aseguradora',
    'services.createNew.privateIndividual': 'Particular',
    'services.createNew.unit': 'Unidad',
    'services.createNew.equipment': 'Equipo',
    'services.createNew.equipmentData': 'Datos del equipo',
    'services.createNew.labelUnit': 'Buscar...',
    'services.createNew.addNew': ' Agregar nuevo',
    'services.createNew.addNewShort': ' Agregar',
    'services.createNew.serialNumber': 'Número de serie',
    'services.createNew.plates': 'Placas',
    'services.createNew.typePetrol': 'Tipo de gasolina',
    'services.createNew.typeVehicle': 'Tipo de unidad',
    'services.createNew.typeEquipment': 'Tipo de equipo',
    'services.createNew.colour': 'Color',
    'services.createNew.searchUnit': ' Buscar unidad',
    'services.createNew.searchEquipment': ' Buscar equipo',
    'services.createNew.client': 'Cliente',
    'services.createNew.labelClient': 'Buscar por nombre o email...',
    'services.createNew.lastNameFather': 'Apellido paterno',
    'services.createNew.lastNameMother': 'Apellido materno',
    'services.createNew.celNumber': 'Telefono/Celular',
    'services.createNew.address': 'Dirección',
    'services.createNew.searchClient': 'Buscar cliente',
    'services.createNew.comments': 'Observaciones',
    'services.createNew.addInformation': 'Descripción del servicio',
    'services.createNew.labelComments': 'Golpes identificados, faltantes en accesorios, entre otros... Cualquier información que pueda servir para el correcto manejo de esta orden de servicio.',
    'services.createNew.siniestroNumber': 'Número de siniestro',
    'services.createNew.adjuster': 'Ajustador',
    'services.createNew.numberGuaranty': 'Número de póliza',
    'services.createNew.dateValidityGuaranty': 'Fecha de vigencia de la póliza',
    'services.createNew.saving': 'Guardando...',
    'services.createNew.saved': '¡Guardado!',
    'services.createNew.err': 'Error al guardar el servicio',
    //Página de Members Center
    'members.title': 'Member Center',
    'members.profile': 'Perfil',
    'members.search_name': 'Buscar por nombre',
    'members.new_profile': 'Nuevo perfil',
    'members.new_user': 'Nuevo usuario',
    'members.delete_user_title': 'Eliminar usuario',
    'members.delete_user_description': 'Se eliminará el usuario "{user}". Esta acción no puede revertirse',
    'members.delete_user_success': 'Usuario eliminado exitosamente.',
    'members.table.profiles': 'Perfiles',
    'members.in_process_repair':'Puede ser asignado a un proceso de reparación',
    'members.can_recepit_unit':'Puede recepcionar unidades',
    'members.can_reopen_order':'Puede reabrir órdenes',
    'members.can_change_promise_date':'Puede cambiar fecha de promesa de entrega',
    'members.receive_promise_date_noti':'Recibir notificación al cambiar fecha promesa de entrega',
    'members.can_show_membership':'Ver membresía',
    'members.table.users': 'Usuarios',
    'members.table.actions': 'Acciones',
    'members.table.showUsers': 'Ver usuarios',
    'members.table.users_plural': 'Usuario(s)',
    'members.table.create_group': 'Crear Grupo',
    'members.table.create_group_label': 'Nombre del grupo',
    'services.members.create.save': 'Guardar',
    //Página de crear ordenes de venta
    'services.saleOrder.title': 'Ventas',
    'services.OrderTypeSale': 'Orden tipo venta',
    'services.saleOrder.createNew': 'Ventas - Crear nuevo',
    'services.saleOrder.typeOfSale': 'Tipo de venta',
    'services.saleOrder.credit': 'Crédito',
    'services.saleOrder.cash': 'Contado',
    'services.saleOrder.year': 'Año',
    'services.saleOrder.carOfInterest': 'Auto de interés',
    'services.saleOrder.validateData': 'Por favor, valide sus datos',
    'services.saleOrder.obligatory': 'El campo es obligatorio',
    'services.saleOrder.typePayment': 'Tipo de pago',
    'services.saleOrder.deleteQuestion': '¿Está seguro de cambiar el estatus de la venta?',
    'services.saleOrder.deleteDescription' : "Este cambio lo podrá visualizar el cliente desde su aplicación.",
    //Menu Side de la izquierda
    'sideBar.left.file': 'Archivos',
    'sideBar.left.moveRepair': 'Mover a reparación',
    'sideBar.left.deleteFile': 'Eliminar archivo',
    //Ordenes de Venta, linea azul
    'saleOrder.contractAndAvailability': 'Contrato y disponibilidad de unidad',
    'saleOrder.delivery': 'Entrega',
    'saleOrder.availability': 'Disponibilidad',
    'saleOrder.closeContract': 'Contrado cerrado',
    'saleOrder.toBeConfirmd': 'Pendiente por confirmar',
    'saleOrder.confirmed': 'Confirmado',
    'saleOrder.deliverySchedule': 'Programación de entrega',
    'saleOrder.conditioningCar': 'Acondicionamiento del auto',
    'saleOrder.processingWithPlates': 'Trámite de placas',
    'saleOrder.deliveryRoom': 'Sala de Entrega',
    'saleOrder.approximateDeliveryDate': 'Fecha aproximada de entrega',
    'saleOrder.deliveryDateClose': 'Fecha de entrega',
    'saleOrder.deliveryReasonClose': 'Razón de entrega',
    'saleOrder.saved': 'Guardado!',
    'saleOrder.financing': 'Financiamiento',
    'saleOrder.timeLimit': 'Plazo',
    'saleOrder.hitch': 'Enganche',
    'saleOrder.fullDocumentation': 'Documentación completa',
    'saleOrder.paymentStatus': 'Estado de pago',
    'saleOrder.datedelivery': 'Elige fecha de entrega',
    //Detalles de servicio
    'servicesDetails.vehicleInYard': 'Vehículo en patio',
    'servicesDetails.toBeValued': 'Por Valuar',
    'servicesDetails.home': ' Inicio',
    'servicesDetails.entrance': 'Entrada',
    'servicesDetails.valuation': 'Valuación',
    'servicesDetails.valuation.technical': 'Técnico valuador',
    'servicesDetails.valuation.quoting': 'Cotizador',
    'servicesDetails.valuation.deductible_amount': 'Monto deducible',
    'services.createNew.uniUnitt': 'Unidad',
    'servicesDetails.valuation.demerit_amount': 'Monto demérito',
    'servicesDetails.valuation.budget_date': 'Fecha presupuesto',
    'servicesDetails.valuation.quote_date': 'Fecha cotización',
    'servicesDetails.valuation.authorization_date': 'Fecha autorización',
    'servicesDetails.valuation.budget': 'Presupuesto',
    'servicesDetails.valuation.workforce': 'Mano de obra',
    'servicesDetails.valuation.spare_parts': 'Refacciones',
    'servicesDetails.valuation.paint': 'Pintura',
    'servicesDetails.valuation.miscellaneous': 'Misceláneos',
    'servicesDetails.spareParts': 'Refacciones',
    'servicesDetails.repair': 'Reparación',
    'servicesDetails.maintenance': 'Inst. / Matto.',
    'servicesDetails.deliverVehicle': 'Entregar unidad',
    'servicesDetails.history': ' Historial',
    'servicesDetails.parts.request': 'Solicitud de refacciones',
    'servicesDetails.deliveryOrderText': 'Una vez entregado la unidad el expediente no podrá ser modificado de nuevo.',
    //Detalles de servicio, apartado Servicio
    'servicesDetails.carnet': ' Carátula',
    'servicesDetails.servicesDetails': 'Detalles de servicio',
    'servicesDetails.service': 'Servicio',
    'servicesDetails.reception': 'Recepción',
    'servicesDetails.changeAdvisor': 'Cambiar asesor',
    'servicesDetails.deliveryPromise': 'Promesa de entrega',
    'servicesDetails.changePromise': 'Cambiar promesa',
    //Detalles de servicio, apartado Cliente
    'servicesDetails.company': 'Empresa',
    'servicesDetails.sendMessage': 'Enviar mensaje',
    'servicesDetails.confirmDelete': '¿Eliminar expediente?',
    //Detalle de servicio, apartado Aseguradora
    'servicesDetails.deductible': 'Deducible',
    'servicesDetails.orderDetail': 'Detalle de la orden',
    'servicesDetails.policy': 'Póliza',
    'servicesDetails.demerit': 'Démerito',
    'servicesDetails.moveToRepairQueue': 'Mover a cola de reparación',
    'servicesDetails.markAsTotalLoss': 'Marcar como perdida total',
    'servicesDetails.deleteFiles': 'Eliminar archivos',
    'servicesDetails.deleteExpedient': 'Eliminar expediente',
    'servicesDetails.changeDatePromise': 'Cambiar fecha promesa de entrega',
    'servicesDetails.actionNotReversible': 'Esta acción no puede ser reversible',
    'servicesDetails.newdate': 'Nueva fecha',
    //Pagina de los colores
    'vehicleColor.title': 'Colores',
    'vehicleColor.nameColor': 'Nombre del color',
    'vehicleColor.editColor': 'Editar color',
    'vehicleColor.addColor': 'Añadir color',
    //Pagina de los tipos
    'vehicleType.title': 'Tipos',
    'vehicleTypeUnid.title': 'Tipos de unidad',
    'vehicleType.nameType': 'Nombre del tipo',
    'vehicleType.editType': 'Editar tipo',
    'vehicleType.addType': 'Añadir tipo',
    // Proveedores
    'suppliers.title': 'Proveedores',
    'suppliers.name': 'Nombre',
    'suppliers.rfc': 'RFC',
    'suppliers.new': 'Nuevo proveedor',
    'suppliers.edit': 'Editar proveedor',
    'supplier.name': 'Nombre',
    'supplier.tax_id': 'RFC',
    //Relleno del dashboard
    'dashboard.comingSoon': 'Próximamente',
    //Promos
    'promos':'Promociones',
    'promos.edit':'Editar promoción',
    'promos.add':'Agregar promoción',
    //TrackingServiceOrderPage
    'tracking.viewHistory': 'Ver historial',
    'tracking.viewFullHistory': 'Ver historial completo',
    'tracking.date': 'Fecha',
    'tracking.hour': 'Hora',
    'search.again': 'Volver a buscar',
    //Errores
    'pageNotFound.404': 'Regresar al dashboard',
    'errorClosing': 'Hubo un error al entregar el expediente, por favor intenta nuevamente',
    //lunes 28 de marzo
    'salesOrder.loading': 'Cargando...',
    //Página del chat
    'chat.notMessagesYet': 'Sin mensajes todavía',
    'chat.needed': 'Mensaje requerido',
    'chat.sendTextOrFile': 'Puedes enviarle un texto o archivo al cliente',
    'chat.sendMessage': 'Enviar mensaje',
    'chat.shareFile': 'Compartir archivo',
    'chat.downloadMessages': 'Descargar mensajes',
    'chat.chatCustomer':'Chat con cliente',
    // Drive
    'drive.my_drive':'Mi Drive',
    //nueva rama, miercoles 06 de abril
    'branch.back': 'Regresar',
    'branch.rightsReserved': 'Todos los derechos reservados.',
    //martes 19 abril
    'filter.columns': 'Filtrar columnas',
    'functionalities.download_report': 'Descargar reporte',
    //Reparación
    'repair.initprocess':'Iniciar proceso',
    'repair.responsible':'Responsable',
    'repair.changeStatus':'Cambiar de estatus',
    'repair.start': 'Iniciar',
    'repair.stop': 'Detener',
    'repair.currentProcess': 'Proceso Actual',
    'repair.stopProcess': 'Detener proceso',
    'repair.initProcess': 'Iniciar proceso',
    'repair.historic': 'Historial',
    'repair.statusChangeSuccess': 'Estatus cambiado exitosamente',
    'repair.statusChangeError': 'Hubo un error al realizar el cambio, intenta nuevamente',
    //Generales
    'accept':'Aceptar',
    'save.view':'Guardar vista',
    'cancel':'Cancelar',
    'choose':'Elige',
    'process':'Proceso',
    'stopped':'Detenido',
    'started':'Iniciado',
    'download':'Descargar',
    'delete':'Eliminar',
    'confirm':'Confirmar',
    'deliver':'Entregar',
    'customer':'Cliente',
    'comment':'Comentario',
    'update_success':'Actualizado corectamente',
    'error_unknow': 'Error desconocido, intentalo nuevamente',
    'error': 'Error',
    'amount': 'Cantidad',
    'typeJob': 'Tipo de trabajo',
    'concept': 'Concepto',
    'cost': 'Costo Unitario',
    'price': 'Precio Unitario',
    'status': 'Estatus',
    'drag&Drop': 'Haga clic o arrastre el archivo a esta área para cargarlo',
    //QR
    'qr.access':'Código QR Kikert',
    'qr.access.description':'Comparte este código qr con tus clientes para que tengan acceso desde la aplicación'
};
