
import React, { useState, useEffect } from 'react';
import { Layout, Menu, Typography, message,Button, Input, Avatar, Space, Dropdown, Tooltip } from 'antd';
import { useHistory, Link, Redirect, useLocation } from "react-router-dom"
import {
  SettingOutlined,
  UserOutlined,
  SettingTwoTone,
  PoweroffOutlined
} from '@ant-design/icons';
import { logOut } from "../redux/securitryDuck";
import turn from '../image/turn_icon_kikert.svg'
import imgUser from "../image/UserSinBarba.png"
import { KButton } from '../components/KComponents';
import BadgeNotifications from '../components/BadgeNotifications';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import {permissionsExist} from '../lib/utils/utils'

const { Header, Sider, Content } = Layout;
const { Title, Text } = Typography;
const { Search } = Input;

const HeaderLayout = ({user,title,subtitle,permissions,intl,...props}) => {


    const [imageprofile,setImageProfile] = useState(imgUser)
    const history = useHistory()
    const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });


    useEffect(() => {
        if(user?.userprofile?.image_profile){
              setImageProfile(user?.userprofile?.image_profile)
        }
      }, [user])



  const logout = async () => {

    try {
        const success = await logOut()
        if (success) {
            message.success(intl.formatMessage({ id: "security.login.logoutSuccess" }))
            history.push('/security/login')
            window.location.href = '/security/login'
        } else {
            message.error(intl.formatMessage({ id: "security.login.logoutErr" }))
        }
    } catch (e) {

    }

}

    const menu = (
        <Menu>

          <Menu.Item disabled key="0">
            {user.email}
          </Menu.Item>
          <Menu.Item key="1" onClick={() => history.push('/company')}>
                <FormattedMessage id="layout.changeEmpresa" />
            </Menu.Item>
            {
                permissionsExist(permissions.permissionsList,"view_paymentorder") &&
                <Menu.Item key="2" onClick={() => history.push('/membership')}>
                    Mi membresía
                </Menu.Item>
            }
          <Menu.Item onClick={()=> {
                    logout()
                }} key="3">
            Cerrar sesión
          </Menu.Item>
        </Menu>
      );

  return (
    <Header className="site-layout-background" style={{ padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: `${isMobile? '50px' : '0'}` }}>
        
          <Space>
            {
              !isMobile &&
              <Title
                  level={3}
                  className="mb-0 title-layout"
                >
                  <img src={turn} alt="logo" style={{...style.logo,transform: 'rotate(180deg)'}} />{" "}
                  {title ? title : "Mi Dashboard"}
                  <Text style={{ fontWeight: 400 }} >{subtitle ? ` - ${subtitle}` : ""}</Text>
                </Title>
            }
            
              
            <Search allowClear placeholder="Buscar órdenes de servicio..."
            onSearch={(val)=> history.push(`/services/list/?filters=search=${val}`)}
            className='kinput' style={{ width: 200 }} />

            {
              !isMobile &&
              <KButton type={'primary'} onClick={()=>history.push('/services/new')}  text={'+ Nueva orden'}/>
            }
            
          </Space>
          <Space>
            <Tooltip title="Configuraciones">
                <Button icon={<SettingTwoTone twoToneColor="#341EFF" />} onClick={()=> {
                    history.push('/config/parts')
                }} shape="circle" />
            </Tooltip>
            
          <BadgeNotifications/>

          {
            isMobile &&
            <Button icon={<PoweroffOutlined />} onClick={()=> {
                    logout()
                }} shape="circle" />
          }
            
            <Dropdown overlay={menu} trigger={['click']}>
              <a onClick={e => e.preventDefault()}>
                {
                 !isMobile && (imageprofile ?<img src={imageprofile} alt="logo" style={style.avatar} />:<Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} />)
                }
                {
              !isMobile &&
                  
                <Typography.Text>{user.first_name}</Typography.Text>
              }
              </a>
            </Dropdown>
          </Space>
        </Header>
  )
}
const style = {
    logo: {
      width: 20,
      height: 20,
      marginRight: 2
    },
    avatar: {
      width: 30,
      height: 30,
      marginRight: 2
    },
  };

const mapStateToProps = state => ({
    menu: state.settings.menu,
    settings: state.settings,
    user:state.user,
    security: state.security,
    usernameFirst: state.user.first_name,
    usernameLast: state.user.last_name,
    permissions:state.permissions
  })
  
  export default injectIntl(
    connect(mapStateToProps, {
      logOut
    })(HeaderLayout)
  );

