import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { PrivateRoutes, PublicRoutes } from './Routes'
import AppAuthRequiredContainer from './AppAuthRequiredContainer'

export default function AppRouter() {
  return (
    <Switch>
      <Route path="/security">
        <PublicRoutes/>
      </Route>
      <Route path="*">
        <AppAuthRequiredContainer>
          <PrivateRoutes/>
        </AppAuthRequiredContainer>
      </Route>
    </Switch>
  );
}
