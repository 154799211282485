import { useEffect, useState } from 'react'
import {axiosApi, API} from "../../../lib/utils/axiosApi";
import { Global, css } from "@emotion/react";
import { CarOutlined, EyeOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Spin, Tooltip, Modal } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { color } from '@amcharts/amcharts5';
import KModal from "../../../components/common/elements/KModal/KModal";


  const OrderStatusTable = ({ orders, steps }) => {


    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
      };


   const colors = {
       card: 'black',
       textCard: 'white',
       header: 'black',
       tableBody:'#212121',
       textHeader: '#1eed0c'
   }


  // Crear un objeto para agrupar las órdenes por el código de paso
    const ordersByStep = steps.reduce((acc, step) => {
        acc[step.code] = orders.filter(order => order.current_step.step.code === step.code);
        return acc;
    }, {});
    // Función para determinar la clase de estado basada en la fecha de entrega prometida
    const getDeliveryStatusClass = (order) => {
        if(!order?.id){
            return '';
        }
        if (!order?.delivery_promise_date){
            return 'no-date';
        }
        const today = new Date();
        const deliveryDate = new Date(order.delivery_promise_date);
        if (deliveryDate < today) {
            return 'status-past-due'; // Rojo si la fecha prometida ya pasó
        } else if (deliveryDate.toDateString() === today.toDateString()) {
            return 'status-due-today'; // Amarillo si la fecha prometida es hoy
        } else {
            return 'status-on-time'; // Verde si la fecha prometida está en el futuro
        }
        };
        
   // Calcular el número de filas necesario para llenar la pantalla
    const numberOfRows = Math.ceil(window.innerHeight / 50); // Asumiendo que cada fila tiene 50px de altura

    /**
     * Devuelve un componente de párrafo con la fecha de entrega prometida de una orden.
     * Si la orden no tiene una fecha de entrega prometida o un ID, muestra "Sin fecha promesa".
     * De lo contrario, muestra la fecha de entrega prometida formateada.
     * 
     * @param {Object} order - Objeto que representa la orden.
     * @returns {JSX.Element} Elemento de párrafo con la clase correspondiente y la fecha de entrega.
     */
    const getStatusDeliveryDate = (order) => {
        let currentStep = order?.current_step;
        return (order?.delivery_promise_date && order?.id) ? 
        <p style={{color: 'black', fontSize:10, fontWeight:'bold'}} className={getDeliveryStatusClass(order)}>
              {currentStep?.assigned_to?.first_name} {currentStep?.assigned_to?.last_name?.substring(0, 3)} -
              {moment(order?.delivery_promise_date).format('DD/MM/YYYY hh:mm a')} 
              <Tooltip title="Ver detalle">
                <Link to={`/service-repair/${order?.id}/`} style={{marginLeft:10, color:'black'}}><EyeOutlined /></Link>
              </Tooltip>
            </p>: order?.id ?
             <p style={{color: 'black', fontSize:10, fontWeight:'bold'}}className={getDeliveryStatusClass(order)}>
                {currentStep?.assigned_to?.first_name} {currentStep?.assigned_to?.last_name?.substring(0, 3)} -
                Sin fecha promesa
             <Tooltip title="Ver detalle">
                <Link to={`/service-repair/${order?.id}/`} style={{marginLeft:10, color:'black'}}><EyeOutlined /></Link>
             </Tooltip>
             </p> : null
    }

    const getDataOrder = (order)=>{
        let vehicle = order?.WORKSHOP?.vehicle;
        return order?.id ?  
        <div className={getDeliveryStatusClass(order)}>
            <p style={{margin:0,padding:0,color:'blue'}}><b>{order?.order_number}</b></p>
            {
              order?.current_location && <p>Locacion: {order?.current_location?.name}</p>
            }
            
            <span><CarOutlined style={{marginRight: '5px'}} />{vehicle?.model?.brand?.name} {vehicle?.model?.name} {vehicle?.license_plate} {vehicle?.color?.name} {vehicle?.year}</span>
        </div>
        : <p></p>
    }

      // Calcular el ancho mínimo de cada celda para llenar el ancho de la pantalla
    const minWidthPerCell = Math.floor(window.innerWidth / steps.length);

     // Calcular el alto mínimo de cada fila para llenar el alto de la pantalla
    const minHeightPerRow = Math.floor(window.innerHeight / numberOfRows);


  return (
    <div>

    <Global
        styles={css`
        /* Estilos para la tabla */

            body{
                background-color: #212121;
            }

            .table-header {
                background-color: ${colors.header};
                color: ${colors.textHeader};
            }

            table {
                width: 100%;
                border-collapse: collapse; /* Asegura que los bordes de las celdas se colapsen en uno solo */
            }

            .table-header th{
                font-size:8px;
                font-weight:bold;
            }
              
            .table-header, .table-body td {
                border-right: 1px solid #11ec11; /* Añade borde derecho blanco a cada celda */
            }

            .table-body td:last-child {
                border-right: none; /* Elimina el borde derecho de la última celda de cada fila */
            }

            .table-header, .table-body td {
            }

            .table-body {
            background-color: ${colors.tableBody};
            color: white;
            }


            .status-past-due {
                text-align: center;
                padding: 0px;
                margin: 0px;
                background-color: #EF5350 !important; /* Rojo para entregas vencidas */
              }

              .status-due-today {
                text-align: center;
                padding: 0px;
                margin: 0px;
                background-color: yellow; /* Amarillo para entregas de hoy */
              }
              
              .status-on-time {
                text-align: center;
                padding: 0px;
                margin: 0px;
                background-color: green; /* Verde para entregas a tiempo */
              }

              .no-date{
                padding: 0px;
                margin: 0px;
                text-align: center;
                color:black;
                background-color: #a3a3a3; /* Verde para entregas a tiempo */
              }
          
        `}
      />

      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead className="table-header">
          <tr>
            {steps.map(step => (
              <th key={step.id}>{step.name}
              <Tooltip title="Explicación de colores">
                <QuestionCircleOutlined onClick={showModal} style={{ cursor: 'pointer' }} />
              </Tooltip>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-body">
          {/* Crear filas basadas en el número máximo de órdenes en cualquier columna */}
          {Array.from({ length: numberOfRows+Math.max(...Object.values(ordersByStep).map(orders => orders.length)) }).map((_, rowIndex) => (
            <tr key={rowIndex} style={{ height: `${minHeightPerRow}px`, padding:0 }}>
              {steps.map(step => (
                step?.id && <td key={step.id}  
                style={{
                    fontSize:8, 
                    minWidth: `${minWidthPerCell}px`, 
                    margin:0,padding:0,
                    verticalAlign: 'top' ,
                    borderTop:`${ordersByStep[step.code][rowIndex]?.id ? '2px solid black' : ''} `,
                    background:`${ordersByStep[step.code][rowIndex]?.id ? colors.card : ''}`}}>
                        <div>
                            {
                                getStatusDeliveryDate(ordersByStep[step.code][rowIndex])
                            }
                            {
                                getDataOrder(ordersByStep[step.code][rowIndex])
                            }

                        </div>
                  
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <KModal title="Explicación de colores de estatus" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p><span style={{ backgroundColor: '#EF5350', padding: '2px 5px' }}>Rojo</span>: Entregas vencidas</p>
        <p><span style={{ backgroundColor: 'yellow', padding: '2px 5px' }}>Amarillo</span>: Entregas de hoy</p>
        <p><span style={{ backgroundColor: 'green', padding: '2px 5px' }}>Verde</span>: Entregas a tiempo</p>
        <p><span style={{ backgroundColor: '#a3a3a3', padding: '2px 5px' }}>Gris</span>: Sin fecha promesa</p>
      </KModal>

    </div>
  );
};


const ElectroplanLight = () => {

    const [dataElectroplan, setDataElectroplan] = useState([])
    const [loading, setLoading] = useState(false)
    const [steps, setSteps] = useState([])
    let firstLoad = true

    useEffect(()=>{
        getData()
        getInfo()
        setTimeout(()=>{
            getData()
        },60000)
    },[])

    
    const getInfo = async () => {
        try {
          let settings = localStorage.getItem('settings_kk')
          settings = JSON.parse(settings)
          const branchSelected = settings?.branchSelected?.id
          let response = await axiosApi.get(API.WORKFLOW.WORKFLOW);
          if (response.status === 200) {
            const filteredData = response.data.results.filter((i) => !i.branch || i.branch === branchSelected)
            console.log('filteredData',filteredData)
            if(filteredData.length > 0){
                setSteps(filteredData[0]?.steps)
            }
          }
        } catch (error) {
          console.log('error__')
        }
      };

      const getData = async () => {
        if(firstLoad){
            setLoading(true)
            firstLoad = false
        }
        try{
            let response = await axiosApi.get(
                API.WORKSHOP.WORKSHOP_ORDER_DETAIL + "?page_size=1000000000&process_status=in_process&is_deleted=false"
              );
            if (response.status === 200) {
              setDataElectroplan(response.data.results);
            }
        }catch(error){
            console.log('error__')
        }finally{
            setLoading(false)
        }
        
      }

    return (
        <div>
        <Spin spinning={loading}><OrderStatusTable orders={dataElectroplan} steps={steps} /></Spin>
    </div>
    )
}

export default ElectroplanLight