import React, { useEffect, useCallback, useState } from "react";
import {
    Select,
    Form
} from "antd";
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import {axiosApi, API} from "../../lib/utils/axiosApi";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
const { Option } = Select;

const SelectSuppliers=({name='asesor',extra=null, label=null})=>{

    const [suppliers, setSuppliers] = useState([]);

    const getSuppliers = async() => {
        try{
            const res =await  axiosApi.get(API.BUSINESS.SUPPLIERS+'?page_size=1000');
            setSuppliers(res?.data?.results ? res?.data?.results  :[])
        }catch (e){
            setSuppliers([])
        }
    }

    const memoizedGetSuppliers = useCallback(() => {
        getSuppliers();
    }, [getSuppliers, name]); // Asegrate de que getSuppliers no cambie innecesariamente

    useEffect(()=>{
        memoizedGetSuppliers();    
    },[memoizedGetSuppliers, name]) // memoizedGetSuppliers solo cambia si getSuppliers cambia

    
    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="services.page.advisor" />}
            name={name}
            extra={extra}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect
                showSearch
                allowClear
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                options={
                    suppliers && _.map(suppliers, (o)=>{
                        return {
                            value:o.id,
                            label:`${o.name}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}



export default injectIntl(SelectSuppliers);
