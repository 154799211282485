import React, { useEffect, useState } from 'react';
import {Select} from  'antd'
import { Global, css } from "@emotion/react";
import styles from "./KSelect.module.css"
import {FormattedMessage} from "react-intl";

const { Option } = Select;

const KSelect=({
                   options=[],
                   style={width:'100%'},
                   value=undefined,
                   loading,
                   defaultActiveFirstOption,
                   showArrow=true,
                   showSearch=false,
                   notFoundContent,
                   ...props
                   })=>{

    return (
      <>
        <Global
          styles={css`
            .ant-select-arrow {
              color: var(--primaryColor) !important;
              font-weight: bolder;
            }

            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              border-radius: 6px;
            }

            .ant-select-arrow,
            .ant-picker-suffix {
              border-left: solid 1px var(--primaryColor);
              padding: 5px 16px;
              top: 37%;
              right: 2px;
              height: auto;
              width: auto;
            }

            .ant-select-selection-item {
              color: var(--primaryColor);
              margin-top: 3px;
              padding-right: 35px !important;
              text-align: left !important;
            }

            .ant-select-selector {
              height: 40px !important;
            }
          `}
        />
        <Select
          loading={loading}
          className={styles.kselect}
          style={style}
          defaultActiveFirstOption={defaultActiveFirstOption}
          showArrow={showArrow}
          notFoundContent={notFoundContent}
          value={value}
          showSearch={showSearch}
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          defaultValue={value}
          {...props}
          maxTagCount="responsive"
        >
          {options
            ? options.map((op, i) => {
                return (
                  <Option key={op.value} value={op.value}>
                    {op.label}
                  </Option>
                );
              })
            : null}
        </Select>
      </>
    );
}


export default KSelect;