import React, { useEffect } from "react";
import {
    Modal,
    Select,
    Form,
    Typography,
    Button,
    Row,
    Col,
    Space,
    Divider,
    Input,
    DatePicker,
    Checkbox
} from "antd";
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from "react-intl";
import KSelect from "../common/elements/KSelect/KSelect";
import _ from "lodash";
import {getUsersAdvisors} from "../../redux/membersDuck";
const { Option } = Select;

const SelectAsesor=({name='asesor',members,getUsersAdvisors, label=null, ...props})=>{

    useEffect(()=>{
        getUsersAdvisors()
    },[])


    return (
        <Form.Item
            label={label ? label : <FormattedMessage id="services.page.advisor" />}
            name={name}
            className="item-expediente-lg mb-0 label-margin"
        >
            <KSelect
                options={
                    members?.usersAdvisorsList && _.map(members?.usersAdvisorsList, (o)=>{
                        return {
                            value:o.id,
                            label:`${o.first_name} ${o.last_name}`
                        }
                    })
                }
            />
        </Form.Item>
    )
}

const mapStateToProps = (state) => ({
    members: state.members
});

export default injectIntl(
    connect(mapStateToProps, { getUsersAdvisors })(SelectAsesor)
);
