export const enus = {
    'ok': 'Ok',
    'name': "Name",
    'last_name': "Last name",
    'email': "Email",
    'phone': "Phone",
    'form.is_required' : "This field is required",
    'form.type_email' : "it is not a valid email",
    'form.confirmPassword' : "The two passwords that you entered do not match!",
    'form.date': "Select a date",
    'profile_assigned': "Profile assigned",
    "actions": "Actions",
    "update": "Update",
    "save": "Save",
    "cover": "Order service",
    "invoicing":'Invoicing',
    'changelang': 'Cambiar a español', //Botón para cambiar de idioma
    'no_results_found': 'No results found',
    //Pantalla de login, Recuperar contraseña y Reset Password
    'security.login.title': 'Enter your credentials to login',
    'servicesDetails.moveToRepairQueue': 'Move to repair Queu',
    'security.login.incorrectAcces': 'Incorrect access',
    'security.login.logoutSuccess': 'Logout successful',
    'security.login.logoutErr': 'Could not logout, please try again',
    'security.login.email': 'Email',
    'security.login.password': 'Password',
    'security.login.signin': 'Sign In',
    'security.login.forgot': 'Forgot your password?',
    'security.login.newaccount': "Don't have an account?",
    'security.login.signup': 'Sign Up',
    'security.login.buy': 'BUY NOW',
    'security.login.accepTerms': 'I agree to the Terms and Conditions.',
    'security.forgot.title': 'Text your email address',
    'security.forgot.instruction': 'Enter your email address and we will send you instructions on how to reset a new one.',
    'security.forgot.login': 'Log In',
    'security.forgot.send': 'Send',
    'security.forgot.back': 'Back',
    'security.login.messageUser': 'Please, enter a email.',
    'security.login.messagePass': 'Please, enter your password.',
    'security.login.click': 'Click',
    'security.login.here': 'Here.',
    'security.forgot.messageDone': 'It has been sent to your mail for recovery.',
    'security.forgot.messageDonePassword': 'Your password has been changed.',
    'security.confirm.title': 'Confirm your new password.',
    'security.profile.title': 'Profile',
    //Escoger la empresa y la sucursal
    'bussiness.branch.chooseBussines': 'Choose your company.',
    'bussiness.company.chooseTaller': 'Choose your Branch / Workshop.',
    //Menu del dashboard que se situa a la izquierda
    'services.dash': 'Dashboard',
    'services.dash.goal.title': 'Dashboard de metas',
    'services.analytics': 'Analytics',
    'services.sale': 'Sales',
    'services.service': 'Services',
    'services.service.saleOrders': 'Sales',
    'services.team': 'Team',
    'services.mess': ' Messages',
    'services.setts': 'Settings',
    'services.insur': 'Insurers',
    'services.brand': 'Brands',
    'services.models': 'Models',
    'services.metas': 'Metas',
    'services.metas.type': 'Type',
    'services.metas.value': 'Value',
    'services.metas.date': 'Application date',
    'services.metas.edit': 'edit meta',
    'services.metas.new': 'New meta',
    'services.metas.upd': 'Update meta',
    'services.metas.form.type': 'Meta type',
    'services.metas.form.value': 'Value',
    'services.metas.form.aplication_date': 'Aplication date',
    'services.members_center': 'Members center',
    'services.members.delete.description': 'The group {group} will be deleted. This action cannot be reversed',
    'members.in_process_repair':'Puede ser asignado a un proceso de reparación',
    'members.can_recepit_unit':'Puede recepcionar unidades',
    'members.can_reopen_order':'Can re-open orders',
    'members.can_show_membership':'Show membership',
    'members.can_change_promise_date':'Can change promnise date in service order',
    'members.receive_promise_date_noti':'Receive change promise date notification',
    //Botón para cambiar de empresa o cerrar sesión
    'layout.changeEmpresa': 'Change Company',
    'layout.logOut': 'Log Out',
    'layout.about': 'About',
    //Página de la aseguradora, CREAR, EDITAR, ELIMINAR
    'layout.name': 'Name',
    'layout.lastname': 'Lastname',
    'layout.phone': 'Phone',
    'layout.assigned_profile': 'Assigned profile',
    'layout.actions': 'Actions',
    'layout.createNew': ' Create New',
    'layout.agents': 'Agents',
    'layout.edit': 'Edit',
    'layout.delete': 'Delete',
    'layout.addInsure': 'Add Insurers',
    'services.insure': 'Insurer',
    'layout.new': ' New',
    'layout.nameAgente': 'Name of agent',
    'layout.cancel': 'Cancel',
    'layout.close': 'Close',
    'layout.print': ' Print',
    'layout.add': 'Add',
    'layout.goBack': 'Go back',
    'layout.name_is_required': 'Please input a name',
    'layout.functions': 'Functions',
    'layout.catalogs': 'catalogues',
    //Titulo del Dash y el buscador
    'dashboard.myDashboard': 'My Dashboard',
    'dashboard.myAnalytics': 'My Analytics',
    'dashboard.search': 'Search',
    'dashboard.title': 'Unit',
    //Errores y Succes
    'item.deleteSuccess': 'It has been successfully removed.',
    'item.saveSuccess': 'It has been saved correctly.',
    'item.editSuccess': 'It has been successfully edited.',
    'deleted.ask': 'Are you sure you want to delete the registry?',
    'deleted.description': 'The register "{obj}" will be deleted. This action cannot be reversed.',
    'item.issure.agenteErr': 'There was an error adding the agent, please try again.',
    'item.brand.registerErr': 'An error occurred while deleting the register',
    'item.brand.element': 'There was an error saving the element',
    //Página de la Marca, CREAR, EDITAR Y ELIMINAR
    'item.brand.nameBrand': 'Name of brand',
    'item.brand.editBrand': 'Edit brand',
    'item.brand.addBrand': 'Add brand',
    //Página del Modelo,  CREAR, EDITAR Y ELIMINAR
    'item.model.nameModel': 'Name of model',
    'item.model.editModel': 'Edit model',
    'item.model.addModel': 'Add model',
    'layout.model': 'Model',
    'layout.brand': 'Brand',
    //Pagina tabla de services y donde aparece el electroplan
    'services.page.electroplan': 'Electroplan',
    'services.page.reports': ' Reports',
    'services.page.report': ' Report',
    'services.page.jacreport': 'Delivery Report',
    'services.page.analitycs': ' Analitycs',
    'services.page.configurations': ' Configurations',
    'services.page.apps': ' Apps',
    'services.page.file': ' File',
    'services.page.advisor': 'Advisor',
    'services.page.technical': 'Technical',
    'services.page.costumer': 'Costumer',
    'services.page.status': 'Status',
    'services.page.filter': ' Filters',
    'services.page.hide_status': 'Visible for user',
    'services.switch.hide': 'Hide',
    'services.switch.visible': 'Visible',
    'services.page.err': 'Loading error ',
    'saleOrder.deliveryDateClose': 'Delivery date',
    'services.searchtext':'Search by folio/customer name/order number / VIN / License plate',
    'servicespart.searchtext':'Search by folio/part/supplier',
    //Archvios y carpetas de servicios
    'services.files.title': ' Dossier files',
    'services.files.newFolder': ' new folder',
    'services.files.EditName': 'Edit name',
    'services.files.autogenerated': 'Autogenerated',
    'services.files.newFile': ' new file',
    'services.files.files': 'Files',
    'services.files.folders': 'Folders',
    'services.files.empty': 'Empty',
    //Ventama de filtros de servicios
    'services.filter.filter': 'Filter result',
    'services.filter.date': 'Discharge date',
    'services.filter.stage': 'Stage',
    'services.filter.vehicleLocation': 'Vehicle location',
    'services.filter.dateCita': 'Appointment date',
    'services.filter.dateInMechanic': 'Date of entry to the workshop',
    'services.filter.datePromise': 'Promise date',
    'services.filter.invoiced': 'Invoiced',
    'services.filter.privateParticular': 'It is particular',
    'services.filter.includeCloseFiles': 'Include closed files',
    'services.filter.clearFilter': 'Clean filter',
    'services.filter.save': ' Save',
    'services.filter.everything': 'Everything',
    //facturacion
    'service.dataInvoice':'Billing Data',
    //Ventana crear nuevos servicios
    'services.createNew': 'Services - Create New',
    'services.update': 'Services - Update',
    'services.createNew.reception': 'Reception',
    'services.createNew.serviceOpening': 'Service opening',
    'services.createNew.dateReceipt': 'Date',
    'services.createNew.orderNumber': 'Order number',
    'services.createNew.pyramid': 'Pyramid',
    'services.createNew.byIsurer': 'By insurer',
    'services.createNew.privateIndividual': 'Private individual',
    'services.createNew.unit': 'Unit',
    'services.createNew.equipment': 'Equipment',
    'services.createNew.equipmentData': 'Equipment data',
    'services.createNew.labelUnit': 'Search by vin or plates...',
    'services.createNew.addNew': ' Add New',
    'services.createNew.serialNumber': 'Serial number',
    'services.createNew.plates': 'Plates',
    'services.createNew.typePetrol': 'Type of petrol',
    'services.createNew.typeVehicle': 'Type of unit',
    'services.createNew.typeEquipment': 'Tipe of equipment',
    'services.createNew.colour': 'Colour',
    'services.createNew.searchUnit': ' Search unit',
    'services.createNew.searchEquipment': ' Search equipment',
    'services.createNew.client': 'Client',
    'services.createNew.labelClient': 'Search by name or email...',
    'services.createNew.lastNameFather': "Father's surname",
    'services.createNew.lastNameMother': "Mother's surname",
    'services.createNew.celNumber': 'Phone/Cellphone',
    'services.createNew.address': 'Address',
    'services.createNew.searchClient': ' Search client',
    'services.createNew.comments': 'Comments',
    'services.createNew.addInformation': 'Additional service information',
    'services.createNew.labelComments': 'Identified knocks, comments from the insurer and/or company, missing accessories, among others... Any information that may be useful for the correct handling of this service order',
    'services.createNew.siniestroNumber': 'Siniestro number',
    'services.createNew.adjuster': 'Adjuster',
    'services.createNew.numberGuaranty': 'Guaranty Number',
    'services.createNew.dateValidityGuaranty': 'Date validity of guaranty',
    'services.createNew.saving': 'Saving...',
    'services.createNew.saved': 'Saved!',
    'services.createNew.err': 'Error saving the service',
    //Página de Members Center
    'members.title': 'Member Center',
    'members.profile': 'Profile',
    'members.search_name': 'Search by name',
    'members.new_profile': 'New profile',
    'members.new_user': 'New user',
    'members.delete_user_title': 'Delete user',
    'members.delete_user_description': 'The user "{user}" will be deleted. This action cannot be reversed',
    'members.delete_user_success': 'User deleted successfully.',
    'members.table.profiles': 'Profiles',
    'members.table.users': 'Users',
    'members.table.actions': 'Actions',
    'members.table.showUsers': 'Show Users',
    'members.table.users_plural': 'User(s)',
    'members.table.create_group': 'Create group',
    'members.table.create_group_label': 'Group name',
    'services.members.create.save': 'Save',
    //Página de crear ordenes de venta
    'services.saleOrder.title': 'Sales',
    'services.OrderTypeSale': 'Order type sale',
    'services.saleOrder.createNew': 'Sales - Create new',
    'services.saleOrder.typeOfSale': 'Type of sale',
    'services.saleOrder.credit': 'Credit',
    'services.saleOrder.cash': 'Cash',
    'services.saleOrder.year': 'Year',
    'services.saleOrder.carOfInterest': 'Car of interest',
    'services.saleOrder.validateData': 'Please validate your data',
    'services.saleOrder.obligatory': ' is obligatory',
    'services.saleOrder.typePayment': 'Type of payment',
    'services.saleOrder.deleteQuestion': 'Are you sure you want to change the status of the sale?',
    'services.saleOrder.deleteDescription' : "This change can be viewed by the client from their application.",
    //Menu Side de la izquierda
    'sideBar.left.file': 'Files',
    'sideBar.left.moveRepair': 'Move to repair',
    'sideBar.left.deleteFile': 'Delete file',
    //Ordenes de Venta, linea azul
    'saleOrder.contractAndAvailability': 'Contract and unit availability',
    'saleOrder.delivery': 'Delivery',
    'saleOrder.availability': 'Availability',
    'saleOrder.closeContract': 'Closed contract',
    'saleOrder.toBeConfirmd': 'To be confirmed',
    'saleOrder.confirmed': 'Confirmed',
    'saleOrder.deliverySchedule': 'Delivery schedule',
    'saleOrder.conditioningCar': 'Conditioning of the car',
    'saleOrder.processingWithPlates': 'Processing plates',
    'saleOrder.deliveryRoom': 'Delivery Room',
    'saleOrder.approximateDeliveryDate': 'Approximate delivery date',
    'saleOrder.saved': 'Saved!',
    'saleOrder.financing': 'Financing',
    'saleOrder.timeLimit': 'Time Limit',
    'saleOrder.hitch': 'Hitch',
    'saleOrder.fullDocumentation': 'Full Documentation',
    'saleOrder.paymentStatus': 'Payment status',
    //Detalles de servicio
    'servicesDetails.vehicleInYard': 'Vehicle in yard',
    'servicesDetails.toBeValued': 'To be valued',
    'servicesDetails.home': ' Home',
    'servicesDetails.entrance': 'Entrance',
    'servicesDetails.valuation': 'Valuation',
    'servicesDetails.valuation.technical': 'appraiser technician',
    'servicesDetails.valuation.quoting': 'Quoting',
    'servicesDetails.valuation.deductible_amount': 'Deductible amount',
    'services.createNew.uniUnitt': 'Unit',
    'servicesDetails.valuation.demerit_amount': 'Demerit amount',
    'servicesDetails.valuation.budget_date': 'Budget date',
    'servicesDetails.valuation.quote_date': 'Quote date',
    'servicesDetails.valuation.authorization_date': 'Authorization date',
    'servicesDetails.valuation.Budget': 'Budget',
    'servicesDetails.valuation.workforce': 'Workforce',
    'servicesDetails.valuation.spare_parts': 'Spare parts',
    'servicesDetails.valuation.paint': 'Paint',
    'servicesDetails.valuation.miscellaneous': 'Miscellaneous',
    'servicesDetails.spareParts': 'Spare parts',
    'servicesDetails.repair': 'Repair',
    'servicesDetails.maintenance': 'Maintenance',
    'servicesDetails.deliverVehicle': 'Deliver vehicle',
    'servicesDetails.history': ' History',
    'servicesDetails.parts.request': 'Part requests',
    //Detalles de servicio, apartado Servicio
    'servicesDetails.carnet': ' Carnet',
    'servicesDetails.servicesDetails': 'Services details',
    'servicesDetails.service': 'Service',
    'servicesDetails.reception': 'Reception',
    'servicesDetails.changeAdvisor': 'Change advisor',
    'servicesDetails.deliveryPromise': 'Delivery promise',
    'servicesDetails.changePromise': 'Change promise',
    //Detalles de servicio, apartado Cliente
    'servicesDetails.company': 'Company',
    'servicesDetails.sendMessage': 'Send Message',
    //Detalle de servicio, apartado Aseguradora
    'servicesDetails.deductible': 'Deductible',
    'servicesDetails.orderDetail': 'Orden detail',
    'servicesDetails.policy': 'Policy',
    'servicesDetails.deliveryOrderText': 'Once the vehicle has been delivered, the expedient cannot be modified again.',
    'servicesDetails.demerit': 'Demerit',
    'servicesDetails.moToRepairQueue': 'Move to repair queue',
    'servicesDetails.markAsTotalLoss': 'Mark as total loss',
    'servicesDetails.deleteFiles': 'Delete files',
    'servicesDetails.actionNotReversible': 'This action cannot be reversed',
    'servicesDetails.confirmDelete': 'Delete expedient?',
    'servicesDetails.newdate': 'New date',
    //Pagina de los colores
    'vehicleColor.title': 'Colours',
    'vehicleColor.nameColor': 'Name of colour',
    'vehicleColor.editColor': 'Edit colour',
    'vehicleColor.addColor': 'Add colour',
    //Pagina de los tipos
    'vehicleType.title': 'Types',
    'vehicleTypeUnid.title': 'Tipos de unidad',
    'vehicleType.nameType': 'Name of type',
    'vehicleType.editType': 'Edit type',
    'vehicleType.addType': 'Add type',
    //Proveedores
    'suppliers.title': 'Suppliers',
    'suppliers.name': 'Name',
    'suppliers.rfc': 'RFC',
    'suppliers.new': 'new supplier',
    'suppliers.edit': 'edit supplier',
    'supplier.name': 'Name',
    'supplier.tax_id': 'RFC',
    //Relleno del dashboard
    'dashboard.comingSoon': 'Coming Soon',
    //Promos
    'promos':'Promotions',
    'promos.edit':'Edit promotion',
    'promos.add':'Add promotion',
    //TrackingServiceOrderPage
    'tracking.viewHistory': 'View history',
    'tracking.viewFullHistory': 'View full history',
    'tracking.date': 'Date',
    'tracking.hour': 'Hour',
    'search.again': 'Search again',
    //Errores
    'pageNotFound.404': 'Go back',
    'errorClosing': 'Hubo un error al entregar el expediente, por favor intenta nuevamente',
    //lunes 28 de marzo
    'salesOrder.loading': 'Loading...',
    //Página del chat
    'chat.notMessagesYet': 'Not messages yet',
    'chat.needed': 'Message required',
    'chat.sendTextOrFile': 'You can send a text or file to the client',
    'chat.sendMessage': 'Send message',
    'chat.shareFile': 'Share file',
    'chat.downloadMessages': 'Download messages',
    'chat.chatCustomer':'Customer chat',
    'servicesDetails.deleteExpedient': 'Delete expedient',
    'servicesDetails.changeDatePromise': 'Change promise date',
    //Drive
    'drive.my_drive':'Mi Drive',
    //nueva rama, miercoles 06 de abril
    'branch.back': 'Back to',
    'branch.rightsReserved': 'All rights reserved.',
    //martes 19 abril
    'filter.columns': 'Filter columns',
    'functionalities.download_report': 'Download report',
    //Reparación
    'repair.initprocess':'Start process ',
    'repair.responsible':'Responsible',
    'repair.changeStatus':'Change status',
    'repair.start': 'Start',
    'repair.stop': 'Stop',
    'repair.currentProcess': 'Current process',
    'repair.stopProcess': 'Stop process',
    'repair.initProcess': 'Start process',
    'repair.historic': 'Historic',
    //Generales
    'accept':'Accept',
    'save.view':'Save view',
    'cancel':'Cancel',
    'process':'Proceso',
    'stopped':'Stopped',
    'started':'Started',
    'choose':'choose',
    'download':'Download',
    'delete':'Delete',
    'confirm':'Confirm',
    'deliver':'Deliver',
    'customer':'Customer',
    'comment':'Comment',
    'update_success':'Updated success',
    'error_unknow': 'Error desconocido, intentalo nuevamente',
    'error': 'Error',
    'amount': 'Amount',
    'typeJob': 'Job type',
    'concept': 'Concept',
    'cost': 'Cost',
    'price': 'Price',
    'status': 'Status',
    'drag&Drop': 'Click or drag file to this area to upload',
    //QR
    'qr.access':'kikert qr code',
    'qr.access.description':'Share this qr code with your customers so that they have access from the application'
};
