import { axiosApi, API } from "../lib/utils/axiosApi"

//constants
let initialData = {
    lang: "es-mx",
    fetching: false,
    settingsData: {
        companySelected: null,
        branchSelected: null,
    },
    brands: null,
    brandsList: [],
    brandsListTotal: 0,
    models: null,
    modelsList: [],
    vehicleColors: null,
    vehicleColorsList: [],
    vehicleType: null,
    vehicleTypeList: [],
    metaList: [],
    types: null,
    colors: null,
    colorsList: [],
    generalInfo:null,
    generalInfoMeta:[],
    valuationIsActive: false,
    insuranceIsActive: false,
    defaultCustomerId: null,
    orderNumberDisabled: false,
    entryDamageImgIsActive: false,
    defaultCustomerUrl: null,
    suppliersList: [],
    menu: {
        collapsed: false,
        keySelected: 1,
    },
};


const CHANGE_LANG = "CHANGE_LANG"
const GET_BRANDS = "GET_BRANDS"
const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS"
const GET_BRANDS_ERROR = "GET_BRANDS_ERROR"
const SAVE_BRANDS = "SAVE_BRANDS"
const SAVE_BRANDS_SUCCESS = "SAVE_BRANDS_SUCCESS"
const SAVE_BRANDS_ERROR = "SAVE_BRANDS_ERROR"
const DELETE_BRAND = "DELETE_BRAND"
const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS"
const DELETE_BRAND_ERROR = "DELETE_BRAND_ERROR"

const GET_COLORS = "GET_COLORS"
const GET_COLORS_SUCCESS = "GET_COLORS_SUCCESS"
const GET_COLORS_ERROR = "GET_COLORS_ERROR"
/// models
const GET_MODELS = "GET_MODELS"
const GET_MODELS_SUCCESS = "GET_MODELS_SUCCESS"
const GET_MODELS_ERROR = "GET_MODELS_ERROR"
const DELETE_MODEL = "DELETE_MODEL"
const DELETE_MODEL_SUCCESS = "DELETE_MODEL_SUCCESS"
const DELETE_MODEL_ERROR = "DELETE_MODEL_ERROR"
const SAVE_MODEL = "SAVE_MODEL"
const SAVE_MODEL_SUCCESS = "SAVE_MODEL_SUCCESS"
const SAVE_MODEL_ERROR = "SAVE_MODEL_ERROR"


//Color de vehiculos
const GET_VEHICLECOLORS = "GET_VEHICLECOLORS"
const GET_VEHICLECOLORS_SUCCESS = "GET_VEHICLECOLORS_SUCCESS"
const GET_VEHICLECOLORS_ERROR = "GET_VEHICLECOLORS_ERROR"
const DELETE_VEHICLECOLORS = "DELETE_VEHICLECOLORS"
const DELETE_VEHICLECOLORS_SUCCESS = "DELETE_VEHICLECOLORS_SUCCESS"
const DELETE_VEHICLECOLORS_ERROR = "DELETE_VEHICLECOLORS_ERROR"
const SAVE_VEHICLECOLORS = "SAVE_VEHICLECOLORS"
const SAVE_VEHICLECOLORS_SUCCESS = "SAVE_VEHICLECOLORS_SUCCESS"
const SAVE_VEHICLECOLORS_ERROR = "SAVE_VEHICLECOLORS_ERROR"

//Tipo de vehiculos
const GET_VEHICLETYPE = "GET_VEHICLETYPE"
const GET_VEHICLETYPE_SUCCESS = "GET_VEHICLETYPE_SUCCESS"
const GET_VEHICLETYPE_ERROR = "GET_VEHICLETYPE_ERROR"
const DELETE_VEHICLETYPE = "DELETE_VEHICLETYPE"
const DELETE_VEHICLETYPE_SUCCESS = "DELETE_VEHICLETYPE_SUCCESS"
const DELETE_VEHICLETYPE_ERROR = "DELETE_VEHICLETYPE_ERROR"
const SAVE_VEHICLETYPE = "SAVE_VEHICLETYPE"
const SAVE_VEHICLETYPE_SUCCESS = "SAVE_VEHICLETYPE_SUCCESS"
const SAVE_VEHICLETYPE_ERROR = "SAVE_VEHICLETYPE_ERROR"

//Proveedores
const GET_SUPPLIER = "GET_SUPPLIERS"
const GET_SUPPLIER_SUCCESS = "GET_SUPPLIERS_SUCCESS"
const GET_SUPPLIER_ERROR = "GET_SUPPLIERS_ERROR"
const DELETE_SUPPLIER = "DELETE_SUPPLIER"
const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS"
const DELETE_SUPPLIER_ERROR = "DELETE_SUPPLIER_ERROR"
const SAVE_SUPPLIER = "SAVE_SUPPLIER"
const SAVE_SUPPLIER_SUCCESS = "SAVE_SUPPLIER_SUCCESS"
const SAVE_SUPPLIER_ERROR = "SAVE_SUPPLIER_ERROR"


// Metas
const GET_METAS = "GET_METAS"
const GET_METAS_SUCCESS = "GET_METAS_SUCCESS"
const GET_METAS_ERROR = "GET_METAS_ERROR"
const SAVE_META = "SAVE_META"
const SAVE_META_SUCCESS = "SAVE_META_SUCCESS"
const SAVE_META_ERROR = "SAVE_META_ERROR"
const DELETE_META = "DELETE_META"
const DELETE_META_SUCCESS = "DELETE_META_SUCCESS"
const DELETE_META_ERROR = "DELETE_META_ERROR"

// Info general
const GET_GENERAL_INFO = "GET_GENERAL_INFO"

// Info meta general
const GET_GENERAL_INFO_META = "GET_GENERAL_INFO_META"

// settings Data
const SAVE_SETTINGS_DATA = "SAVE_SETTINGS_DATA"

// config Sider
const TOGGLE_MENU = "TOGGLE_MENU";

// reducer

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case SAVE_SETTINGS_DATA:
            return { ...state, settingsData: action.payload };
        case CHANGE_LANG:
            return { ...state, lang: action.payload };
        case GET_BRANDS:
            return { ...state, fetching: true };
        case GET_BRANDS_SUCCESS:
            return { ...state, fetching: false, ...action.payload };
        case GET_BRANDS_ERROR:
            return { ...state, fetching: false, brands: [] };
        case GET_COLORS:
            return { ...state, fetching: true };
        case GET_COLORS_SUCCESS:
            return { ...state, fetching: false, ...action.payload };
        case GET_COLORS_ERROR:
            return { ...state, fetching: false, colors: [] };
        case SAVE_BRANDS:
            return { ...state, fetching: true };
        case SAVE_BRANDS_SUCCESS:
            return { ...state, fetching: false };
        case SAVE_BRANDS_ERROR:
            return { ...state, fetching: false, brands: [] };
        case SAVE_MODEL:
            return { ...state, fetching: true };
        case SAVE_MODEL_SUCCESS:
            return { ...state, fetching: false };
        case SAVE_MODEL_ERROR:
            return { ...state, fetching: false, brands: [] };
        case GET_MODELS:
            return { ...state, fetching: true };
        case GET_MODELS_SUCCESS:
            return { ...state, fetching: false, ...action.payload };
        case GET_MODELS_ERROR:
            return { ...state, fetching: false };
        case DELETE_BRAND:
            return { ...state, fetching: true };
        case DELETE_BRAND_ERROR:
            return { ...state, fetching: false };
        case DELETE_BRAND_SUCCESS:
            return { ...state, fetching: false };
        case DELETE_MODEL:
            return { ...state, fetching: true };
        case DELETE_MODEL_ERROR:
            return { ...state, fetching: false };
        case DELETE_MODEL_SUCCESS:
            return { ...state, fetching: false };
        //Color de vehiculos
        case GET_VEHICLECOLORS:
            return { ...state, fetching: true };
        case GET_VEHICLECOLORS_SUCCESS:
            return { ...state, fetching: false, ...action.payload };
        case GET_VEHICLECOLORS_ERROR:
            return { ...state, fetching: false, vehicleColors: [] };
        case SAVE_VEHICLECOLORS:
            return { ...state, fetching: true };
        case SAVE_VEHICLECOLORS_SUCCESS:
            return { ...state, fetching: false };
        case SAVE_VEHICLECOLORS_ERROR:
            return { ...state, fetching: false, vehicleColors: [] };
        case DELETE_VEHICLECOLORS:
            return { ...state, fetching: true };
        case DELETE_VEHICLECOLORS_SUCCESS:
            return { ...state, fetching: false };
        case DELETE_VEHICLECOLORS_ERROR:
            return { ...state, fetching: false };
        //Color de vehiculos
        // Proveedores
        case GET_SUPPLIER:
            return { ...state, fetching: true }
        case GET_SUPPLIER_SUCCESS:
            return { ...state, fetching: false, ...action.payload }
        case GET_SUPPLIER_ERROR:
            return { ...state, fetching: false }
        case SAVE_SUPPLIER:
            return { ...state, fetching: true }
        case SAVE_SUPPLIER_SUCCESS:
            return { ...state, fetching: false, ...action.payload }
        case SAVE_SUPPLIER_ERROR:
            return { ...state, fetching: false }
        case DELETE_SUPPLIER:
            return { ...state, fetching: true }
        case DELETE_SUPPLIER_SUCCESS:
            return { ...state, fetching: false, ...action.payload }
        case DELETE_SUPPLIER_ERROR:
            return { ...state, fetching: false }
        // Proveedores
        //Metas
        case GET_METAS:
            return { ...state, fetching: true }
        case GET_METAS_SUCCESS:
            return { ...state, fetching: false, ...action.payload }
        case GET_METAS_ERROR:
            return { ...state, fetching: false }
        case DELETE_META:
            return { ...state, fetching: true }
        case DELETE_META_SUCCESS:
            return { ...state, fetching: false }
        case DELETE_META_ERROR:
            return { ...state, fetching: false }
        //Metas
        //Tipo de vehiculos
        case GET_VEHICLETYPE:
            return { ...state, fetching: true };
        case GET_VEHICLETYPE_SUCCESS:
            return { ...state, fetching: false, ...action.payload };
        case GET_VEHICLETYPE_ERROR:
            return { ...state, fetching: false, vehicleType: [] };
        case SAVE_VEHICLETYPE:
            return { ...state, fetching: true };
        case SAVE_VEHICLETYPE_SUCCESS:
            return { ...state, fetching: false };
        case SAVE_VEHICLETYPE_ERROR:
            return { ...state, fetching: false, vehicleType: [] };
        case DELETE_VEHICLETYPE:
            return { ...state, fetching: true };
        case DELETE_VEHICLETYPE_SUCCESS:
            return { ...state, fetching: false };
        case DELETE_VEHICLETYPE_ERROR:
            return { ...state, fetching: false };
        case GET_GENERAL_INFO:
            return { ...state, fetching: action.payload.loading, generalInfo: action.payload.data };
        case GET_GENERAL_INFO_META:
            const valuationIsActiveFound = action.payload.data && action.payload.data.results.find((i) => {
                return i.key === "VALUATION_IS_ACTIVE" && i.value === "1"
            }) 
            const isHideEntrySection = action.payload.data && action.payload.data.results.find((i) => {
                return i.key === "HIDE_ENTRY_SECTION" && i.value === "1"
            }) 
            const insuranceIsActiveFound = action.payload.data && action.payload.data.results.find((i) => {
                return i.key === "INSURANCE_IS_ACTIVE" && i.value === "1"
            }) 
            const defaultCustomerId = action.payload.data && action.payload.data.results.find((i) => {
                return i.key === "DEFAULT_CUSTOMER_ID" && i.value && parseInt(i.value) > 0
            }) 
            const defaultCustomerURL = action.payload.data && action.payload.data.results.find((i) => {
                return i.key === "DEFAULT_CUSTOMER_URL" && i.value
            })
            const entryDamageImgIsActive = action.payload.data && action.payload.data.results.find((i) => {
                return i.key === "ENTRY_DAMAGE_IMAGE_IS_ACTIVE" && i.value === "1"
            }) 
            const orderNumberDisabled = action.payload.data && action.payload.data.results.find((i) => {
                return i.key === "ORDER_NUMBER_DISABLED" && i.value === "1"
            }) 
            return { ...state, fetching: action.payload.loading, generalInfoMeta: action.payload.data, 
                entryDamageImgIsActive: !!(entryDamageImgIsActive), orderNumberDisabled: !!(orderNumberDisabled),
                valuationIsActive: !!(valuationIsActiveFound), isHideEntrySection: !!(isHideEntrySection), insuranceIsActive: !!(insuranceIsActiveFound), 
                defaultCustomerId: defaultCustomerId, defaultCustomerUrl: defaultCustomerURL && defaultCustomerURL.value};
        //Tipo de vehiculos
        case TOGGLE_MENU:
            return {
                ...state,
                menu: {
                    collapsed: action.payload,
                },
            };
        default:
            return state;
    }
}

//almacenams la sesion en el storage
function saveStorage(storage) {
    localStorage.storage = JSON.stringify(storage)
}

export let restoreSettingsAction = () => (dispatch, getState) => {
    let settingLocal = localStorage.getItem('settings_kk')

    if (settingLocal) {
        settingLocal = JSON.parse(settingLocal)
        dispatch({
            type: SAVE_SETTINGS_DATA,
            payload: settingLocal
        })
    } else {
        localStorage.removeItem('storage')
        localStorage.removeItem('settings_kk')
    }

}

/**
 * Este es la funcion cuando el usuario elige una empresa y sucursal, esta funcion es generica para ambos casos
 * @param type si es company entonces significa que el usuario está eligiendo empresa , de lo contrario entonces es sucursal
 * @param element es el objeto comoleto de sucursal o empresa
 */
export let setConfigCompanyBranch = (type = 'company', element) => (dispatch, getState) => {
    let settings = { ...getState().settings.settingsData }
    if (type === 'branch') {
        settings.branchSelected = element
    }

    if (type === 'company') {
        settings.companySelected = element
    }


    console.log(settings)

    if (localStorage) {
        localStorage.setItem('settings_kk', JSON.stringify(settings))
    }

    dispatch({
        type: SAVE_SETTINGS_DATA,
        payload: settings
    })

}

export let changeLangAction = () => (dispatch, getState) => {
    let lang = getState().settings.lang
    console.log(lang)
    dispatch({
        type: CHANGE_LANG,
        payload: lang === 'es-mx' ? 'en-us' : 'es-mx'
    })
}

export let getMetas = (page=1) => async (dispatch, getState) => {
    dispatch({
        type:GET_METAS
    })

    try {
        const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_METAS)
        console.log('res=>',response);
        dispatch({
            type: GET_METAS_SUCCESS,
            payload: { metaList: response.data.results }
        })
        console.log(response)
    } catch (errr) {
        console.log(errr)
        dispatch({
            type: GET_METAS_ERROR,
            payload:{loading:false, data:[]}
        })
    }

}

export let saveMetaAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_META
    })

    let settings = getState().settings.settingsData

    let request = {
        company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        ...data
    }
    try {
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_METAS, request)
        dispatch({
            type: SAVE_META_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: SAVE_META_ERROR
        })
        return { success: false }
    }
}

export let deleteMetaAction = (id) => async (dispatch) => {
    dispatch({
        type: DELETE_META
    })
    try {
        const response = await axiosApi.delete(`${API.WORKSHOP.WORKSHOP_METAS}${id}`)
        dispatch({
            type: DELETE_META_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: DELETE_META_ERROR
        })
        return { success: false }
    }
}

export let editMetaAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_META
    })
    let settings = getState().settings.settingsData

    let request = {
        company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        goal: data.goal,
        goal_date_apply: data.goal_date_apply,
        goal_type: data.goal_type
    }
    try {
        const response = await axiosApi.put(`${API.WORKSHOP.WORKSHOP_METAS}${data.id}/`, request)
        dispatch({
            type: SAVE_META_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: SAVE_META_ERROR
        })
        return { success: false }
    }
}

export let getGeneralMetaConfig = (page=1) => async (dispatch, getState) => {
    dispatch({
        type:GET_GENERAL_INFO_META,
        payload:{loading:true, data:null}
    })

    try {
        const response = await axiosApi.get(API.SECURITY.GENERAL_CONFIG_META)
        dispatch({
            type: GET_GENERAL_INFO_META,
            payload: { loading:false, data: response.data }
        })
        console.log(response)
    } catch (errr) {
        console.log(errr)
        dispatch({
            type: GET_GENERAL_INFO_META,
            payload:{loading:false, data:[]}
        })
    }

}

export let getBrandsAction = (page=1) => async (dispatch, getState) => {
    dispatch({
        type: GET_BRANDS
    })
    try {
        const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_CARBRAND+`?page=${page}`)
        console.log("response.data", response.data);
        dispatch({
            type: GET_BRANDS_SUCCESS,
            payload: { brands: response.data, brandsList: response.data.results }
        })
        console.log(response)
    } catch (errr) {
        console.log(errr)
        dispatch({
            type: GET_BRANDS_ERROR
        })
    }
}

export let getCarModelsAction = (page=1, filter='') => async (dispatch, getState) => {
    dispatch({
        type: GET_MODELS
    })
    await axiosApi
      .get(API.WORKSHOP.WORKSHOP_CARMODEL + `?page=${page}${filter}`)
      .then((response) => {
        dispatch({
          type: GET_MODELS_SUCCESS,
          payload: { models: response.data, modelsList: response.data.results },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_MODELS_ERROR,
        });
      });
}

export let getModelsAction = getCarModelsAction

export let deleteModelAction = (id) => async (dispatch) => {
    dispatch({
        type: DELETE_BRAND
    })

    try {
        const response = await axiosApi.delete(`${API.WORKSHOP.WORKSHOP_CARMODEL}${id}`)
        return { success: true }
    } catch (e) {
        dispatch({
            type: DELETE_MODEL_ERROR
        })
        return { success: false }
    }
}


export let deleteBrandAction = (id) => async (dispatch) => {
    dispatch({
        type: DELETE_BRAND
    })

    try {
        const response = await axiosApi.delete(`${API.WORKSHOP.WORKSHOP_CARBRAND}${id}`)
        dispatch({
            type: DELETE_BRAND_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: DELETE_BRAND_ERROR
        })
        return { success: false }
    }
}


export let saveModelAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_MODEL
    })

    let request = {
        ...data
    }

    try {
        console.log(request)
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_CARMODEL, request)

        dispatch({
            type: SAVE_MODEL_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: SAVE_MODEL_ERROR
        })
        return { success: false }
    }
}

export let saveBrandAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_BRANDS
    })

    let settings = getState().settings.settingsData

    let request = {
        company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        ...data
    }

    try {
        console.log(request)
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_CARBRAND, request)

        dispatch({
            type: SAVE_BRANDS_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: SAVE_BRANDS_ERROR
        })
        return { success: false }
    }
}

export let editModelAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_BRANDS
    })

    let request = {
        brand: data.brand,
        name: data.name
    }

    if(data.vehicle_type){
        request.vehicle_type=data.vehicle_type
    }else if(data.brand.vehicle_type===undefined){
        request.vehicle_type=null
    }

    try {
        console.log(request)
        const response = await axiosApi.put(`${API.WORKSHOP.WORKSHOP_CARMODEL}${data.id}/`, request)

        dispatch({
            type: SAVE_BRANDS_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: GET_BRANDS_ERROR
        })
        return { success: false }
    }
}

export let editBrandAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_BRANDS
    })

    let settings = getState().settings.settingsData

    let request = {
        company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        name: data.name
    }

    try {
        console.log(request)
        const response = await axiosApi.put(`${API.WORKSHOP.WORKSHOP_CARBRAND}${data.id}/`, request)

        dispatch({
            type: SAVE_BRANDS_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: GET_BRANDS_ERROR
        })
        return { success: false }
    }
}


export const toggleMenu = (data) => async (dispatch, getState) => {
    console.log('data', data)
    dispatch({
        type: "TOGGLE_MENU",
        payload: data,
    });
};


//Aciones para colores de vehiculos
export let getColorsAction = (page=1) => async (dispatch, getState) => {
    dispatch({
        type: GET_VEHICLECOLORS
    })
    await axiosApi.get(API.WORKSHOP.WORKSHOP_CARCOLOR+`?page=${page}`)
        .then((response) => {
            console.log("response.data", response.data);
            dispatch({
                type: GET_VEHICLECOLORS_SUCCESS,
                payload: { colors: response.data, vehicleColorsList: response.data.results }
            });
        })
        .catch((error) => {
            console.log(error)
            dispatch({
                type: GET_VEHICLECOLORS_ERROR
            });
        })
}

export let editColorAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_VEHICLECOLORS
    })
    let settings = getState().settings.settingsData

    let request = {
        company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        name: data.name
    }

    try {
        console.log(request)
        const response = await axiosApi.put(`${API.WORKSHOP.WORKSHOP_CARCOLOR}${data.id}/`, request)

        dispatch({
            type: SAVE_VEHICLECOLORS_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: GET_VEHICLECOLORS_ERROR
        })
        return { success: false }
    }
}

export let saveColorAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_VEHICLECOLORS
    })

    let settings = getState().settings.settingsData

    let request = {
        company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        ...data
    }

    try {
        console.log(request)
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_CARCOLOR, request)

        dispatch({
            type: SAVE_VEHICLECOLORS_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: SAVE_VEHICLECOLORS_ERROR
        })
        return { success: false }
    }
}

export let deleteColorAction = (id) => async (dispatch) => {
    dispatch({
        type: DELETE_VEHICLECOLORS
    })

    try {
        const response = await axiosApi.delete(`${API.WORKSHOP.WORKSHOP_CARCOLOR}${id}`)
        dispatch({
            type: DELETE_VEHICLECOLORS_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: DELETE_VEHICLECOLORS_ERROR
        })
        return { success: false }
    }
}

//Aciones para tipos de vehiculos
export let getTypesAction = (page=1) => async (dispatch, getState) => {
    dispatch({
        type: GET_VEHICLETYPE
    })
    await axiosApi.get(API.WORKSHOP.WORKSHOP_CAR_TYPES+`?page=${page}`)
        .then((response) => {
            dispatch({
                type: GET_VEHICLETYPE_SUCCESS,
                payload: { types: response.data, vehicleTypeList: response.data.results }
            });
        })
        .catch((error) => {
            console.log(error)
            dispatch({
                type: GET_VEHICLETYPE_ERROR
            });
        })
}

export let editTypeAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_VEHICLETYPE
    })

    let settings = getState().settings.settingsData

    let request = {
        //company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        ...data
    }

    try {
        console.log(request)
        const response = await axiosApi.put(`${API.WORKSHOP.WORKSHOP_CAR_TYPES}${data.id}/`, request)

        dispatch({
            type: SAVE_VEHICLETYPE_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: GET_VEHICLETYPE_ERROR
        })
        return { success: false }
    }
}

export let saveTypeAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_VEHICLETYPE
    })

    let settings = getState().settings.settingsData

    let request = {
        //company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        ...data
    }

    try {
        console.log(request)
        const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_CAR_TYPES, request)

        dispatch({
            type: SAVE_VEHICLETYPE_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: SAVE_VEHICLETYPE_ERROR
        })
        return { success: false }
    }
}

export let deleteTypeAction = (id) => async (dispatch) => {
    dispatch({
        type: DELETE_VEHICLETYPE
    })

    try {
        const response = await axiosApi.delete(`${API.WORKSHOP.WORKSHOP_CAR_TYPES}${id}`)
        dispatch({
            type: DELETE_VEHICLETYPE_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: DELETE_VEHICLETYPE_ERROR
        })
        return { success: false }
    }
}


export let getSupplierAction = (page=1, filter=null) => async (dispatch, getState) => {
    dispatch({
        type: GET_SUPPLIER
    })
    await axiosApi.get(API.BUSINESS.SUPPLIERS+`?page=${page}${filter ? "&search="+filter : ""}&page_size=1000`)
        .then((response) => {
            dispatch({
                type: GET_SUPPLIER_SUCCESS,
                payload: { suppliersList: response.data.results }
            });
        })
        .catch((error) => {
            console.log(error)
            dispatch({
                type: GET_SUPPLIER_ERROR
            });
        })
}


export let saveSupplierAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_SUPPLIER
    })

    let settings = getState().settings.settingsData

    let request = {
        company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        ...data
    }

    try {
        console.log(request)
        const response = await axiosApi.post(API.BUSINESS.SUPPLIERS, request)

        dispatch({
            type: SAVE_SUPPLIER_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: SAVE_SUPPLIER_ERROR
        })
        return { success: false }
    }
}

export let editSupplierAction = (data) => async (dispatch, getState) => {
    dispatch({
        type: SAVE_SUPPLIER
    })

    let settings = getState().settings.settingsData

    let request = {
        company: settings.companySelected.id,
        branch: settings.branchSelected.id,
        name: data.name,
        tax_id: data.tax_id,
        categories: data.categories
    }

    try {
        console.log(request)
        const response = await axiosApi.put(`${API.BUSINESS.SUPPLIERS}${data.id}/`, request)

        dispatch({
            type: SAVE_SUPPLIER_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: SAVE_SUPPLIER_ERROR
        })
        return { success: false }
    }
}

export let deleteSupplierAction = (id) => async (dispatch) => {
    dispatch({
        type: DELETE_SUPPLIER
    })

    try {
        const response = await axiosApi.delete(`${API.BUSINESS.SUPPLIERS}${id}`)
        dispatch({
            type: DELETE_SUPPLIER_SUCCESS
        })
        return { success: true }
    } catch (e) {
        dispatch({
            type: DELETE_SUPPLIER_ERROR
        })
        return { success: false }
    }
}

export let getGeneralConfig = (page=1) => async (dispatch, getState) => {
    dispatch({
        type:GET_GENERAL_INFO,
        payload:{loading:true, data:null}
    })

    try {
        const response = await axiosApi.get(API.SECURITY.GENERAL_CONFIG)
        console.log("response.data", response.data);
        dispatch({
            type: GET_GENERAL_INFO,
            payload: { loading:false, data: response.data }
        })
        console.log(response)
    } catch (errr) {
        console.log(errr)
        dispatch({
            type: GET_GENERAL_INFO,
            payload:{loading:false, data:[]}
        })
    }

}