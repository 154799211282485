import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { Layout, Menu,Avatar,Button,Row,Col } from 'antd';
import { CarOutlined, AppstoreOutlined,TagOutlined,
  FolderFilled,PieChartOutlined, DashboardOutlined,UserOutlined,CloudServerOutlined, SettingOutlined,  LeftOutlined,BlockOutlined,
  RightOutlined,ToolOutlined,TeamOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { injectIntl } from 'react-intl';
import { useHistory, NavLink, Redirect } from "react-router-dom"
import { toggleMenu } from '../redux/settingsDuck';
import { Global, css } from '@emotion/react';
import { changeLangAction } from '../redux/settingsDuck';
import imgUser from "../image/UserSinBarba.png"
import EsEn from "../image/idiomas_icon.svg"
import { useMediaQuery } from 'react-responsive';

import VentasIconBlanco from "../image/ventas_icon2.svg"
import ServiciosIconBlanco from "../image/servicios_icon2.svg"

import MembersIcon from "../image/member_center_icon.svg";
import {permissionsExist} from "../lib/utils/utils";
import refacciones_icon from "../image/detail/refacciones_icon_white.svg";
import { tenantSelected } from '../lib/utils/axiosApi';



const MainSider = ({ openKeys,branchSelected,companySelected, usernameFirst,user, security, usernameLast, changeLangAction, toggleMenu, settings, intl, index,permissions, ...props }) => {

  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const history = useHistory()

  const [collapsed, setCollapsed] = useState(isMobile?true:false);

  //const { collapsed } = props.menu;
  const [imageprofile,setImageProfile] = useState(imgUser)
  
  const [companyLogo,setCompanyLogo] = useState(null)
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };


  useEffect(() => {
    if(user?.userprofile?.image_profile){
          setImageProfile(user?.userprofile?.image_profile)
    }
    if(companySelected?.logo){
      setCompanyLogo(companySelected?.logo)
    }
  }, [user,companySelected])
  


  /***Validador de permisos***/
  const permissionsExist_ = (arr=[],permission)=> {
         if (permissions.permissionsList && permissions.permissionsList.length>0){
           return  permissions.permissionsList.some(function(el) {
             return el.codename === permission;
           });
         }else {
           return false
         }
  }


  /**
   * Esta funcio nos sirve para identificar si hay que mostrar o no el menu de ventas (es mediante los metas del admin)
   * @returns {boolean}
   */
  const showSales=()=>{
    let metas= settings?.generalInfoMeta?.results;
    if(metas){
      let data =metas.find((item) => item.key==='SALES_IS_ACTIVE' && item.value==='1');
      if(data){
        return true
      }
    }
    return false
  }


  return (
    <>
      <Global
        styles={css`
                .divlogo{
                    background-color: #fff;
                    margin-top: 25px;
                    display: flex;
                    
                }
                
                .ant-layout-sider-collapsed .divlogo{
                    margin: auto;
                }

                .ant-layout-sider-collapsed .logo{
                  width:50px;
                  height: 50px;
                }
                .logo {
                    width:40px;
                    height: 40px;
                }

                .ant-layout-sider-collapsed .logoMenu{
                    margin: auto;
                }
                .logoMenu{
                    height: 64px;
                    width: 28px;
                    margin-left: 40px;
                }
                
                .siderColor{
                  background-color: var(--secondaryColor) !important;
                }
                
                .ant-menu-item-selected{
                  background-color: var(--primaryColor) !important;
                }

                .ant-layout-sider{
                  width:10% !important;
                }

            `}
      />
      <Sider collapsible
             trigger={null} className={'siderColor'} collapsed={collapsed}>
        <div style={{ width: '100%', display: 'flex', textAlign: 'center',color:'white',marginTop:30 }}>
          {/*<Avatar className="logo"  icon={<UserOutlined style={{ margin: 'auto', fontSize: 35 }} />} style={{ margin: 'auto', display: 'flex' }} />*/}
         {
          !collapsed ? 
          <Row gutter={[6,6]} style={{paddingLeft:10}}>
          <Col>
          <Avatar  onError={()=>setCompanyLogo(imgUser)} className="logo" src={companyLogo} 
          style={{ margin: 'auto', display: 'flex', borderRadius:'2%', border:'2px solid white' }} />
          </Col>
          <Col>
          <p>{companySelected?.name} 
          {branchSelected && 
          <small style={{fontSize:10,display:'block',padding:0,margin:0}}>
            {branchSelected?.name}</small>}</p>
          </Col>
        </Row> : <Avatar  onError={()=>setCompanyLogo(imgUser)} className="logo" src={companyLogo} 
          style={{ margin: 'auto', display: 'flex', borderRadius:'2%', border:'2px solid white' }} />
         }
        </div>
        
        <Menu style={{marginTop:10}} theme="dark" className={'siderColor'} mode="inline" defaultOpenKeys={openKeys} /* onOpenChange={onOpenChange} */ selectedKeys={index ? index : ""} >
          <Menu.Item key="Dashboard" icon={<AppstoreOutlined />} onClick={() => history.push("/")}>
            <FormattedMessage id="services.dash" />
          </Menu.Item>
          <Menu.Item key="analitica" icon={<PieChartOutlined />} onClick={() => history.push("/analytics")}>
            <FormattedMessage id="services.analytics" />
          </Menu.Item>
          {
            showSales() && <Menu.Item key="ordenes_de_venta" icon={<img src={VentasIconBlanco} width="20px" height="20px" />} onClick={() => history.push("/sale-orders")}>
              {/*<img src={index === 'ordenes_de_venta' ? VentasIconBlanco : VentasIcon} width="20px" height="20px" /> &nbsp; <FormattedMessage id="services.sale" />*/}
               &nbsp; <FormattedMessage id="services.sale" />
            </Menu.Item>
          }

          <Menu.Item key="customer" icon={<UserOutlined />} onClick={() => history.push("/customers")}>
            Clientes
          </Menu.Item>
          <Menu.Item key="electroplan" icon={<DashboardOutlined />} onClick={() => history.push("/electroplan_")}>
            <FormattedMessage id="services.page.electroplan" />
          </Menu.Item>
          <Menu.Item key="drive" icon={<FolderFilled />} onClick={() => history.push("/drive")}>
            <FormattedMessage id="drive.my_drive" />
          </Menu.Item>
          <Menu.Item key="expedientes" icon={<ToolOutlined />} onClick={() => history.push("/services/list")}>
            {/*<img src={ index === 'expedientes' ? ServiciosIconBlanco : ServiciosIcon} width="19px" height="19px" /> &nbsp; <FormattedMessage id="services.service" />*/}
            <FormattedMessage id="services.service" />
          </Menu.Item>
          {
              settings && settings.valuationIsActive && tenantSelected!=='jacmexico' && (<Menu.Item key="parts" icon={<BlockOutlined />} onClick={() => history.push("/services/parts")}>
                {/*<img src={ index === 'expedientes' ? ServiciosIconBlanco : ServiciosIcon} width="19px" height="19px" /> &nbsp; <FormattedMessage id="services.service" />*/}
                 <FormattedMessage id="servicesDetails.spareParts" />
              </Menu.Item>)
          }

          <Menu.Item key="members" icon={
            // <img src={MembersIcon} width="19px" height="19px"  style={ index !== 'members' ? { filter:'invert(100%)' } : null} />
            <TeamOutlined />
          } onClick={() => history.push("/members_center_users")}>
            {/* <img src={ index === 'members' ? UsuariosAzul : UsuariosNegro} width="19px" height="19px" /> &nbsp;  */}
            <FormattedMessage id="services.members_center" />
          </Menu.Item>
          <Menu.Item key="units" icon={<CarOutlined />} onClick={() => history.push("/units")}>
            Unidades
          </Menu.Item>

          <Menu.Item key="promos" icon={<TagOutlined />} onClick={() => history.push("/promos")}>
            Promociones
        </Menu.Item>
          {collapsed ?
            <Menu.Item key="10"  onClick={changeLangAction} >
              <img style={{filter:'invert(100%)'}} src={EsEn} width="18px" height="18px" />
            </Menu.Item> :
            <Menu.Item key="10" onClick={changeLangAction} >
              <img src={EsEn} style={{filter:'invert(100%)'}} width="17px" height="17px" /> &nbsp; Es/En
            </Menu.Item>
          }
        </Menu>
        <div>
          <Button  onClick={toggleCollapsed} style={{ marginBottom: 16, width: '100%',backgroundColor:'transparent', color:'white',borderRadius:'0px' }}>
              {React.createElement(collapsed ? RightOutlined : LeftOutlined)}
          </Button>
        </div>
      </Sider>
    </>
  )
}

const mapState = (state) => {
  return {
    menu: state.settings.menu,
    settings: state.settings,
    user:state.user,
    security: state.security,
    usernameFirst: state.user.first_name,
    usernameLast: state.user.last_name,
    permissions:state.permissions,
    companySelected: state?.settings?.settingsData?.companySelected,
    branchSelected: state?.settings?.settingsData?.branchSelected,
  }
}

export default injectIntl(connect(mapState, { toggleMenu, changeLangAction })(MainSider))


