import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout';
import {  Layout, Form, Button, Input, Space, message, Row, Col, Typography, Select, Tag } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import DeleteLinkComponent from "../../components/DeleteLinkComponent";
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import {
  getSupplierAction,
  saveSupplierAction,
  editSupplierAction,
  deleteSupplierAction
} from "../../redux/settingsDuck"
import {permissionsExist} from "../../lib/utils/utils";
import DeleteModal from "../../components/modal/DeleteModal";
import EditIcon from "../../image/edit.svg";
import DeleteIcon from "../../image/delete.svg";
import turn from '../../image/turn_icon_kikert.svg'
import {CustomTable, KSelect} from "../../components/KComponents";
import KButton from "../../components/common/elements/KButton/KButton";
import KModal from '../../components/common/elements/KModal/KModal';
import TableCategory from "../../components/TableCategory";
import SelectCategoriesSuppliers from "../../components/Selects/SelectCategoriesSuppliers";
import {getCategoriesSupplier} from "../../redux/businessDuck";
import _ from 'lodash'
import MainLayoutv2 from '../../layouts/MainLayoutv2';
import TabsConfig  from '../../components/common/elements/TabsMenuConfig/TabsMenuConfig';


const { Header, Content, Footer } = Layout;
const {Search} = Input 

const Suppliers = ({intl, getSupplierAction, saveSupplierAction, settings, editSupplierAction,categories, deleteSupplierAction, getCategoriesSupplier}) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleCategory, setIsModalVisibleCategory] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState(false);
  const [forDelete, setForDelete] = useState(null)
  const [modalDelete, setModalDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm();

  const {Title} = Typography;

  const columns = [
    {
      id:'name',
      dataIndex: 'name',
      title: intl.formatMessage({ id: "suppliers.name" })
    },
    {
      id:'rfc',
      dataIndex: 'tax_id',
      title: intl.formatMessage({ id: "suppliers.rfc" })
    },
      {
      id:'category',
      dataIndex: 'category',
      title: 'Categoría(s)',
      render:(id,record)=>{
          return <>
              {
                  record.categories_obj
                  && record.categories_obj.map((ele)=>{
                      return <Tag key={ele.id} color="geekblue">{ele.name}</Tag>
                  })
              }
          </>
      },
      filters:categories
          ? categories.map((ele)=>{
              return {text:ele.name, value: ele.id}
          }):[],
      onFilter: (value, record) =>{
          console.log(value, record)
         return _.includes(record.categories,value)
      }
    },
    {
      title: <FormattedMessage id="layout.actions"/>,
      dataIndex: 'id',
      key: 'actions',
      render: (id, record) => {
          return <Space size={30}>
              {
                <img key="EditIcon" src={EditIcon} style={{cursor:'pointer',  width:12}}
                  onClick={() => editSupplier(record)}/>
              }
              {
                <img key="DeleteIcon" src={DeleteIcon} style={{cursor:'pointer', width:12}} onClick={() => deleteItem(record)}/>

              }
          </Space>
      }

  }
  ]

  useEffect(() => {
    getSupplierAction()
    getCategoriesSupplier()
  }, [])

  const showModal = (isEdit = false) => {
    if (!isEdit) {
        form.resetFields()
        setCurrentSupplier(null)
    }
    setIsModalVisible(true);
};

const handleOk = () => {
    form.submit();
};

const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentSupplier(null)
    form.resetFields()
};

const closeModal = () =>{
    setModalDelete(false);
    setForDelete(null);
}

const deleteItem = (current) => {
    setModalDelete(true);
    setForDelete(current);
}

const onDeleteSupplier = async () => {
  try {
      const res = await deleteSupplierAction(forDelete.id)
      if (res.success) {
          message.success(intl.formatMessage({ id: "item.deleteSuccess" }))
          await getSupplierAction()
          await closeModal()

      } else {
          message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
          await closeModal()

      }
  } catch (e) {
      message.error(intl.formatMessage({ id: "item.brand.registerErr" }))
      await closeModal()
  }
}

const onSaveSupplier = async values => {
  setIsLoading(true)
  if (values) {
      let res = await saveSupplierAction(values)
      if (res.success) {
          message.success(intl.formatMessage({ id: "item.saveSuccess" }))
          await getSupplierAction()
      } else {
          message.error(intl.formatMessage({ id: "item.brand.element" }))
      }
      form.resetFields()
      setCurrentSupplier(null)
      setIsModalVisible(false);
  }
  console.log('Received values of form: ', values);
  setIsLoading(false)
};

const onEditSupplier = async values => {
    setIsLoading(true)
  if (values) {
      let req = {
          id: currentSupplier.id,
          ...values
      }
      let res = await editSupplierAction(req)
      if (res.success) {
          message.success(intl.formatMessage({ id: "item.editSuccess" }))
          await getSupplierAction()
      } else {
          message.error(intl.formatMessage({ id: "item.brand.element" }))
      }

      form.resetFields()
      setCurrentSupplier(null)
      setIsModalVisible(false);
  }
  setIsLoading(false)
};

const editSupplier = (supplier = null) => {
  setCurrentSupplier(supplier)
  if (supplier) {
      form.setFieldsValue({
          name: supplier.name,
          tax_id: supplier.tax_id,
          categories: supplier.categories
      })
  }
  showModal(true)
}

const changePagination = (page) => {
  getSupplierAction(page);
};
  

  return (
    <MainLayoutv2
        title={intl.formatMessage({ id: "suppliers.title" })}
        index="11"
        openKeys={["sub1"]}
      >
        <Content
          style={{ padding: "10px 10px", background: "white" }}
        >
          <Row justify='space-between'>
            <Col span={24}>
              <TabsConfig keyActive={'2'}/>

              <Search
                  size="large"
                  onSearch={(value)=>{
                      getSupplierAction(1, value)
                  }}
                  allowClear
                  placeholder={intl.formatMessage({ id:'dashboard.search'})}
                  className="kinput-search"
                  style={{ height: 50 }}
              />
            </Col>
            <Col>
                <KButton
                    onClick={()=>setIsModalVisibleCategory(true)}
                    type="secondary"
                    size={"large"}
                    text={'Categorías de proveedor'}
                />
              <KButton
                  onClick={showModal}
                  type="primary"
                  size={"large"}
                  text={<FormattedMessage id="layout.createNew" />}
              />
            </Col>
          </Row>
          <CustomTable 
          fixed={true}
          scroll={{ x: 1500 }}
          loading={settings.fetching} dataSource={settings?.suppliersList} columns={columns} />

        <KModal
            title="Categorías de proveedor"
            footer=""
            visible={isModalVisibleCategory}
            onOk={()=> setIsModalVisibleCategory(false)}
            onCancel={()=> setIsModalVisibleCategory(false)}
        >
            <TableCategory/>

        </KModal>
          <KModal
            title=""
            footer=""
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Row>
                <Col span={24}>
                    <Title level={2} style={{ marginBottom: 0 }}>
                        <img
                        src={turn}
                        alt="logo"
                        style={{ height: 60, width: 30, transform: "scaleX(-1)", marginRight:10 }}
                        />
                        <FormattedMessage id={currentSupplier ? "suppliers.edit": "suppliers.new" } />
                    </Title>
                </Col>
                <Col span={24}>
                  <Form
                    layout={"vertical"}
                    form={form}
                    onFinish={currentSupplier ? onEditSupplier : onSaveSupplier}
                  >  
                    <Form.Item
                      name={"name"}
                      label={intl.formatMessage({ id: "supplier.name" })}
                      rules={[{ required: true }]}
                    >
                      <Input
                        className='kinput'
                        placeholder={intl.formatMessage({
                        id: "supplier.name",
                        })}
                      />
                    </Form.Item>
                      <SelectCategoriesSuppliers/>
                    <Form.Item
                      name={"tax_id"}
                      label={intl.formatMessage({ id: "supplier.tax_id" })}
                    >
                      <Input
                        className='kinput'
                        placeholder={intl.formatMessage({
                        id: "supplier.tax_id",
                        })}
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Space>
                        <Button
                            size="large"
                            loading={isLoading}
                            className="btn-string-blue btn-report-action"
                            onClick={() => handleCancel()}
                        >
                            <FormattedMessage id="layout.cancel" />
                        </Button>
                        <Button
                            className="btn-blue btn-report-action"
                            size="large"
                            loading={isLoading}
                            onClick={() => handleOk()       }
                            >
                            <FormattedMessage id="services.members.create.save" />
                        </Button>
                    </Space>
                </Col>
              </Row>
          </KModal>
          <DeleteModal actionDelete={() => onDeleteSupplier()} isVisible={modalDelete}
                       closeModal={closeModal}
                       modalTitle={<FormattedMessage id="deleted.ask"/>}
                       description={intl.formatMessage({ id:'deleted.description'},{ obj: forDelete && forDelete.name })} />
        </Content>
    </MainLayoutv2>
  )
}

const mapStateToProps = state => ({
    settings: state.settings,
    permissions: state.permissions,
    categories: state.business.categories,
})

export default injectIntl(connect(mapStateToProps, {getSupplierAction, saveSupplierAction, editSupplierAction,getCategoriesSupplier, deleteSupplierAction})(Suppliers))